<template>
  <div class="img-item"> 
    <img 
      class="img-item__close"
      :src="closeIcon"
      alt="close icon"
      @click="$emit('onClose', id)" 
    >
    <img 
      class="img-item__img" 
      :src="imgPath" 
      alt="image thumbnail"
      @click="$emit('onImgClick')"
    >
  </div>
</template>

<script>
import closeIcon from "@/assets/img/icons/close.svg";
export default {
  props: {
    imgPath: {
      type: String,
      default: null
    },
    id: {
      type: Number,
      default: null
    },
  },
  emits: ['onClose', 'onImgClick'],
  setup () {
    return {
      closeIcon,
    }
  },
}
</script>

<style lang="scss" scoped>
  .img-item {
    position: relative;
    width: 48px;
    height: 48px;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    cursor: pointer;

    &__close {
      width: 16px;
      position: absolute;
      top: -8px;
      right: -8px;
      opacity: .5;
      cursor: pointer;
    }

    &__img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }
</style>