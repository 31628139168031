<template>
  <section class="devices">
    <AddFlagCard 
      v-show="isFlagsTooptipVisible" 
      :x="mouseX" 
      :y="mouseY" 
      :flagsData="currentFlagsData"
    />
    <div class="my-container">
      <div class="devices-header">
        <div
          class="devices-header__actions"
        >
          <router-link 
            v-show="isAdmin"
            :to="{name: 'AddDevice'}" 
          >
            <MyButton :title="$t('addNewDevice')" />
          </router-link>
          <!-- <MySelect
            :options="countriesFilterItems"
            :title="$t('selectCountry')" 
            class="devices-header__select"
            @change="filterByCountry"
          /> -->
        </div>
        <div class="devices-header__right">
          <span class="devices-header__all">{{ $t('allDevices') }}: <b>{{ $total }}</b></span>
          <img
            class="devices-header__blocks" 
            :class="[actualView === 'block' ? '' : 'disable-icon']"
            :src="blocksIcons" 
            alt="blocks icon"
            @click="() => changeView('block')"
          >
          <img
            class="devices-header__list" 
            :class="[actualView === 'list' ? '' : 'disable-icon']"
            :src="listIcon" 
            alt="list icon"
            @click="() => changeView('list')"
          >
        </div>
      </div>
      <Spinner :loading="$devicesLoading" />
      <div 
        v-if="!$devicesLoading"
        class="devices-wrapper"
      >
        <DeviceCard 
          v-for="device in $devices"
          :key="device.id"
          :view="actualView"
          :name="device.name"
          :imgUrl="device.images[0] ? device.images[0].path : 'no-img'"
          :subName="device.country_origin.name"
          :hasFlag="device.needs_additional_photos || device.missing_data || device.needs_cross_section || device.error_reported || device.needs_qc"
          :flagsData="showFlagsData(device)"
          @click="() => goToDevice(device.id)"
          @onFlagMouseEnter="onFlagMouseEnter"
          @onFlagMouseLeave="onFlagMouseLeave"
        />
        <div 
          v-show="$total <= 0 && !$devicesLoading" 
          class="devices__nothing"
        >
          <h3>
            {{ $t('noDevices') }}
          </h3>
        </div>
      </div>
      <div 
        v-show="$total > $limit"
        class="devices__pagination"
      >
        <PaginationButtons 
          :limit="$limit"
          :total="$total"
          :page="$page"
          @onChange="changePage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ls from "local-storage";
import { MyButton, MySelect, DeviceCard, AddFlagCard, Spinner, PaginationButtons } from "@/components-ui"
import listIcon from "@/assets/img/icons/list.svg";
import blocksIcons from "@/assets/img/icons/blocks.svg";


export default {
  name: "DeviceView",
  components: {
    MyButton,
    // MySelect,
    DeviceCard,
    AddFlagCard,
    Spinner,
    PaginationButtons
  },
  setup() {
    return {
      listIcon,
      blocksIcons,
    };
  },
  data() {
    return {
      actualView: 'block',     

      isFlagsTooptipVisible: false,
      mouseX: 0,
      mouseY: 0,
      currentFlagsData: {}
      // filteredDevices: [] 
    }
  },
  computed: {
    ...mapState("device", {
      $devices: "devices",
      $total: "total",
      $limit: "limit",
      $page: "page",
      $devicesLoaded: "devicesLoaded"
    }),
    ...mapState("loader", {
      $devicesLoading: "devicesLoading"
    }),
    ...mapState("navbarFilter", {
      $navbarFilter: "filterValue",
      $filters: "filters"
    }),

    // filteredDevices() {
    //   return this.$devices.filter(device => {
    //     return device.name.toLowerCase().includes(this.$navbarFilter.toLowerCase())
    //   })
    // },

    // countriesFilterItems() {
    //   return [{
    //     id: 0,
    //     name: 'Reset filter'
    //   }, ...this.$countries]
    // }
    isAdmin() {
      return ls.get("HALO_USER_ROLE") === 'super_admin' || ls.get("HALO_USER_ROLE") === 'admin';
    },
  },
  methods: {
    ...mapActions("device", ["getDeviceCollection"]),
    ...mapActions("country", ["getCountryCollection"]),
    
    changePage(page) {
      this.getDeviceCollection({
        filters: {
          group_ids: this.$filters.group,
          sub_group_ids: this.$filters.subGroup,
          role_ids: this.$filters.role,
          hazard_ids: this.$filters.hazards,
          country_origin_ids: this.$filters.countryOfOrigin,
          country_found_in_ids: this.$filters.foundIn,
          needs_qc: this.$filters.needQC ? 1 : null,
          missing_data: this.$filters.missingData ? 1 : null,
          needs_additional_photos: this.$filters.needsAdditionalPhotos ? 1 : null,
          error_reported: this.$filters.errorReported ? 1 : null,
          needs_cross_section: this.$filters.needsCrossSection ? 1 : null,
        },
        name: this.$navbarFilter ? this.$navbarFilter : null,
        page,
      })
    },
    changeView(type) {
      this.actualView = type
    },
    goToDevice(id) {
      this.$router.push({
        name: 'Device',
        params: {id: id}  
      })
    },
    // filterByCountry(event) {
    //   this.getDeviceCollection({ 
    //     params: {
    //       country_origin_ids: [event.target.value === '0' ? null : event.target.value]
    //     }
    //   })
    // },
    showFlagsData(device) {
      return {
        missingData: device.missing_data,
        needsAdditionalPhotos: device.needs_additional_photos,
        needsCrossSection: device.needs_cross_section,
        errorReported: device.error_reported,
        needsQC: device.needs_qc
      }
    },
    onFlagMouseEnter({event, flagsData}) {
      this.currentFlagsData = flagsData
      this.isFlagsTooptipVisible = true;
      this.mouseX = event.clientX + 3;
      this.mouseY = event.clientY + 3;
    },
    onFlagMouseLeave({event}) {
      this.isFlagsTooptipVisible = false;
    }
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    if(!this.$devicesLoaded) {
      this.getDeviceCollection()
      // this.getCountryCollection()
    }
  }
}
</script>

<style lang="scss" scoped>


.devices {
  &-header {
    display: flex;
    justify-content: space-between;

    padding: 31px 0 24px 0;

    border-bottom: 1px solid #B1B2B2;

    &__actions {
      display: flex;
      gap: 20px;
    }

    &__select {
      width: 150px;
      height: 36px;
    }

    &__right {
      display: flex;
      align-items: center;
    }

    &__all {
      display: flex;
      gap: 3px;
      padding-right: 30px;
      height: 30px;
      white-space: nowrap;
      font-size: 16px;
      line-height: 30px;

      border-right: 1px solid #B1B2B2;
    }

    &__blocks {
      display: block;
      padding: 0 30px 0 43px;
      cursor: pointer;
    }

    &__list {
      width: 24px;
      cursor: pointer;
    }
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    gap: 20px 15px;
    margin: 30px 0;
  }

  &__nothing {
    padding: 20px 30px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    h3 {
      text-align: center;
      margin-bottom: 0;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.disable-icon {
  filter: grayscale(100%);
  opacity: 0.3;
}


@media (max-width: 740px) {
  .devices {
    &-header {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  }
}
@media (max-width: 400px) {
  .devices-header__actions {
    a {
      button {
        width: 100%;
      }
    }
    flex-direction: column;
  }
}
</style>