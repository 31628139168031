<template>
  <main>
    <section class="login d-flex justify-content-center">
      <div class="text-center">
        <img 
          class="login__img"
          src="@/assets/img/SiteLogo.png" 
          alt="SiteLogo 1"
        >
        <div v-if="!loginFormView">
          <h1>{{ $t('welcomeToDashboard') }}</h1> 
          <button 
            class="login__btn"
            @click="login" 
          >
            {{ $t('loginViaOffice') }}
          </button>
        </div>
        <form 
          v-if="loginFormView"
          class="login__form" 
          @submit.prevent="loginWithFormData"
        >
          <MyInput
            v-model="formData.email"
            type="text"
            :placeholder="$t('email')"  
          />
          <MyPassInput 
            v-model="formData.password"
            :placeholder="$t('password')"  
          />

          <button 
            class="login__btn"
            type="submit"
            :disabled="loading"
          >
            {{ $t('submit') }} 
          </button>
        </form>
        <div class="login__alternative">
          <span>{{ $t('useAlternativeLoginVia') }}  <a @click="toggleLoginView">{{ loginViewText }}</a></span> 
        </div>
      </div>
      <div class="position-absolute bottom-0 text-center pb-4">
        <p class="mb-0">
          © {{ $t('haloTrust') }}, 2023. {{ $t('allRightsReserved') }}.  
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import AuthService from '@/services/AuthService'

import { MyInput, MyPassInput  } from '@/components-ui'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  components: {
    MyInput,
    MyPassInput
  },
  data() {
    return {
      loginFormView: false,
      loading: false,
      formData: {
        email: null,
        password: null,
      }
    }
  },

  computed: {
    loginViewText() {
      return this.loginFormView ? this.$t("officeAccount") : this.$t("personalCredentials") 
    }
    // isError() {
    //   return this.$store.state.system.error_api_response
    // },
    // message() {
    //   return this.$store.state.system.message
    // },
  },
  methods: {
    ...mapActions("auth", ["loginViaCredentials"]),

    toggleLoginView() {
      this.loginFormView = !this.loginFormView;
    },
    closeMessage() {
      this.$store.commit("set_message", null)
    },
    login() {
      const authService = new AuthService();
      authService.loginOffice365()
    },

    async loginWithFormData() {
      this.loading = true;
      await this.loginViaCredentials({
        email: this.formData.email,
        password: this.formData.password
      })
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>

.login {
  &__img {
    height: 240px; 
    margin-bottom: 50px;
  }

  h1 {
    margin-bottom: 64px;
  }

  &__btn {
    height: 48px;
    padding: 0 16px;
    /* margin-top: 64px; */
    color: white;
    background-color: #008DA8;
    border-radius: 6px;
    border: none;

    &:disabled {
      background-color: #c7c7c7;
    }
  }

  &__alternative {
    margin-top: 20px;
    font-size: 14px;
    span {
      font-weight: bold;
      color: #0B3C61;
    }
    a {
      color: #008DA8 !important;

      &:hover {
        color: #0B3C61 !important;
      }
    }
  }

  &__form {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
}





/* .errorMessage {
  background: linear-gradient(90deg, rgba(255, 4, 0, 0.86) 0%, rgba(223, 142, 1, 0.85) 35%, rgba(250, 250, 246, 0.97) 100%) !important;
  left: 0 !important;
  transition: all 0.5s ease-out;

}

.message-content {
  padding: 30px;
  position: relative;
}

.close-but {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
}

.message {
  position: absolute;
  bottom: 0;
  left: -600px;
  width: 100%;
  overflow-x: auto;
  background: linear-gradient(90deg, rgba(3, 182, 189, 0.86) 0%, rgba(52, 224, 246, 0.7) 35%, rgba(246, 249, 250, 0.5) 100%);
  z-index: 10000;
}

main {
  margin-bottom: 10%;
} */

</style>