<template> 
  <div class="notif-card">
    <div class="notif-card__item">
      <img :src="closeIcon" alt="delete icon" class="notif-card__delete">
      <div class="notif-card__header">
        <span class="notif-card__title">Matt Abercrombie</span>
        <span class="notif-card__date">05/04/2023 17:15</span>
      </div>
      <div class="notif-card__text">
        Group changed from Projectile to Mortar
      </div>
    </div>

    <div class="notif-card__item">
      <img :src="closeIcon" alt="delete icon" class="notif-card__delete">
      <div class="notif-card__header">
        <span class="notif-card__title">Matt Abercrombie</span>
        <span class="notif-card__date">05/04/2023 17:15</span>
      </div>
      <div class="notif-card__text">
        Group changed from Projectile to Mortar
      </div>
    </div>

    <div class="notif-card__item">
      <img :src="closeIcon" alt="delete icon" class="notif-card__delete">
      <div class="notif-card__header">
        <span class="notif-card__title">Matt Abercrombie</span>
        <span class="notif-card__date">05/04/2023 17:15</span>
      </div>
      <div class="notif-card__text">
        Group changed from Projectile to Mortar
      </div>
    </div>

    <div class="notif-card__item">
      <img :src="closeIcon" alt="delete icon" class="notif-card__delete">
      <div class="notif-card__header">
        <span class="notif-card__title">Matt Abercrombie</span>
        <span class="notif-card__date">05/04/2023 17:15</span>
      </div>
      <div class="notif-card__text">
        Group changed from Projectile to Mortar
      </div>
    </div>
  </div>
</template>

<script>
import closeIcon from "@/assets/img/icons/close-gray.svg";


  export default {
    setup() {
      return {
        closeIcon
      }
    }
  }
</script>

<style lang="scss" scoped>
.notif-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 445px;
  padding: 24px 24px 24px 16px;

  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);


  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;

    padding: 10px 16px;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    cursor: pointer;

    &:hover {
      background-color: #F6F6F6;
    }
  }

  &__delete {
    position: absolute;
    right: -11px;
    top: -11px;
    &:hover {
      background-color: #ffffff;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: #0B3C61;
  }
  &__date {
    font-size: 12px;
    font-weight: 400;
    color: #363939;
  }
  &__text {
    font-size: 14px;
    font-style: italic;
  }
  
}
</style>