<template> 
  <div class="manage-translations">
    <div class="manage-translations__header">
      <h4>{{ $t('manageTranslations') }}</h4>
      <div class="manage-translations__search">
        <img
          class="manage-translations__search-img"
          :src="searchIcon"
          alt="search icon"
        >
        <input 
          v-model="searchFilter"
          type="text" 
          class="manage-translations__input" 
          :placeholder="$t('search')" 
        >
      </div>
    </div>
    <div class="manage-translations__checkboxes">
      <MyCheckbox 
        v-for="item in filteredItems" 
        :key="item.short"
        :title="item.name"
        :value="item.active"
        :disabled="item.short === 'en'"
        @checked="onChecked(item.id, $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MyCheckbox } from "@/components-ui";
import searchIcon from "@/assets/img/icons/search-light.svg";

  export default {
    name: 'CheckboxesPopup',
    components: {
      MyCheckbox,
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
    },
    // emits: ['onValueChanged'], 
    setup() {
      return {
        searchIcon
      }
    },
    data() {
      return {
        searchFilter: ""
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => {
          return item.name.toLowerCase().includes(this.searchFilter.toLowerCase()) 
        })
      }
    },
    methods: {
      ...mapActions("translate", ["setActiveLocale"]),

      onChecked(id, newValue) {
        this.setActiveLocale({
          localeId: id,
          newValue
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.manage-translations {
  display: flex;
  flex-direction: column;
  /* width: 220px; */
  /* padding: 24px 30px; */

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

  &__header {
    padding: 24px 30px;
    border-bottom: 1px solid #D2D3D3;
  }

  h4 {
    /* padding: 24px 30px 0px 30px; */
    margin-bottom: 26px;
    color: black;
  }

  &__search {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #D2D3D3;

    &-img {
      width: 24px;
      height: 24px;
    }
  }

  &__input {
    width: 100%;
    font-size: 14px;
    border: none;

    &::placeholder {
      color: #D2D3D3;
    }
  }
  
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px 24px 30px;

    label {
      font-size: 16px;
    }
  }
}
</style>