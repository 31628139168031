import store from "@/store";
import router from "@/router";


class AuthService {

    constructor() {
    }

    loginOffice365() {
        // window.console.log(process.env.VUE_APP_BASE_URL + '/auth/office365')
        window.location.href = process.env.VUE_APP_BASE_URL + '/auth/office365';
    }

    loginOffice365Callback(code, state) {
        store.dispatch('auth/loginOffice365Callback', {code, state})
            .then(function () {
                router.push('/')
            })
            .catch(function (err){
                router.push('/login')
            })
    }
}

export default AuthService 