import { country } from "@/api";
import { get, set } from "local-storage"

export default {
  state: {
    items: [],
    countriesOfOrigin: [],
    countriesFoundIn: []
  },

  actions: {
    async getCountryCollection({ dispatch, commit }) {
      try {
        const { data } = await country.getCollection()

        commit("SET_COUNTRY_COLLECTION", [ ...data.result ])
      } catch (error) {
        // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },

    async getCountryOfOriginCollection({ dispatch, commit }) {
      try {
        const { data } = await country.getCollection({countriesOfOrigin: 1})

        commit("SET_COUNTRY_OF_ORIGIN_COLLECTION", [ ...data.result ])
      } catch (error) {
        // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },
    async getCountryFoundInCollection({ dispatch, commit }) {
      try {
        const { data } = await country.getCollection({countriesFoundIn: 1})

        commit("SET_COUNTRY_FOUND_IN_COLLECTION", [ ...data.result ])
      } catch (error) {
        // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    }
  },

  mutations: {
    SET_COUNTRY_COLLECTION(state, list) {
      state.items = list
    },
    SET_COUNTRY_OF_ORIGIN_COLLECTION(state, list) {
      state.countriesOfOrigin = list
    },
    SET_COUNTRY_FOUND_IN_COLLECTION(state, list) {
      state.countriesFoundIn = list
    }
  }
}