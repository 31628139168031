<template>
  <div class="calc-result">
    <strong>{{ $t('result') }}</strong>
    
    <div class="calc-result__item">
      <span>{{ $t('totalAuw') }}</span>
      <strong>{{ result.totalAUW.toFixed() || 0 }} {{ $t('kg') }}</strong>
    </div>
    <div class="calc-result__item">
      <span>{{ $t('safetyDistanceForBulk') }}</span>
      <strong>{{ result.safetyDistance.toFixed() || 0 }} {{ $t('meters') }}</strong>
    </div>
    <div class="calc-result__item">
      <span>{{ $t('notamForBulk') }}</span>
      <strong>{{ result.notam.toFixed() || 0 }} {{ $t('meters') }}</strong>
    </div>
  </div>
</template>

<script>
import { } from "@/components-ui";


export default {
  components: {
  },
  props: {
    result: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['onPopupClose'],
  methods: {
    onPopupOpen() {
      this.popupActive = true;
    },
  },
}
</script>

<style lang="scss" scoped>

.calc-result {
  display: flex;
  flex-direction: column;
  width: 390px;
  /* height: 300px; */
  padding: 23px 30px;
  background-color: #F6F6F6;
  align-items: center;
  border-radius: 10px;

  strong {
    font-size: 18px;
    /* margin-bottom: 10px; */
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 18px 0 10px 0;
    width: 100%;

    strong {
      font-size: 24px;
    }

    &:nth-child(3) {
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }

  }
}

  @media (max-width: 450px) {
    .calc-result {
      width: auto;
    }
  }
</style>