import { device } from "@/api";
import { get, set } from "local-storage"

export default {
  state: {
    // devices: [{"id":11,"imgPath":"/image/100ba768-3e1b-4698-abda-0a29ef1526a1.jpg","name":"name","auw":1,"quantity":1}],
    devices: [],
    result: {}
  },

  actions: {
    async calculateDevices({state, commit, dispatch}, devices) {
      try {
        const { data } = await device.calculateSafetyDistance( devices )

        const transformedData = {
          totalAUW: data.result.total_auw,
          notam: data.result.notam_for_bulk_dem,
          safetyDistance: data.result.safety_distance_for_bulk_dem
        }

        commit('calculator/setResult', transformedData, { root: true })

      } catch {
        // commit('snackbar/SET_ERROR_API', "Calculating error", {root: true}) 
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },
    async addDeviceCalc({state, dispatch, commit }, { device }) {

      const haveSameDevice = state.devices.findIndex(item => item.id === device.id) >= 0
      
      if(!haveSameDevice) {
        commit('calculator/AddNewDevice', device, { root: true })
      }

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Device added', {root: true}) 
    },

    changeQuantityById({state, commit}, {id, value}) {
      const index = state.devices.findIndex(item => item.id === id);

      if (index >= 0, state.devices[index].quantity + value > 0) {
          commit('calculator/ChangeQuantity', {index, value}, { root: true })
      } 
    },

    removeDevice({state, commit}, id) {
      const index = state.devices.findIndex(item => item.id === id);

      if(index >= 0) {
        commit('calculator/removeDevice', index, { root: true })
      }
    }
  },

  mutations: {
    AddNewDevice(state, device) {
      state.devices.push(device)
    },
    ChangeQuantity(state, {index, value}) {
      state.devices[index].quantity = state.devices[index].quantity + value
    },
    removeDevice(state, index) {
      state.devices.splice(index, 1)
    },
    setResult(state, result) {
      state.result = result
    }
  }
}