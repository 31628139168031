import axios from 'axios' 
import router from "@/router";
import {errorHandler} from "@/services/errorHandler";
import { auth } from "@/api";
import { translate } from "@/api";

const actions = {
    loginOffice365Callback({commit, dispatch}, params) {

        return new Promise((resolve) => {
            axios.defaults.headers.common['Authorization'] = null
            axios.get(process.env.VUE_APP_BASE_URL + "/auth/office365/callback", {
                params: params
            })
                .then(resp => {
                    const token = resp.data.result['x_api_key'];
                    // const expires_in = resp.data.result['ttl'];
                    const user_email = resp.data.result.user.email
                    const user_role = resp.data.result.user.role

                    const currentDateTime = new Date();
                    // const resultInSeconds = currentDateTime.getTime() / 1000;
                    localStorage.setItem('HALO_TOKEN', token)
                    // localStorage.setItem('HALO_EXPIRES_IN', resultInSeconds + expires_in)
                    localStorage.setItem('HALO_USER_EMAIL', user_email)
                    localStorage.setItem('HALO_USER_ROLE', user_role)
                    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

                    translate.setTranslate(localStorage.getItem("HALO_LANG") || process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE)

                    commit('auth_success', {token, user_email})
                    resolve()
                    dispatch('device/getDeviceCollection', {}, {root: true})

                })
                .catch(errorHandler);
        })
    },

    async loginViaCredentials({commit, dispatch}, {email = '', password = ''} ) {
        try {
            const { data } = await auth.logInGetToken({
                email,
                password
            })
            const token = data.result['x_api_key'];
            const refreshToken = data.result['refresh_token'];
            const user_email = data.result.user.email
            const user_role = data.result.user.role

            localStorage.setItem('HALO_TOKEN', token)
            localStorage.setItem('HALO_REFRESH_TOKEN', refreshToken)
            localStorage.setItem('HALO_USER_EMAIL', user_email)
            localStorage.setItem('HALO_USER_ROLE', user_role)

            translate.setTranslate(localStorage.getItem("HALO_LANG") || process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE) || 'en'

            router.push({ name: "Devices" });
            dispatch('device/getDeviceCollection', {}, {root: true})

                
        }
        catch (error) {
            dispatch('snackbar/setErrorApi', error, { root: true })
        }
    },

    logout({commit, dispatch}) {
        commit('logout')
        localStorage.removeItem('HALO_TOKEN')
        localStorage.removeItem('HALO_REFRESH_TOKEN')
        router.push({name: 'Login'})
    },
}

export default actions