<template>
  <div>
    <vue3-snackbar 
      top 
      center 
      :duration="2000"
    />
  </div>
</template>

<script>
import { Vue3Snackbar } from "vue3-snackbar";

export default {
  name: "SnackbarModule",
  components: {
    Vue3Snackbar
  },
  setup () {
    return {}
  },
  data() {
    return {
      type: null,
      text: null,
    }
  },  
  methods: {
    callSnacbar() {
      this.$snackbar.add({
        type: this.type,
        text: this.text
      })
    }
  },
  // eslint-disable-next-line vue/order-in-components
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "snackbar/SET_ERROR_API" && typeof mutation.payload === "string") {
        this.text = mutation.payload
        this.type = 'error'
        this.callSnacbar()
        return;
      } 
      else if (mutation.type === "snackbar/SET_ERROR_API" ) {
        const errorData = mutation.payload?.data;
        const errorCode = mutation.payload?.status;
        this.type = 'error'
        this.text = errorCode ? errorCode + " " : "";
        this.text += errorData ? errorData.detail : "";
        this.callSnacbar()
        return;
      } 
      else if (mutation.type === "snackbar/SET_SUCCESS_MESSAGE") {
        this.text = mutation.payload
        this.type = 'success'
        this.callSnacbar()
        return;
      } 
    })
  },
}
</script>

<style lang="scss" scoped>

</style>