<template>
  <div 
    class="pdf-item" 
    @click="onEdit"
  >
    <div class="pdf-item__tooltip">{{ title }}</div>
    <img 
      class="pdf-item__close"
      :src="closeIcon"
      alt="close icon"
      @click="$emit('onClose', id)" 
    >
    <img 
      class="pdf-item__img" 
      :src="pdfIcon" 
      alt="image thumbnail"
    >
  </div>
</template>

<script>
import closeIcon from "@/assets/img/icons/close.svg";
import pdfIcon from "@/assets/img/icons/pdf-light.svg";
export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ""
    },
  },
  emits: ['onClose', 'onPdfClick'], 
  setup () {
    return {
      closeIcon,
      pdfIcon
    }
  },
  methods: {
    onEdit(event) {
      if (event.target.classList.contains('pdf-item__close')) return
      this.$emit('onPdfClick')
    }
  }
}
</script>

<style lang="scss" scoped>
  .pdf-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    cursor: pointer;

    &__close {
      width: 16px;
      position: absolute;
      top: -8px;
      right: -8px;
      opacity: .5;
      cursor: pointer;
    }

    &__img {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      object-fit: cover;
    }

    &__tooltip {
      position: absolute;
      display: none;
      bottom: -35px;
      left: 50%;
      padding: 5px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);


      background-color: #fff;
      border-radius: 5px;

      transform: translateX(-50%);
    }

    &:hover > &__tooltip {
      display: block;
    }
  }
</style>