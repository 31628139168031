<template> 
  <div class="popup-attachments">
    <!-- <button 
      class="popup-attachments__close" 
      @click="$emit('onPopupClose')"
    >
      <img 
        :src="closeIcon"
        alt="close icon"
      >
    </button> -->

    <div 
      v-show="isImgShown"
      class="popup-attachments__img" 
    >
      <button 
        class="popup-attachments__close" 
        @click="$emit('onPopupClose')"
      >
        <img 
          :src="closeIcon"
          alt="close icon"
        >
      </button>
      <img 
        :src="pdfIcon" 
        alt="pdf icon"
      >
    </div>
    
    <div class="popup-attachments__wrapper">
      <div class="popup-attachments__input">
        <label>
          <span>{{ $t('name') }}</span> 
          <MyInput 
            v-model="pdfData.name"
            :class="[validate.name ? null : 'border-danger']"
            @input="validateItem('name')"
          />
        </label>
      </div>

      <div class="popup-attachments__input">
        <label>
          <span>{{ $t('description') }}</span> 
          <textarea 
            v-model="pdfData.description"
            class="form-control popup-attachments__textarea" 
            :class="[validate.description ? null : 'border-danger']"
            :style="{border: '2px solid #D2D3D3'}"
            @input="validateItem('description')"
          />
        </label>
      </div>

      <div class="popup-attachments__footer">
        <MyButton 
          :title="$t('add')" 
          @click="onAttachmentAdd"
        />
        <MyButton 
          :title="$t('cancel')"  
          type="o"
          @click="$emit('onPopupClose')" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MyInput, MySelect, MyButton } from '@/components-ui'

import closeIcon from "@/assets/img/icons/close-light.svg";
import pdfIcon from "@/assets/img/icons/pdf-light.svg";

export default {
  components: {
    MyInput,
    MyButton
  },
  props: {
    currentPdfData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['onPopupClose', 'onPdfAdded', 'onPdfEdited'],
  setup () {
    return {
      closeIcon,
      pdfIcon
    }
  },
  data() {
    return {
      pdfData: {
        name: '',
        description: ''
      },
      validate: {
        name: true,
        description: true,
      },
      isImgShown: true
    }
  },
  methods: {
    onAttachmentAdd() {
      const pdfData = {...this.currentPdfData, ...this.pdfData}

      if(this.validateAll()) {
        this.currentPdfData.editing 
          ? this.$emit('onPdfEdited', pdfData) 
          : this.$emit('onPdfAdded', {
            ...pdfData,
            id: Date.now(),
            new: true,
          })
      }
    },
    validateItem(name) {
      this.validate[name] = !!this.pdfData[name];
    },
    validateAll() {
      this.validate.name = !!this.pdfData.name;
      this.validate.description = !!this.pdfData.description;

      return Object.values(this.validate).every(value => value === true);
    }
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    if(window.innerHeight < 700) {
      this.isImgShown = false
    }
    this.pdfData = {
      name: this.currentPdfData.name,
      description: this.currentPdfData.description
    }
  }
}
</script>

<style lang="scss" scoped>


.popup-attachments {
    position: relative;
    width: 370px;
    /* height: 700px; */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    overflow: hidden;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    margin-bottom: 4px;
    background-color: #EFEFEF;
  }

  &__input {

    &:nth-child(1) {
      padding: 20px 24px 16px 24px;
    }
    &:nth-child(2) {
      padding: 0 24px 30px 24px;
    }

    label {
      width: 100%;
      margin-bottom: 0;

      span {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 700;
        color: black;
      }
    }
  }
  &__footer {
    display: flex;
    gap: 30px;
    padding: 0px 24px 30px 24px ;

    button {
      height: 36px;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border:none;
    background-color: transparent;
    z-index: 999;
  }

  &__textarea {
    resize: none;
    height: 85px;
  }
}

@media (max-width: 400px) {
  .popup-attachments {
    width: 300px;
  }
}
</style>