import { user } from "@/api";
import ls from "local-storage";
import router from '@/router';

export const actions = {

  async getUserCollection({ commit, dispatch }, { params = {} } = {}) {
    try {
      commit('user/SetUsersLoaded', true, {root: true})

      const { data } = await user.getCollection({ params })

      commit('user/SetUserCollection', { data: data.result }, { root: true })
      
    } catch (error) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async getUser({ commit, dispatch, state }, { userId }) {
    try {

      if(state.user.userId != userId) {
        const { data } = await user.getUser(userId);
        const userData = data.result;
  
        const transformedData = {
          userId: userData?.id,
          firstName: userData?.first_name,
          lastName: userData?.last_name,
          email: userData?.email,
          role: userData?.role,
          deleted: userData?.deleted,
          isOffice365: userData?.is_office365
        }
  
        commit('user/SetUser', { data: transformedData }, { root: true })
      }

    } catch ( error ) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async createUser({ commit, dispatch }, { userData = {} } = {}) {
    try {
      const body = {
        first_name: userData?.firstName,
        last_name: userData?.lastName,
        email: userData?.email,
        password: userData?.password,
        password_confirmation: userData?.repeatPassword,
        role: userData?.role,
        is_office365: userData?.isOffice365
      }

      const { data } = await user.create(body)

      commit('snackbar/SET_SUCCESS_MESSAGE', 'User successfully created', {root: true})

      commit('user/SetUser', { data: data.result }, { root: true })

      dispatch('user/getUserCollection', {}, { root: true })

      router.push({
        name: 'Users' 
      })

    } catch (error) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async updateUser({ commit, dispatch }, { userId, userData = {}} = {}) {
    try {
      const body = {
        first_name: userData?.firstName,
        last_name: userData?.lastName,
        email: userData?.email,
        password: userData?.password,
        password_confirmation: userData?.repeatPassword,
        role: userData?.role,
      }

      const { data } = await user.update({userId, body})

      commit('snackbar/SET_SUCCESS_MESSAGE', 'User successfully updated', {root: true})

      commit('user/SetUser', { data: data.result }, { root: true })

      dispatch('user/getUserCollection', {}, { root: true })

      router.push({
        name: 'Users' 
      })

    } catch (error) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async deleteUser({ commit, dispatch }, { userId }) {
    try {

      const { data } = await user.delete(userId);

      commit('user/SetUser', { data: data.result }, { root: true })
      
      dispatch('user/getUserCollection', {}, { root: true })

      commit('snackbar/SET_SUCCESS_MESSAGE', 'User successfully deleted', {root: true})

      router.push({
        name: 'Users' 
      })
    } catch ( error ) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async importUsers({commit, dispatch}, { file }) {
    try {

      const usersCSV = new FormData();

      usersCSV.append('file', file);

      const { data } = await user.importUsers({ file: usersCSV })

      dispatch('user/getUserCollection', {}, { root: true })

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Users successfuly added', {root: true}) 
    } catch (error) {
      // commit('snackbar/SET_ERROR_API', "Error in users import", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
      
    }
  },

  async restoreUser({ commit, dispatch }, { userId }) {
    try {

      const { data } = await user.restore(userId);

      commit('user/SetUser', { data: data.result }, { root: true })

      commit('snackbar/SET_SUCCESS_MESSAGE', 'User successfully restored', {root: true})

      dispatch('user/getUserCollection', {}, { root: true })

      router.push({
        name: 'Users' 
      })
    } catch ( error ) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

}