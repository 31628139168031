<template> 
  <input 
    class="form-control my-input"
    :value="modelValue"
    min="1"
    @input="updateInput"
  >
</template>

<script>
export default {
  name: "MyInput",
  props: {
    modelValue: [String, Number]
  },
  emits: ['update:model-value'],
  methods: {
    updateInput(event) {
      this.$emit('update:model-value', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .my-input {
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    color: #363939;
    border: 2px solid #D2D3D3;
    margin: 0;

  }
</style>