<template>
  <div class="lang-switch"> 
    <!-- <div class="lang-switch__item">
      <img :src="checkIcon" alt="icon check" class="lang-switch__check lang-switch__check_active">
      <img :src="enIcon" alt="icon flag en " class="lang-switch__flag">
      <span>
        English
      </span>
    </div>
    <div class="lang-switch__item">
      <img :src="checkIcon" alt="icon check " class="lang-switch__check">
      <img :src="uaIcon" alt="icon flag ua " class="lang-switch__flag">
      <span>
        Ukraine
      </span>
    </div>
    <div class="lang-switch__item">
      <img :src="checkIcon" alt="icon check " class="lang-switch__check">
      <img :src="ruIcon" alt="icon flag ru " class="lang-switch__flag">
      <span>
        Russian
      </span>
    </div> -->
    <div 
      v-for="lang in translates"
      :key="lang.short"
      class="lang-switch__item"
      @click="$emit('onLangClick', lang)"
    >
      <img 
        :style="{opacity: lang.short === $i18n.locale ? '1' : '0'}"
        :src="checkIcon" 
        alt="icon check" 
        class="lang-switch__check lang-switch__check_active">
      <!-- <img :src="enIcon" alt="icon flag en " class="lang-switch__flag"> -->
      <span>
        <!-- English -->
        {{ lang.name }}
      </span>
    </div>
  </div>
</template>

<script>
import enIcon from "@/assets/img/flags/en.png";
import uaIcon from "@/assets/img/flags/ua.png";
import ruIcon from "@/assets/img/flags/ru.png";
import checkIcon from "@/assets/img/icons/check-blue.svg";


  export default {
    props: {
      translates: {
        type: Array,
        default: () => []
      }
    },
    setup() {
      return {
        enIcon,
        uaIcon,
        ruIcon,
        checkIcon
      }
    },
  }
</script>

<style lang="scss" scoped>
.lang-switch {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* width: 120px; */

  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);


  &__item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 16px 12px 16px;


    gap: 5px;

    span {
      position: relative;
      top: 2px;
      margin-left: 5px;
      font-size: 12px;
      font-weight: 700;
    }

    &:last-child {
      padding-bottom: 16px;
    }

    &:hover {
      background-color: #dedbdb;
    }
  }

  &__check {
    opacity: 0;
    &_active {
      opacity: 1;
    }
  }

  &__flag {
    width: 12px;
    height: 12px;
  }
}
</style>