<template>
  <button class="add-image">
    <img 
      class="add-image__icon" 
      :src="plusIcon"  
      alt="plus icon"
    >
  </button>
</template>

<script>
import plusIcon from "@/assets/img/icons/plus.svg";

export default {
  name: "AddImgButton",
  props: {
  },
  setup () {
    return {
      plusIcon,
    }
  }
}
</script>

<style lang="scss" scoped>

.add-image {
  width: 48px;
  height: 48px;
  background: #008DA8;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;

  &__icon {
    filter: invert(1)
  }
}
</style>