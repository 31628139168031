
<template> 
  <div 
    v-if="popupActive"
    class="popup"
  >
    <div v-click-outside="onPopupClose">
      <slot />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

import vClickOutside from 'click-outside-vue3'

export default {
  name: 'PopupWrapper',
  directives: {
    clickOutside: vClickOutside.directive
  },

  setup (props, context) {
    let popupActive = ref(false);

    return {
      popupActive
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    onPopupOpen() {
      this.popupActive = true;
    },
    onPopupClose() {
      this.popupActive = false;
    }
  }, 
}
</script>

<style lang="scss">
.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f222375;
}
</style>