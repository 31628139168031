<template>
  <section class="device-edit">
    <PopupWrapper ref="editPdfPopup">
      <AddAttachmentsPopup
        :currentPdfData="currentPdf"
        @onPdfEdited="onPdfEdited"
        @onPdfAdded="onPdfAdded"
        @onPopupClose="() => onPopupAction('editPdfPopup', 'close')" 
      />
    </PopupWrapper>

    <PopupWrapper ref="editImagePopup">
      <AddImagePopup 
        :sourceValues="$sources"
        :licenseValues="$deviceAdds.licenses"
        :currentImgData="currentImg"
        @onImgAdded="onImgAdded"
        @onImgEdited="onImgEdited"
        @onPopupClose="() => onPopupAction('editImagePopup', 'close')"
      />
    </PopupWrapper>
    
    <PopupWrapper ref="addHazardPopup">
      <CheckboxesPopup 
        :items="hazardsValues"
        @onValueChanged="(id, newValue) => {onCheckboxesValueChanged(id, newValue, 'hazards')}"
      />
    </PopupWrapper>

    <PopupWrapper ref="addSourcePopup">
      <AddSourcePopup 
        :items="sourcesValues"
        @onValueChanged="(id, newValue) => {onCheckboxesValueChanged(id, newValue, 'sources')}"
      />
      <!-- <CheckboxesSearchPopup 
        :title="$t('addSource')"
        :items="sourcesValues"
        @onValueChanged="(id, newValue) => {onCheckboxesValueChanged(id, newValue, 'sources')}"
      /> -->
    </PopupWrapper>

    <PopupWrapper ref="addExplosiveTypePopup">
      <CheckboxesSearchPopup
        :title="'explosiveType'"
        :items="explosiveTypesValues"
        @onValueChanged="(id, newValue) => {onCheckboxesValueChanged(id, newValue, 'explosiveTypes')}"
      />
    </PopupWrapper>

    <PopupWrapper ref="addFoundInPopup">
      <CheckboxesSearchPopup
        :title="'foundIn'"
        :items="foundInValues"
        @onValueChanged="(id, newValue) => {onCheckboxesValueChanged(id, newValue, 'foundIn')}"
      />
    </PopupWrapper>

    <PopupWrapper ref="addFuzingPopup">
      <CheckboxesSearchPopup
        :title="'fuzingOption'"
        :items="fuzingOptionsValues"
        @onValueChanged="(id, newValue, title) => {onFuzingOptionsValueChanged(id, newValue, title)}"
      />
    </PopupWrapper>

    <PopupWrapper ref="addRelatedDevicesPopup">
      <CheckboxesSearchPopup
        :title="'relatedDevices'"
        :items="relatedDevicesValues"
        @onValueChanged="(id, newValue, title) => {onRelatedDevicesValueChanged(id, newValue, title)}"
      />
    </PopupWrapper>

    <PopupWrapper ref="addGoimsDevices">
      <GoimsSearchPopup
        :title="$t('goimsDevices')"
        :items="goimsDevicesValues"
        @onValueChanged="onGoimsValueChanged"
      />
    </PopupWrapper>

    <PopupWrapper ref="areYouSurePopup">
      <AreYouSurePopup
        @onAccept="onDeviceDelete"
        @onPopupClose="() => onPopupAction('areYouSurePopup', 'close')" 
      />
    </PopupWrapper>
    
    <div class="my-container">
      <div class="device-edit__header">
        <span>
          {{ $route.params.id ? $t('editDevice') : $t('newDeviceAdding') }} -> 
          <strong>{{ stepTitle }}</strong>
        </span> 
        <span><strong>{{ $t('step') }} {{ step }} {{ $t('of') }} </strong> 2 </span> 
      </div>
      <button 
        class="device-edit__back-btn" 
        @click="onBackBtn"
      >
        {{ $t('back') }} 
      </button>
      <form class="device-edit__card needs-validation" ref="form1">
        <Spinner :loading="loading" />
        <div v-show="!loading">
          <div 
            v-show="step === 1" 
            class="device-edit__wrapper"
          >
            <div class="device-edit__general">
              <h3 class="device-edit__general-title"> 
                {{ $t('generalInformation') }}  
              </h3>
              <label>
                {{ $t('name') }}  
              </label>
              <MyInput 
                v-model="deviceData.name"
                :class="[validate[0].name ? null : 'border-danger']"
                @input="validateItem('name', 'input')"
              />
              <label>
                {{ $t('group') }} 
              </label>
              <MySelect 
                v-model="deviceData.group"
                :options="$deviceAdds.groups" 
                :title="$t('select')"
                :class="[validate[0].group ? null : 'border-danger']"
                @change="validateItem('group', 'input')"
              />
              <label>
                {{ $t('subGroup') }} 
              </label>
              <MySelect 
                v-model="deviceData.subGroup"
                :options="$deviceAdds.sub_groups" 
                :title="$t('select')"
                :class="[validate[0].subGroup ? null : 'border-danger']"
                @change="validateItem('subGroup', 'input')"
              />
              <label>
                {{ $t('role') }}  
              </label>
              <MySelect 
                v-model="deviceData.role"
                :options="$deviceAdds.roles" 
                :title="$t('select')"
                :class="[validate[0].role ? null : 'border-danger']"
                @change="validateItem('role', 'input')"
              />
              <label>
                {{ $t('hazards') }}  
              </label>
              
              <div class="device-edit__chips">
                <AddButton 
                  @click.prevent="() => onPopupAction('addHazardPopup', 'open')" 
                />
                <div 
                  v-show="!validate[0].hazards"
                  class="device-edit__required"
                >
                  {{ $t('required') }}  
                </div>
                <ChipsItem 
                  v-for="hazard in chips.hazards"
                  :id="hazard.id"
                  :key="hazard.id"
                  :text="hazard.name" 
                  @onChipsClose="(id) => onCheckboxesValueChanged(id, false, 'hazards')"
                />
              </div>
              <label>
                {{ $t('source') }} 
              </label>
              <div class="device-edit__chips">
                <AddButton @click.prevent="() => onPopupAction('addSourcePopup', 'open')" />
                <div 
                  v-show="!validate[0].sources"
                  class="device-edit__required"
                >
                  {{ $t('required') }} 
                </div>
                <ChipsItem 
                  v-for="source in chips.sources"
                  :id="source.id"
                  :key="source.id"
                  :text="source.name" 
                  @onChipsClose="(id) => onCheckboxesValueChanged(id, false, 'sources')"
                />
              </div>
            </div>
            <div class="device-edit__right">
              <div class="device-edit__images">
                <h3>{{ $t('uploadImages') }}</h3> 
                <div>
                  <AddImgButton @click.prevent="selectImageFile" />
                  <div 
                    v-show="!validate[0].imgCollection"
                    class="device-edit__required"
                  >
                    {{ $t('required') }} 
                  </div>
                  <ImgItem 
                    v-for="img in imgCollection"
                    :id="img.id"
                    :key="img"
                    :imgPath="img.path"
                    @onClose="onImgClose"
                    @onImgClick="onImageEdit(img.id)"
                  />
                </div>
              </div>
              <div class="device-edit__attachments">
                <h3>{{ $t('uploadAttachments') }}</h3> 
                <div>
                  <AddImgButton @click.prevent="selectPdfFile" />
                  <!-- <div 
                    v-show="!validate[0].pdfCollection"
                    class="device-edit__required"
                  >
                    {{ $t('required') }} 
                  </div> -->
                  <PdfItem
                    v-for="pdf in pdfCollection"
                    :id="pdf.id"
                    :key="pdf.id"
                    :title="pdf.name"
                    @onClose="onPdfClose"
                    @onPdfClick="onPdfEdit(pdf.id)"
                  />
                </div>
              </div>
              <div class="device-edit__description">
                <h3>{{ $t('description') }}</h3> 
                <MyEditor 
                  :validate="validate[0].description"
                  v-model="deviceData.description"
                  @onEditorChanged="onEditorChanged" 
                />
              </div>
            </div>
          </div>
          <div 
            v-if="step === 2" 
            class="device-edit__details"
          >
            <h3>{{ $t('deviceDetails') }}</h3> 
            <div class=" row">
              <div class="col-lg-4 col-md-12 device-edit__details-wrapper">
                <div class="row">
                  <div class="col">
                    <label>{{ $t('auw') }}, {{ $t('kg') }}</label> 
                    <MyInput 
                      v-model="deviceData.auw" 
                      :class="[validate[1].auw ? null : 'border-danger']"
                      type="number"
                      @input="validateItem('auw', 'input')"
                    />
                  </div>
                  <div class="col">
                    <label>{{ $t('neq') }}, {{ $t('kg') }}</label> 
                    <MyInput 
                      v-model="deviceData.neq" 
                      :class="[validate[1].neq ? null : 'border-danger']"
                      type="number"
                      @input="validateItem('neq', 'input')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ $t('completeRoundWeight') }}, {{ $t('kg') }}</label> 
                    <MyInput 
                      v-model="deviceData.completeRoundWeight" 
                      :class="[validate[1].completeRoundWeight ? null : 'border-danger']"
                      type="number"
                      @input="validateItem('completeRoundWeight', 'input')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ $t('propellantWeight') }}, {{ $t('kg') }}</label> 
                    <MyInput 
                      v-model="deviceData.propellantWeight" 
                      :class="[validate[1].propellantWeight ? null : 'border-danger']"
                      type="number"
                      @input="validateItem('propellantWeight', 'input')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex flex-column">
                    <label>{{ $t('explosiveType') }}</label> 
                    <div class="device-edit__chips">
                      <AddButton @click.prevent="() => onPopupAction('addExplosiveTypePopup', 'open')" />
                      <!-- <div 
                        v-show="!validate[1].explosiveTypes"
                        class="device-edit__required"
                      >
                        {{ $t('required') }} 
                      </div> -->
                      <ChipsItem 
                        v-for="explosiveType in chips.explosiveTypes"
                        :id="explosiveType.id"
                        :key="explosiveType.id"
                        :text="explosiveType.name" 
                        @onChipsClose="(id) => onCheckboxesValueChanged(id, false, 'explosiveTypes')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ $t('length') }}, {{ $t('mm') }}</label> 
                    <MyInput 
                      v-model="deviceData.length" 
                      :class="[validate[1]['length'] ? null : 'border-danger']"
                      type="number"
                      @input="validateItem('length', 'input')"
                    />
                  </div>
                  <div class="col">
                    <label>{{ $t('diameter') }}, {{ $t('mm') }}</label> 
                    <MyInput 
                      v-model="deviceData.diameter" 
                      :class="[validate[1].diameter ? null : 'border-danger']"
                      type="number"
                      @input="validateItem('diameter', 'input')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 device-edit__details-wrapper">
                <div class="row">
                  <div class="col">
                    <label>{{ $t('width') }}, {{ $t('mm') }}</label> 
                    <MyInput 
                      v-model="deviceData.width" 
                      :class="[validate[1].width ? null : 'border-danger']"
                      type="number"
                      @input="validateItem('width', 'input')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex flex-column">
                    <label>{{ $t('foundIn') }}</label> 
                    <div class="device-edit__chips">
                      <AddButton @click.prevent="() => onPopupAction('addFoundInPopup', 'open')" />
                      <div 
                        v-show="!validate[1].foundIn"
                        class="device-edit__required"
                      >
                        {{ $t('required') }}
                      </div>
                      <ChipsItem 
                        v-for="country in chips.foundIn"
                        :id="country.id"
                        :key="country.id"
                        :text="country.name" 
                        @onChipsClose="(id) => onCheckboxesValueChanged(id, false, 'foundIn')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>{{ $t('CountryOrigin') }}</label> 
                    <MySelect 
                      v-model="deviceData.countryOrigin"
                      :options="$countriesOfOrigin"
                      :title="$t('select')"
                      :class="[validate[1].countryOrigin ? null : 'border-danger']"
                      @change="validateItem('countryOrigin', 'input')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex flex-column">
                    <label>{{ $t('fuzingOptions') }}</label> 
                    <div class="device-edit__chips">
                      <AddButton @click.prevent="() => onPopupAction('addFuzingPopup', 'open')" />
                      <div 
                        v-show="!validate[1].fuzingOptions"
                        class="device-edit__required"
                      >
                        {{ $t('required') }} 
                      </div>
                      <ChipsItem 
                        v-for="option in chips.fuzingOptions"
                        :id="option.id"
                        :key="option.id"
                        :text="option.name" 
                        @onChipsClose="(id) => onFuzingOptionsValueChanged(id, false)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 device-edit__details-wrapper">
                <div class="row">
                  <div class="col d-flex flex-column">
                    <label>{{ $t('relatedDevices') }}</label> 
                    <div class="device-edit__chips">
                      <AddButton @click.prevent="() => onPopupAction('addRelatedDevicesPopup', 'open')" />
                      <div 
                        v-show="!validate[1].relatedDevices"
                        class="device-edit__required"
                      >
                        {{ $t('required') }}  
                      </div>
                      <ChipsItem 
                        v-for="device in chips.relatedDevices"
                        :id="device.id"
                        :key="device.id"
                        :text="device.name" 
                        @onChipsClose="(id) => onRelatedDevicesValueChanged(id, false)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex flex-column">
                    <label>{{ $t('goimsDevices') }} </label> 
                    <div class="device-edit__chips">
                      <AddButton @click.prevent="() => onPopupAction('addGoimsDevices', 'open')" />
                      <div 
                        v-show="!validate[1].goimsDevices"
                        class="device-edit__required"
                      >
                        {{ $t('required') }} 
                      </div>
                      <ChipsItem 
                        v-for="device in chips.goimsDevices"
                        :id="device.id"
                        :key="device.id"
                        :text="device.name" 
                        @onChipsClose="(goimId) => onGoimsValueChanged({id: goimId, newValue: false})"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col">
                    <label>{{ $t('relatedEoEvent') }}</label> 
                    <MyInput 
                      v-model="deviceData.relatedEoEvent" 
                      :class="[validate[1].relatedEoEvent ? null : 'border-danger']"
                      @input="validateItem('relatedEoEvent', 'input')"
                    />
                  </div>
                </div> -->
                <div class="row">
                  <div class="col">
                    <label>{{ $t('model3d') }}</label> 
                    <MyInput 
                      v-model="deviceData.model3D" 
                      :class="[validate[1].model3D ? null : 'border-danger']"
                      @input="validateItem('model3D', 'input')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="device-edit__footer">
          <router-link 
            :to="{name: 'Devices'}" 
          >
            <MyButton 
              :title="$t('cancel')"
              :type="'o'"
            />
          </router-link>
          <MyButton 
            v-show="$route.params.id"
            :title="$t('delete')"
            :style="{'background-color': '#fd8e14'}"
            @click.prevent="onPopupAction('areYouSurePopup', 'open')" 
          />
          <MyButton 
            v-show="!loading"
            :disabled="saveButtonDisabled"
            :title="nextButtonTitle" 
            @click.prevent="nextStep" 
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash";

import { 
  MyInput, 
  MySelect, 
  AddButton, 
  AddImgButton, 
  MyEditor, 
  MyButton, 
  PopupWrapper, 
  AddImagePopup, 
  AddAttachmentsPopup,
  CheckboxesPopup,
  AddSourcePopup,
  CheckboxesSearchPopup,
  ImgItem,
  ChipsItem,
  PdfItem,
  AreYouSurePopup,
  Spinner,
  GoimsSearchPopup
} from "@/components-ui"

export default {
  name: "DeviceEdit",
  components: {
    MyInput,
    MySelect,
    AddButton,
    AddImgButton,
    MyEditor,
    MyButton,
    PopupWrapper,
    AddImagePopup,
    AddAttachmentsPopup,
    CheckboxesPopup,
    AddSourcePopup,
    CheckboxesSearchPopup,
    ImgItem,
    ChipsItem,
    PdfItem,
    AreYouSurePopup,
    Spinner,
    GoimsSearchPopup
  },
  setup () {
    return {}
  },
  data() {
    return {
      step: 1,
      loading: false,
      saveButtonDisabled: false,
      deviceData: {
        name: '',
        group: '',
        subGroup: '',
        role: '',
        hazards: [],
        sources: [],
        description: '',
        auw: '',
        neq: '',
        completeRoundWeight: '',
        propellantWeight: '',
        explosiveTypes: [],
        length: '',
        diameter: '',
        width: '',
        foundIn: [],
        countryOrigin: null,
        fuzingOptionsCollection: [],
        relatedDevicesCollection: [],
        goimsDevices: [],
        // relatedEoEvent: '',
        model3D: ''
      },
      imgCollection: [],
      currentImg: {
        id: null,
        file: null,
        path: null,
        caption: null,
        source: null,
        license: null
      },
      pdfCollection: [],
      currentPdf: {
        id: null,
        file: null,
      },
      hazardsValues: [],
      explosiveTypesValues: [],
      foundInValues: [],
      // fuzingOptionsValues: [],
      // relatedDevicesValues: [],
      validate: [
        {
          name: true,
          group: true,
          subGroup: true,
          role: true,
          hazards: true,
          sources: true,
          imgCollection: true,
          description: true,
        },
        {
          auw: true,
          neq: true,
          completeRoundWeight: true,
          propellantWeight: true,
          length: true,
          diameter: true,
          width: true,
          foundIn: true,
          countryOrigin: true,
          fuzingOptions: true,
          relatedDevices: true,
          goimsDevices: true,
          // relatedEoEvent: true,
          model3D: true
        }
      ]
    }
  },
  computed: {
    ...mapState("device", {
      $device: "device",
      $deviceAdds: "adds",
      $devices: "devices",
      $goims: "goims"
    }),
    ...mapState("country", {
      $countriesOfOrigin: "countriesOfOrigin",
      $countriesFoundIn: "countriesFoundIn",
    }),
    ...mapState("source", {
      $sources: "sources",
    }),

    stepTitle() {
      return this.step === 1 ? this.$t('stepGeneralInformation') : this.$t('stepDeviceDetails') 
    },

    nextButtonTitle() {
      return  this.step === 1 ? this.$t('next') : this.$t('save') 
    },

    chips() {
      return {
        hazards: this.hazardsValues.filter(item => item.checked),
        sources: this.sourcesValues.filter(item => item.checked),
        explosiveTypes: this.explosiveTypesValues.filter(item => item.checked),
        foundIn: this.foundInValues.filter(item => item.checked),
        relatedDevices: this.deviceData.relatedDevicesCollection,
        fuzingOptions: this.deviceData.fuzingOptionsCollection,
        goimsDevices: this.deviceData.goimsDevices,
      }
    }, 

    sourcesValues() {
      return this.$sources.map(source => ({
        ...source,
        checked: this.deviceData.sources.includes(source.id)
      }));
    },

    relatedDevicesValues(){
      return this.$devices.map(device => ({
        id: device.id,
        name: device.name,
        checked: this.deviceData.relatedDevicesCollection.some(item => item.id === device.id)
      }));
    },

    fuzingOptionsValues() {
      return this.$devices.map(device => ({
        id: device.id,
        name: device.name,
        checked: this.deviceData.fuzingOptionsCollection.some(item => item.id === device.id)
      }));
    },

    goimsDevicesValues() {
      return this.$goims.map(goim => ({
        id: goim.id,
        name: goim.name,
        checked: this.deviceData.goimsDevices.some(item => item.id === goim.id)
      }));
    }
  },
  methods: {
    ...mapActions("device", [
      "getDevice", 
      "getDeviceAdds", 
      "createDevice", 
      "uploadAttachment", 
      "deleteAttachment", 
      "addAttachment", 
      "updateDevice", 
      "getDeviceCollection",
      "deleteDevice",
      "updateAttachment",
      "getGoimsCollection"]),
    ...mapActions("country", ["getCountryCollection", "getCountryOfOriginCollection", "getCountryFoundInCollection"]),
    ...mapActions("snackbar", ["setErrorMessage"]),
    ...mapActions("source", ["getSourceCollection"]),

    onBackBtn() {
      if(this.step === 2) {
        this.step = 1;
      } else {
        this.$router.push(this.$route.params.id ? {name: 'Device', id: this.$route.params.id} : {name: 'Devices'})
      }
    },

    async nextStep() {
      if(this.step === 1) {
        const validationPassed = this.validateAll();

        if (validationPassed) this.step = 2; 

      } else {
        const validationPassed = this.validateAll();

        if(validationPassed) {
          this.saveButtonDisabled = true;
          if(this.$route.params.id) {
            await this.updateDevice( {
              deviceId: this.$route.params.id,
              deviceData: this.deviceData, 
              imgCollection:this.imgCollection, 
              pdfCollection: this.pdfCollection
            })
          } else {
            await this.createDevice( {
              deviceData: this.deviceData, 
              imgCollection:this.imgCollection, 
              pdfCollection: this.pdfCollection
            })
          }
          this.saveButtonDisabled = false;
        }
      }
    },

    validateAll() {
      if(this.step === 1) {

        this.validate[0].name = !!this.deviceData.name;
        this.validate[0].group = !!this.deviceData.group;
        this.validate[0].subGroup = !!this.deviceData.subGroup;
        this.validate[0].role = !!this.deviceData.role;
        this.validate[0].description = !!this.deviceData.description;

        this.validate[0].hazards = !!this.deviceData.hazards.length;
        this.validate[0].sources = !!this.deviceData.sources.length;

        this.validate[0].imgCollection = !!this.imgCollection.length;
        // this.validate[0].pdfCollection = !!this.pdfCollection.length;

        return Object.values(this.validate[0]).every(value => value === true)

      } else {

        this.validate[1].auw = Number(this.deviceData.auw) >= 0 && this.deviceData.auw !== "";
        this.validate[1].neq = Number(this.deviceData.neq) >= 0 && this.deviceData.neq !== "" && Number(this.deviceData.neq) <= Number(this.deviceData.auw);
        this.validate[1].completeRoundWeight = this.deviceData.completeRoundWeight !== "" || Number(this.deviceData.completeRoundWeight) >= 0 ;
        this.validate[1].propellantWeight = this.deviceData.propellantWeight !== "" || Number(this.deviceData.propellantWeight) >= 0;
        this.validate[1]['length'] = this.deviceData['length'] == "" || Number(this.deviceData['length']) >= 0 ;
        this.validate[1].diameter = Number(this.deviceData.diameter) >= 0 && this.deviceData.diameter !== "";
        this.validate[1].width =this.deviceData.auw !== "" || Number(this.deviceData.width) >= 0;
        this.validate[1].countryOrigin = !!this.deviceData.countryOrigin;
        // this.validate[1].relatedEoEvent = !!this.deviceData.relatedEoEvent;
        // this.validate[1].model3D = !!this.deviceData.model3D;

        // this.validate[1].explosiveTypes = !!this.deviceData.explosiveTypes.length;
        this.validate[1].foundIn = !!this.deviceData.foundIn.length;
        // this.validate[1].fuzingOptions = !!this.deviceData.fuzingOptions.length;
        // this.validate[1].relatedDevices = !!this.deviceData.relatedDevices.length;
        // this.validate[1].goimsDevices = !!this.deviceData.goimsDevices.length;

        return Object.values(this.validate[1]).every(value => value === true)
      }
    },

    validateItem(name, type) {

        if(type === 'input') {
          const requiredNumberKeys = ['auw', 'neq','diameter']
          const notRequiredNumberKeys = ['length', 'width', 'completeRoundWeight', 'propellantWeight']

          if(name === 'neq') {
            this.validate[this.step - 1][name] = 
              this.deviceData[name] !== "" && 
              Number(this.deviceData[name]) >= 0 && 
              Number(this.deviceData[name]) <=  Number(this.deviceData.auw)
          } 
          else if (name == 'model3D') {
            return
          }
          else if(notRequiredNumberKeys.findIndex(item => name === item) != -1) {
            this.validate[this.step - 1][name] = this.deviceData[name] == "" || Number(this.deviceData[name]) >= 0
          }
          else if(requiredNumberKeys.findIndex(item => name === item) != -1) {
            this.validate[this.step - 1][name] = this.deviceData[name] !== "" && Number(this.deviceData[name]) >= 0;
          } else {
            this.validate[this.step - 1][name] = !!this.deviceData[name];
          }
        }
        
        if(type === 'chips') {
          const notReqiuredChips = ['explosiveTypes', 'fuzingOptions', 'relatedDevices', 'goimsDevices']

          if(notReqiuredChips.findIndex(item => name === item) == -1) {
            this.validate[this.step - 1][name] = !!this.deviceData[name].length;
          }
        }
        if(type === 'attachments') {
          this.validate[this.step - 1][name] = !!this[name].length;
        }
    },

    onPopupAction(refName, Action) {
      if (Action === "open") {
        this.$refs[refName].onPopupOpen()
      } else {
        this.$refs[refName].onPopupClose()
      }
    },  
    
    onCheckboxesValueChanged(itemId, newValue, valueName) {

      const index = this[`${valueName}Values`].findIndex(item => item.id === itemId);

      this[`${valueName}Values`][index].checked = newValue
     
      this.deviceData[valueName] = this[`${valueName}Values`].filter(item => item.checked).map(item => {
        return item.id
      });

      this.validateItem(valueName, 'chips')

    },

    onGoimsValueChanged({id, name, newValue}) {

      if(newValue) {
        this.deviceData.goimsDevices.push({id: id, name: name })
      } else {
        const index = this.deviceData.goimsDevices.findIndex(item => item.id === id)
        this.deviceData.goimsDevices.splice(index, 1)
      }
    },

    onFuzingOptionsValueChanged(id, newValue, title) {

      if(newValue) {
        this.deviceData.fuzingOptionsCollection.push({id: id, name: title })
      } else {
        const index = this.deviceData.fuzingOptionsCollection.findIndex(item => item.id === id)
        this.deviceData.fuzingOptionsCollection.splice(index, 1)
      }
    },

    onRelatedDevicesValueChanged(id, newValue, title) {

      if(newValue) {
        this.deviceData.relatedDevicesCollection.push({id: id, name: title })
      } else {
        const index = this.deviceData.relatedDevicesCollection.findIndex(item => item.id === id)
        this.deviceData.relatedDevicesCollection.splice(index, 1)
      }
    },

    onEditorChanged(newValue) {
      this.deviceData.description = newValue
      this.validateItem('description', 'input')
    },

    selectImageFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.addEventListener('change', this.onImageSelected);
      input.click();
    },

    onImageSelected(event) {
      const file = event.target.files[0];

      if(file.size >= 1000000) {
        this.setErrorMessage('Image is too heavy. Max: 1MB')
      }

      else if (file && file.type.startsWith('image/')) {

        this.currentImg.file = file

        const reader = new FileReader();
        reader.onload = (e) => {

          this.currentImg = {
            ...this.currentImg,
            caption: '',
            source: '',
            license: '',
            editing: false,
            path: e.target.result
          }
          this.onPopupAction('editImagePopup', 'open')
        };
        reader.readAsDataURL(file);
      }
    },

    onImgAdded(imgData) {
      this.onPopupAction('editImagePopup', 'close')

      this.imgCollection.push({ ...imgData})

      this.validateItem('imgCollection', 'attachments')
    },

    onImgEdited(imgData) {
      this.onPopupAction('editImagePopup', 'close')

      const index = this.imgCollection.findIndex(img => img.id === imgData.id)
      this.imgCollection.splice(index, 1, imgData)

      if(!imgData.new) {
        this.updateAttachment({
          deviceId: this.$route.params.id,
          attachment: imgData
        })
      }
    },

    async onImgClose(imgId) {
      const index = this.imgCollection.findIndex(img => img.id === imgId);

      if(this.$route.params.id && this.imgCollection[index].new == false) {

        await this.deleteAttachment({
          deviceId: this.$route.params.id, 
          attachmentId: imgId
        })
      }
      

      if (index !== -1) {
        this.imgCollection.splice(index, 1);
      }

      this.validateItem('imgCollection', 'attachments')
    },

    onImageEdit(imgId) {
      this.currentImg = {
        ...this.imgCollection.find(img => img.id == imgId),
        editing: true
      }
      this.onPopupAction('editImagePopup', 'open')
    },

    selectPdfFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/pdf';
      input.addEventListener('change', this.onPdfSelected);
      input.click();
    },

    onPdfSelected(event) {
      const file = event.target.files[0];

      if(file.size >= 1000000) {
        this.setErrorMessage('Attachment is too heavy. Max: 1MB')
      }
      else if (file && file.type.startsWith('application/pdf')) {

        this.currentPdf.file = file;

        this.currentPdf = {
            ...this.currentPdf,
            name: '',
            description: '',
            editing: false,
            file: file
          }

        this.onPopupAction('editPdfPopup', 'open')
      }
    },
    onPdfAdded(pdfData) {
      this.onPopupAction('editPdfPopup', 'close')
      
      // this.currentPdf.id = Date.now()
      // this.currentPdf.new = true
      // Object.assign(this.currentPdf, pdfData);

      this.pdfCollection.push({ ...pdfData})

      // this.validateItem('pdfCollection', 'attachments')
    },

    onPdfEdit(pdfId) {
      this.currentPdf = {
        ...this.pdfCollection.find(pdf => pdf.id == pdfId),
        editing: true
      }
      this.onPopupAction('editPdfPopup', 'open')
    },

    onPdfEdited(pdfData) {
      this.onPopupAction('editPdfPopup', 'close')

      const index = this.pdfCollection.findIndex(pdf => pdf.id === pdfData.id)
      this.pdfCollection.splice(index, 1, pdfData)

      if(!pdfData.new) {
        this.updateAttachment({
          deviceId: this.$route.params.id,
          attachment: pdfData
        })
      }
    },

    async onPdfClose(pdfId) {
      const index = this.pdfCollection.findIndex(pdf => pdf.id === pdfId);

      if(this.$route.params.id && this.pdfCollection[index].new == false) {

        await this.deleteAttachment({
          deviceId: this.$route.params.id, 
          attachmentId: pdfId
        })
      }

      if (index !== -1) {
        this.pdfCollection.splice(index, 1);
      }
      // this.validateItem('pdfCollection', 'attachments')
    },
    checkChips() {

      if(this.$countriesFoundIn) {
        this.foundInValues = this.$countriesFoundIn.map(country => ({
          ...country,
          checked: this.deviceData.foundIn.includes(country.id)
        }));
      }

      // if(this.$devices) {
        // this.fuzingOptionsValues = this.$devices.map(device => ({
        //   ...device,
        //   checked: this.deviceData.fuzingOptions.includes(device.id)
        // }));

        // this.relatedDevicesValues = this.$devices.map(device => ({
        //   ...device,
        //   checked: this.deviceData.relatedDevices.includes(device.id)
        // }));
      // }

      // this.goimsDevicesValues = this.goimsDevicesValues.map(device => ({
      //   ...device,
      //   checked: this.deviceData.goimsDevices.includes(device.id)
      // }));
    },

    checkAddsChips() {
      if(this.$deviceAdds.hazards) {
        this.hazardsValues = this.$deviceAdds.hazards.map(hazard => ({
          ...hazard,
          checked: this.deviceData.hazards.includes(hazard.id)
        }));
      }

      // if(this.$sources) {
      //   this.sourcesValues = this.$sources.map(source => ({
      //     ...source,
      //     checked: this.deviceData.sources.includes(source.id)
      //   }));
      // }

      if(this.$deviceAdds.explosives) {
        this.explosiveTypesValues = this.$deviceAdds.explosives.map(type => ({
          ...type,
          checked: this.deviceData.explosiveTypes.includes(type.id)
        }));
      }
    },
    onDeviceDelete() {
      this.deleteDevice({
        deviceId: this.$route.params.id
      })
    }
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
    this.loading = true;
    if(this.$route.params.id) {
      await this.getDevice({ deviceId: this.$route.params.id })

      this.deviceData = cloneDeep(this.$device)


      this.imgCollection = this.$device.images
        .map(img => {
           return {
            ...img, 
            new: false, 
            path: `${process.env.VUE_APP_BASE_IMG_URL}${img.path}`,
            source: img.source.id,
            license: img.license.id,
          } 
        })
      this.pdfCollection = this.$device.attachments
        .map(pdf => {
          return {
            ...pdf, 
            new: false,
            name: pdf.title,
            description: pdf.description
          } 
        })
    } 
    
    this.getSourceCollection()
    await this.getDeviceAdds()
    this.checkAddsChips()
    this.loading = false;
    await this.getCountryOfOriginCollection()
    await this.getCountryFoundInCollection()
    await this.getDeviceCollection()
    this.checkChips()
  }
}
</script>

<style lang="scss" scoped>
label {
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 16px;

}
.device-edit {
  &__header {
    display: flex;
    justify-content: space-between;

    padding: 30px 0 20px 0;

    border-bottom: 1px solid #D2D3D3;

    span {
      font-weight: 700;
      color: #0A3C6180;
    }

    strong {
      color: #0B3C61;
    }
  }

  &__back-btn {
    padding: 10px 8px 8px 30px;
    margin-bottom: 8px;
    font-size: 12px;
    background: #ffffff00 url("@/assets/img/icons/arrow-back.svg");
    background-position: 8px 12px;
    background-repeat: no-repeat;
    border: none;
  }

  &__card {
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  &__general {
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-right: 100px;

  }

  &__chips {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__images {
    h3 {
      margin-bottom: 21px;
    }
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFEF;

    div {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }
  }

  &__attachments {
    h3 {
      margin:16px 0 21px 0;
    }
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFEF;

    div {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }
  }
  
  &__description {
    h3 {
      margin:16px 0 10px 0;
    }
  }

  &__editor {
    height: 250px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 60px;
  }

  &__details-wrapper {
    padding: 0 20px;
  }

  &__required {
    text-align: center;
    align-self: center;
    color: rgb(201, 11, 11);
  }
}

@media (max-width: 865px) {
  .device-edit__wrapper {
    display: block;
  }

  .device-edit__header {
    flex-direction: column;
    gap: 15px;
  }

  .device-edit__general {
    width: 100%;
  }

  .device-edit__images {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #EFEFEF;
  }

  .device-edit__details-wrapper{
    padding: 0;
  }
}
</style>