<template>
  <div class="wrapper">
    <NavBar @onHamburger="onSidebarOpen" />
    <SideBar 
      :isOpened="sidebarActive" 
      @onSidebarClose="onSidebarClose"
    />
    <div class="content-wrapper paper-bcg-light">
      <router-view />
    </div>
  </div>
</template>

<script>
//js
import '@/assets/js/adminlte/jquery/jquery.min.js'
import '@/assets/js/adminlte/bootstrap/bootstrap.bundle.min.js'
import '@/assets/js/adminlte/jquery-ui/jquery-ui.min.js'
import '@/assets/js/adminlte/adminlte.min.js'


import NavBar from "@/components/AdminLte/NavBar";
import SideBar from "@/components/AdminLte/SideBar";

export default {
  name: 'DefaultApp',
  components: {
    SideBar,
    NavBar,
  },
  data() {
    return {
      sidebarActive: window.innerWidth < 990 ? false : true
    }
  },  
  unmounted() {
    window.removeEventListener("resize", this.calculateSidebarActive);
  },
  mounted() {
    window.addEventListener("resize", this.calculateSidebarActive);
  },
  methods: {
    onSidebarOpen() {
      this.sidebarActive = true
    },
    onSidebarClose() {
      this.sidebarActive = false
    },
    calculateSidebarActive() {
      if (window.innerWidth < 990) {
        this.sidebarActive = false
      } else {
        this.sidebarActive = true
      }
    }
  },
}
</script>


<style scoped>
</style>
