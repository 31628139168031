import { Axios } from "./config.js";

const basePath = 'user'

export default class UserService {

  async getCollection({params} = {}) {
    return await Axios.get(`${basePath}`, {
      params
    });
  }

  async getUser(userId) {
    return await Axios.get(`${basePath}/${userId}`);
  }

  async create(body) {
    return await Axios.post(`${basePath}`, body);
  }

  async update({ userId, body } = {}) {
    return await Axios.put(`${basePath}/${userId}`, body);
  }

  async delete(userId) {
    return await Axios.delete(`${basePath}/${userId}`);
  }

  async importUsers({ file }) {
    return await Axios.post(`${basePath}/import`, file );
  }

  async restore(userId) {
    return await Axios.patch(`${basePath}/${userId}/restore`);
  }

}