<template>
  <section class="user-edit">
    <PopupWrapper ref="areYouSurePopup">
      <AreYouSurePopup
        @onAccept="onAccept"
        @onPopupClose="() => onPopupAction('areYouSurePopup', 'close')" 
      />
    </PopupWrapper>

    <div class="my-container">
      <div class="user-edit__header">
        <span>{{ $t('newUserAdding') }}</span> 
      </div>
      <button 
        class="user-edit__back-btn" 
        @click="$router.push({
          name: 'Users'
        })"
      >
        {{ $t('back') }} 
      </button>
      <form class="user-edit__card">
        <Spinner :loading="loading" />
        <div v-show="!loading" class="user-edit__wrapper">
          <div class="user-edit__general">
            <h3 class="user-edit__general-title"> 
              {{ $t('generalInformation') }}  
            </h3>
           
            <label>
              {{ $t('firstName') }} 
            </label>
            <MyInput 
              v-model="userData.firstName"
              :class="[validate.firstName ? null : 'border-danger']"
              autocomplete="off"
              @input="validateItem('firstName', 'input')"
            />
            <label>
              {{ $t('lastName') }} 
            </label>
            <MyInput 
              v-model="userData.lastName"
              :class="[validate.lastName ? null : 'border-danger']"
              autocomplete="off"
              @input="validateItem('lastName', 'input')"
            />
            <label>
              {{ $t('email') }} 
            </label>
            <MyInput 
              v-model="userData.email"
              :class="[validate.email ? null : 'border-danger']"
              autocomplete="new-email"
              @input="validateItem('email', 'input')"
            />
            <label>
              {{ $t('Office 365') }} 
            </label>
            <MyCheckbox 
              v-if="checkboxActive"
              :title="'Has Office365 account'"
              :value="userData.isOffice365"
              class="mb-0 mt-0"
              @checked="isOffice365Checked"
            />
            <div v-show="!userData.isOffice365">
              <label>
                {{ $t('password') }} 
              </label>
              <MyPassInput 
                v-model="userData.password"
                :validationClass="[validate.password ? null : 'border-danger']"
                autocomplete="new-password"
                @input="validateItem('password', 'input')"
              />
              <label>
                {{ $t('repeatPassword') }} 
              </label>
              <MyPassInput 
                v-model="userData.repeatPassword"
                :validationClass="[validate.repeatPassword ? null : 'border-danger']"
                autocomplete="new-password"
                @input="validateItem('repeatPassword', 'input')"
              />
            </div>
            <label>
              {{ $t('role') }} 
            </label>
            <MySelect 
              v-model="userData.role"
              :options="roleValues" 
              :title="$t('select')" 
              :class="[validate.role ? null : 'border-danger']"
              @change="validateItem('role', 'input')"
            />
          </div>
        </div>
        <div class="user-edit__footer">
          <router-link 
            :to="{name: 'Users'}" 
          >
            <MyButton 
              :title="$t('cancel')"  
              :type="'o'"
            />
          </router-link>
          <MyButton 
            v-show="$route.params.id && !$user.deleted && !loading"
            :title="$t('deleteTheUser')"
            :style="{'background-color': '#fd8e14'}"
            @click.prevent="onPopupAction('areYouSurePopup', 'open')" 
          />
          <MyButton 
            v-show="$user.deleted && !loading"
            :title="$t('restoreUser')"
            :style="{'background-color': '#fd8e14'}"
            @click.prevent="onPopupAction('areYouSurePopup', 'open')" 
          />
          <MyButton 
            v-show="!loading"
            :title="$t('save')" 
            @click.prevent="onUserSave" 
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash";

import { 
  MyInput, 
  MySelect, 
  MyButton,
  MyPassInput,
  PopupWrapper,
  AreYouSurePopup,
  Spinner,
  MyCheckbox
} from "@/components-ui"

export default {
  name: "UserEdit",
  components: {
    MyInput,
    MySelect,
    MyButton,
    MyPassInput,
    PopupWrapper,
    AreYouSurePopup,
    Spinner,
    MyCheckbox
  },
  setup () {
    return {}
  },
  data() {
    return {
      loading: false,
      step: 1,
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        repeatPassword: '',
        role: '',
        isOffice365: false
      },
      
      validate: {
        firstName: true,
        lastName: true,
        email: true,
        password: true,
        repeatPassword: true,
        role: true,
      },

      roleValues: [
        {
          id: 'admin',
          name: this.$t('admin') 
        },
        {
          id: 'user',
          name: this.$t('fieldStaff')
        },
      ],
      checkboxActive: false,
      
    }
  },
  computed: {
    ...mapState("user", {
      $user: "user",
      $users: "users"
    }),
  },
  methods: {
    ...mapActions("user", ["createUser", "getUser", "updateUser", "deleteUser", "restoreUser"]),

    validateAll() {
      this.validate.firstName = !!this.userData.firstName;
      this.validate.lastName = !!this.userData.lastName;
      this.validate.email = !!this.userData.email;
      
      this.validate.role = !!this.userData.role;
      this.validate.repeatPassword = this.userData?.repeatPassword === this.userData.password;

      this.validate.password = this.$route.params.id
        ? this.userData.password.length === 0 || this.userData.password.length > 5
        : this.validate.password = this.userData.password.length > 5 || this.userData.isOffice365
    },

    validateItem(name, type) {

        if(type === 'input') {
          this.validate[name] = !!this.userData[name];

          if(name === 'password') {
            this.validate.password = this.$route.params.id
              ? this.userData.password.length === 0 || this.userData.password.length > 5
              : this.validate.password = this.userData.password.length > 5
          }

          if(name === 'repeatPassword') {
            this.validate.repeatPassword = this.userData?.repeatPassword === this.userData.password;
          } 

        }
    },
    
    async onUserSave() {
      this.validateAll()

      const allValuesAreTrue = Object.values(this.validate).every(value => value === true);

      if(allValuesAreTrue) {
        if(this.$route.params.id) {
          await this.updateUser( {
            userId: this.$route.params.id,
            userData: this.userData, 
          })
        } else {
          await this.createUser( {
            userData: this.userData, 
          })
        }
      }
    },

    // onUserDelete() {
    //   this.deleteUser({
    //     userId: this.$route.params.id
    //   })
    // },

    onPopupAction(refName, Action) {
      if (Action === "open") {
        this.$refs[refName].onPopupOpen()
      } else {
        this.$refs[refName].onPopupClose()
      }
    },  

    onAccept() {
      if (this.$user.deleted) {
        this.restoreUser({
          userId: this.$route.params.id
        })
      } else {
        this.deleteUser({
        userId: this.$route.params.id
      })
      }
    },
    isOffice365Checked(newValue) {
      this.userData.isOffice365 = newValue
    },
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
    this.loading = true;
    if(this.$route.params.id) {
      await this.getUser({ userId: this.$route.params.id })

      this.userData = {
        ...this.$user,
        password: '',
        repeatPassword: '' 
      }
    }
    this.checkboxActive = true
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
label {
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 16px;

}
.user-edit {
  &__header {
    display: flex;
    justify-content: space-between;

    padding: 30px 0 20px 0;

    border-bottom: 1px solid #D2D3D3;

    span {
      font-weight: 700;
      color: #0A3C6180;
    }

    strong {
      color: #0B3C61;
    }
  }

  &__back-btn {
    padding: 10px 8px 8px 30px;
    margin-bottom: 8px;
    font-size: 12px;
    background: #ffffff00 url("@/assets/img/icons/arrow-back.svg");
    background-position: 8px 12px;
    background-repeat: no-repeat;
    border: none;
  }

  &__card {
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  &__general {
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-right: 100px;

  }

  &__chips {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__images {
    h3 {
      margin-bottom: 21px;
    }
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFEF;

    div {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }
  }

  &__attachments {
    h3 {
      margin:16px 0 21px 0;
    }
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFEF;

    div {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }
  }
  
  &__description {
    h3 {
      margin:16px 0 10px 0;
    }
  }

  &__editor {
    height: 250px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 60px;
  }

  &__details-wrapper {
    gap: 50px;    
  }

  &__required {
    text-align: center;
    align-self: center;
    color: rgb(201, 11, 11);
  }
}

@media (max-width: 500px) {
  .user-edit__wrapper {
    display: block;
  }
  .user-edit__general {
    width: 100%;
  }

  .user-edit__footer {
    flex-direction: column; 
    a {
      button {
        width: 100%;
      }
    }
  }
}
</style>