<template>
  <div class="add-fusion">
    <div class="add-fusion__header">
      <h4>{{ $t(title) }}</h4>
      <div class="add-fusion__search">
        <img
          class="add-fusion__search-img"
          :src="searchIcon"
          alt="search icon"
        >
        <input 
          v-model="searchFilter"
          type="text" 
          class="add-fusion__input" 
          :placeholder="$t('search')" 
          @input="onInput"
        >
        <img
          v-show="loading"
          class="add-fusion__search-spinner"
          :src="spinner"
          alt="loading spinner"
        >
      </div>
    </div>
    <div class="add-fusion__checkboxes">
      <MyCheckbox 
        v-for="item in filteredItems" 
        :key="item.id"
        :title="item.name"
        :value="item.checked || false"
        @checked="onChecked(item.id, item.name, $event)"
      />
      <h4 
        v-show="filteredItems.length === 0" 
        class="add-fusion__empty"
      >
        {{ $t('noDevices') }}
      </h4>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MyCheckbox } from "@/components-ui";
import debounce from '@/helpers/debounce'

import searchIcon from "@/assets/img/icons/search-light.svg";
import spinner from "@/assets/img/spinner.svg";



  export default {
    name: 'CheckboxesSearchPopup',
    components: {
      MyCheckbox,
    },
    props: {
      title: {
        type: String,
        default: 'Add fusion'
      },
      items: {
        type: Array,
        default: () => []
      },
    },
    emits: ['onValueChanged'], 
    setup() {
      return {
        searchIcon,
        spinner
      }
    },
    data() {
      return {
        searchFilter: "",
        loading: false
      }
    },
    computed: {
      filteredItems() {
        if(this.withDevices) return this.items
        
        return this.items.filter(item => {
          return item.name.toLowerCase().includes(this.searchFilter.toLowerCase()) 
        })
      },
      withDevices() {
        return this.title === 'fuzingOption' || this.title === 'relatedDevices'
      }
    },
    methods: {
    ...mapActions("device", ["getDeviceCollection"]),

      onChecked(id, deviceTitle, newValue) {
        this.$emit('onValueChanged', id, newValue, deviceTitle)
      },

       devicesQuerySearch: debounce(async function () {
        this.loading = true
        await this.getDeviceCollection({
          name: this.searchFilter ? this.searchFilter : null
        });
        this.loading = false
      }, 1000),

      onInput() {
        if(this.withDevices) {
          this.devicesQuerySearch()
        }
      }
    },

    // eslint-disable-next-line vue/order-in-components
    async mounted() {
      if(this.withDevices && this.filteredItems.length === 0) {
        this.loading = true
        await this.getDeviceCollection()
        this.loading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.add-fusion {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 495px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  

  &__header {
    padding: 24px 30px;
    border-bottom: 1px solid #D2D3D3;
  }

  h4 {
    /* padding: 24px 30px 0px 30px; */
    margin-bottom: 26px;
    color: black;
  }

  &__search {
    position: relative;
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #D2D3D3;

    &-img {
      width: 24px;
      height: 24px;
    }
    &-spinner{
      position: absolute;
      top: -8px;
      right: 0;
      height: 35px;
      width: 35px;
      background-color: transparent;
    }
  }

  &__input {
    width: 100%;
    font-size: 14px;
    border: none;

    &::placeholder {
      color: #D2D3D3;
    }
  }
  
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px 24px 30px;
    overflow: auto;
    
    label {
      font-size: 16px;
    }
  }

  &__empty{
    text-align: center;
    margin-top: 120px;
  }
}
</style>