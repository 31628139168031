<template>
  <div class="device-history">
    <div class="my-container">
      <div class="device-history__header">
        <span>{{ $t('devices') }} -> <strong>{{ $t('appliedChangesHistory') }}</strong></span> 
      </div>
      <router-link 
        :to="{name: 'Device', id: $route.params.id}" 
      >
        <button class="device__back-btn">
          {{ $t('back') }} 
        </button>
      </router-link>
      <div class="device-history__loading-card">
        <Spinner :loading="loading" />
      </div>
      <section class="device-history__card">
        <div 
          v-for="historyItem in transformedHistoryValues" 
          :key="historyItem.created_at"
          class="history-item" 
        >
          <div class="history-item__header">
            <span class="history-item__date">{{ historyItem.created_at }}</span>
            <span class="history-item__info">{{ $t('deviceChangedBy') }} {{ historyItem.user.email }}</span> 
          </div>
          <div class="history-item__body">
            <div 
              v-for="change in historyItem.changes" 
              v-show="!(hasTypeImage(historyItem) || hasTypeAttachment(historyItem))"
              :key="change"
            >
              <strong>
                {{ change.column }}
              </strong> 
              {{ $t('changedFrom') }}
              <strong v-show="!(change.column === 'Description')">
                {{ change.old_value || 'blank' }}
              </strong> 
              <div 
                v-show="change.column === 'Description'" 
                class="mt-3" 
                v-html="change.old_value" 
              />
              {{ $t('to') }}
              <strong v-show="!(change.column === 'Description')">
                {{ change.new_value || 'blank' }}
              </strong>
              <div 
                v-show="change.column === 'Description'" 
                class="mt-3" 
                v-html="change.new_value" 
              />
            </div>

            <div v-if="hasTypeImage(historyItem) && !attachmentDeleted(historyItem)"> 
              <strong>{{ $t('imageWasAdded') }}</strong> 
            </div>
            <div v-if="hasTypeImage(historyItem) && attachmentDeleted(historyItem)"> 
              <strong>{{ $t('imageWasDeleted') }}</strong> 
            </div>
            <img 
              v-if="hasTypeImage(historyItem)"
              class="history-item__img"
              :src="imagePathByHistoryItem(historyItem)" 
              alt="device image"
            >

            <div v-if="hasTypeAttachment(historyItem) && !attachmentDeleted(historyItem)"> 
              <strong>{{ $t('attachmentWasAdded') }}</strong> 
            </div>
            <div v-if="hasTypeAttachment(historyItem) && attachmentDeleted(historyItem)"> 
              <strong>{{ $t('attachmentWasDeleted') }}</strong> 
            </div>
            <img 
              v-if="hasTypeAttachment(historyItem)"
              class="history-item__img"
              :src="pdfImage" 
              alt="device image"
            >
            <div v-if="hasTypeAttachment(historyItem)">
              <strong>{{ attachmentNameByHistoryItem(historyItem) }}</strong> 
            </div>
          </div>
        </div>
        <div 
          v-show="transformedHistoryValues.length <= 0 && !loading" 
          class="history__nothing"
        >
          <h3>
            {{ $t('noDeviceHistory') }}
          </h3>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import device2Img from "@/assets/img/device2.jpeg";
import device3Img from "@/assets/img/device3.jpeg";
import device4Img from "@/assets/img/device4.jpeg";
import pdfImage from "@/assets/img/pdfBackground.jpg";

import { Spinner } from "@/components-ui"

export default {
  name: "DeviceHistoryView",
  setup () {
    return {
      device2Img,
      device3Img,
      device4Img,
      pdfImage
    }
  },
  data() {
    return {
      loading: false,
      transformedHistoryValues: [],
    }
  },
  components: {
    Spinner
  },
  computed: {
    ...mapState("device", {
      $devices: "devices",
      $history: "history"
    }),
    ...mapState("country", {
      $countries: "items",
      $countriesOfOrigin: "countriesOfOrigin",
      $countriesFoundIn: "countriesFoundIn",
    }),
    ...mapState("loader", {
      $historyLoading: "historyLoading"
    }),
    baseUrl() {
      return process.env.VUE_APP_BASE_IMG_URL || '';
    }
  },
  methods: {
    ...mapActions("device", ["getDeviceHistory", "getDeviceCollection"]),
    ...mapActions("country", ["getCountryCollection"]),

    transformHistoryValues() {

      const keyMappings = { 
        'role_id': this.$t('role'),
        'name': this.$t('name'),
        'group_id': this.$t('group'),
        'sub_group_id': this.$t('subGroup'),
        'hazard_ids': this.$t('hazards'),
        'source_ids': this.$t('sources'),
        'description': this.$t('description'),
        'explosive_ids': this.$t('explosiveType'),
        'auw': this.$t('auw'),
        'neq': this.$t('neq'),
        'length': this.$t('length'),
        'width': this.$t('width'),
        'diameter': this.$t('diameter'),
        'complete_round_weight': this.$t('completeRoundWeight'),
        'propellant_weight': this.$t('propellantWeight'),
        'country_origin_id': this.$t('CountryOrigin'),
        'goims_device_ids': this.$t('goimsDevices'),
        'icrc_3D_url': this.$t('model3d'),
        'related_eo_event': this.$t('relatedEoEvent'),
        'countries_found_in': this.$t('foundIn'),
        'related_devices': this.$t('relatedDevices'),
        'fuzing_options': this.$t('fuzingOptions'),
      };

      const arrayKeys = ['hazard_ids', 'source_ids', 'explosive_type_int', 'goims_device_ids', 'related_devices', 'fuzing_options', 'explosive_ids']

      this.transformedHistoryValues = this.$history.map((history) => {
        const newChanges = history.changes.map((change) => {
          let newColumn = change.column

          let newValue = arrayKeys.includes(change.column)
            ? JSON.parse(change.new_value).join(', ') 
            : change.new_value;

          let oldValue = arrayKeys.includes(change.column) && change.old_value !== null
            ? JSON.parse(change.old_value).join(', ') 
            : change.old_value;

          if(newColumn === 'countries_found_in') {
            newValue = JSON.parse(change.new_value).map(itemId => {
              return this.$countriesFoundIn.find(countryItem => countryItem.id === Number(itemId)).name;
            }).join(', ') 

            oldValue = JSON.parse(change.old_value).map(itemId => {
              return this.$countriesFoundIn.find(countryItem => countryItem.id === Number(itemId)).name;
            }).join(', ') 
          } else

          if (newColumn === 'country_origin_id') {
            newValue = this.$countriesOfOrigin.find(countryItem => countryItem.id === Number(change.new_value)).name;
            oldValue = this.$countriesOfOrigin.find(countryItem => countryItem.id === Number(change.old_value)).name;
          } else 

          if (newColumn === 'related_devices' || change.column === 'fuzing_options') {
            newValue = JSON.parse(change.new_value).map(itemId => {
              return this.$devices.find(countryItem => countryItem.id === Number(itemId)).name;
            }).join(', ') 

            oldValue = JSON.parse(change.old_value).map(itemId => {
              return this.$devices.find(countryItem => countryItem.id === Number(itemId)).name;
            }).join(', ') 
          } 
          
          newColumn = keyMappings[change.column] || change.column;
          
          return { ...change, column: newColumn, new_value: newValue, old_value: oldValue };
        });

        return {...history, changes: newChanges};
      }).slice().reverse();
    },
    
    hasTypeImage(historyItem) {
      return historyItem.changes.some(
        (item) => item.column === 'type' && item.new_value === 'image'
      );
    },

    hasTypeAttachment(historyItem) {
      return historyItem.changes.some(
        (item) => item.column === 'type' && item.new_value === 'attachment'
      );
    },

    attachmentDeleted(historyItem) {
      return historyItem.changes.some(
        (item) => item.column === 'deleted' && item.new_value === '1'
      );
    },

    imagePathByHistoryItem(historyItem) {
      const path = historyItem.changes.filter(
        (item) => item.column === 'path' 
      )[0];

      return `${this.baseUrl}${path.new_value}`
    }, 

    attachmentNameByHistoryItem(historyItem) {
      const title = historyItem.changes.filter(
        (item) => item.column === 'title'
      )[0].new_value

      return title
    }
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
    this.loading = true;
    await this.getDeviceHistory({ deviceId: this.$route.params.id });
    await this.getCountryCollection();
    await this.getDeviceCollection();

    this.transformHistoryValues();
    this.loading = false;
  }
}
</script>

<style lang="scss">

.device-history {
  &__header {
    display: flex;
    justify-content: space-between;

    padding: 30px 0 20px 0;

    border-bottom: 1px solid #D2D3D3;

    span {
      font-weight: 700;
      color: #0A3C6180;
    }

    strong {
      color: #0B3C61;
    }
  }

  &__back-btn {
    padding: 10px 8px 8px 30px;
    margin-bottom: 8px;
    font-size: 12px;
    background: #ffffff00 url("@/assets/img/icons/arrow-back.svg");
    background-position: 8px 12px;
    background-repeat: no-repeat;
    border: none;
  }

  &__card {
    margin-bottom: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  &__loading-card {
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
}

.history-item {
  padding: 20px 24px 24px 30px;
  border-bottom: 1px solid #D2D3D3;

  &:last-child {
    border-bottom: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__date {
    font-size: 18px;
    font-weight: 700;
    color: #363939;
  }

  &__info {
    font-size: 16px;
    text-decoration-line: underline;
    color: #363939;
  }

  &__images {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__img {
    height: 200px;
    max-width: 250px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  }

  &__body {
    font-size: 16px;
    color: #363939;
    font-style: italic;
  }
}

.history__nothing {
  padding: 20px 30px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  h3 {
    text-align: center;
    margin-bottom: 0;
  }
}

@media (max-width: 700px) {
  .history-item__header {
    flex-direction: column;
  }
}
</style>