<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light ">
    <div class="navbar-nav d-flex justify-content-between">
      <HamburgerButton @click="$emit('onHamburger')" />
      <SearchInput 
        v-model="filterValue"
        @input="onInput"
        @filters-icon-click="filtersCardActive ? filtersCardActive = false : filtersCardActive = true"
      />
      <FiltersCard 
        v-if="filtersCardActive"
        class="navbar__filter-card"
        v-click-outside="closeFiltersCard"
        @close="filtersCardActive = false"
      /> 

      <div class="navbar-nav__wrapper">
        <div 
          class="navbar-user navbar-link" 
          role="button"
        >
          <img
            class="navbar-user__img"
            :src="userIcon"
            alt="user icon"
          >
          <span class="navbar-user__text">{{ user }}</span>
        </div>
        <router-link 
          v-show="isFiledStaff"
          class="navbar-calc"
          :to="{name: 'Calculator'}" 
        >
          <img
            class="navbar-calc__img"
            :src="calcIcon"
            alt="calc icon"
          >
        </router-link>
        <div 
          class="navbar-lang navbar-link" 
          @click="langCardActive = !langCardActive"
        >
          <img
            class="navbar-lang__img"
            :src="langIcon"
            alt="user icon"
          >
          <div class="navbar-lang__text">
            <!-- EN -->
            {{ $i18n.locale.toLocaleUpperCase() }}
          </div>
          <langSwitchCard 
            v-if="langCardActive"
            v-click-outside="closeLangCard"
            :translates="activeLocales"
            class="navbar-lang__card" 
            @onLangClick="(lang) => changeLang(lang)"
          />
        </div>
        <!-- <div 
          class="navbar-notif navbar-link" 
          @click="notifCardActive = !notifCardActive "
        >
          <img
            class="navbar-notif__img"
            :src="notificationIcon"
            alt="notif icon"
          >
        </div> -->
        <NotificationCard 
          v-if="notifCardActive"
          class="navbar-notif__card "
          v-click-outside="closeNotifCard"
        />
      </div>  
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { SearchInput, langSwitchCard, NotificationCard, FiltersCard, HamburgerButton } from "@/components-ui";
import ls from "local-storage";

import debounce from '@/helpers/debounce'

import userIcon from "@/assets/img/icons/user.svg";
import langIcon from "@/assets/img/icons/lang.svg";
import calcIcon from "@/assets/img/icons/calculator.svg";
import notificationIcon from "@/assets/img/icons/notification.svg";

// import { setI18nLanguage } from "@/plugins/i18n"

// function debounce(func, delay) {
//   let timerId;
//   return function (...args) {
//     clearTimeout(timerId);
//     timerId = setTimeout(() => {
//       func.apply(this, args);
//     }, delay);
//   };
// }

export default {
  name: "NavBar",
  components: {
    SearchInput,
    langSwitchCard,
    NotificationCard,
    FiltersCard,
    HamburgerButton
  },
  emits: ['onHamburger'],
  setup() {
    return {
      userIcon,
      langIcon,
      notificationIcon,
      calcIcon
    };
  },
  data() {
    return {
      // user: localStorage.getItem('HALO_USER_EMAIL'),
      langCardActive: false,
      notifCardActive: false,
      filtersCardActive: false,
    }
  },
  computed: {
    ...mapState("navbarFilter", {
      $filters: "filters",
      $filterValue: "filterValue"
    }),
    ...mapState("translate", {
      $translates: "translates"
    }),

    user() {
      return ls.get('HALO_USER_EMAIL')
    },
    filterValue: {
      get() {
        return this.$filterValue;
      },
      set(value) {
        this.updateFilterValue(value)
      },
    },
    isFiledStaff() {
      return ls.get("HALO_USER_ROLE") === 'user'
    },
    activeLocales() {
      return this.$translates.filter(locale => locale.active)
    }
  },
  methods: {
    ...mapActions("navbarFilter", ["updateFilterValue"]),
    ...mapActions("translate", ["getLocaleCollection", "setLanguage"]),
    ...mapActions("country", ["getCountryOfOriginCollection", "getCountryFoundInCollection"]),
    ...mapActions("device", ["getDeviceCollection"]),

    closeLangCard() {
      if (this.langCardActive) {
        this.langCardActive = !this.langCardActive;
      }
    },
    closeNotifCard(){
      if (this.notifCardActive) {
        this.notifCardActive = !this.notifCardActive;
      }
    },
    closeFiltersCard() {
      if (this.filtersCardActive) {
        this.filtersCardActive = !this.filtersCardActive;
      }
    },

    getDevicesDelayed: debounce(function() {
      this.getDeviceCollection({
        filters: {
          group_ids: this.$filters.group,
          sub_group_ids: this.$filters.subGroup,
          role_ids: this.$filters.role,
          hazard_ids: this.$filters.hazards,
          country_origin_ids: this.$filters.countryOfOrigin,
          country_found_in_ids: this.$filters.foundIn,
          needs_qc: this.$filters.needQC ? 1 : null,
          missing_data: this.$filters.missingData ? 1 : null,
          needs_additional_photos: this.$filters.needsAdditionalPhotos ? 1 : null,
          error_reported: this.$filters.errorReported ? 1 : null,
          needs_cross_section: this.$filters.needsCrossSection ? 1 : null,
        },
        name: this.filterValue ? this.filterValue : null
      });
    }, 1000),

    onInput() {
      this.updateFilterValue(this.filterValue)
      this.getDevicesDelayed()
    },

    changeLang(lang) {
      this.setLanguage(lang.short)
    }
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    this.getLocaleCollection()
    this.getCountryOfOriginCollection()
    this.getCountryFoundInCollection()
  }
  
}
</script>

<style lang="scss" scoped>
.main-header {
  transition: margin-left .3s ease-in-out !important;
}

.navbar {
  padding: 29px 26px 20px 88px;
  height: 72px;

  &-nav {
    width: 100%;

    &__wrapper {
      display: flex;
    }
  }
  

  &-link {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0;
    
  }

  &-user {
    padding-right: 20px;
    display: flex;
    align-items: center;
    /* border-right: 1px solid white; */
    &__img {
      filter: invert(1);
      width: 16px;
      margin-right: 9px;
    }

    &__text {
      font-size: 20px;
      color: white;
    }
  }

  &__filter-card {
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translateX(-50%)
  }

  &-calc {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    border-left: 1px solid white;

    cursor: pointer;

  }

  &-lang {
    position: relative;
    padding: 0 16px 0 22px;
    /* border-right: 1px solid white; */
    border-left: 1px solid white;
    align-items: center;
    cursor: pointer;
    &__img {
      width: 20px;
      filter: invert(1);
      margin-right: 7px;
    }
    &__text {
      position: relative;
      top: 1px;
      display: flex;

      font-weight: 700;
      font-size: 12px;
      color: white;
    }
    &__card {
      display: block;
      position: absolute;
      top: 47px;
      right: 0px;
      z-index: 999;

      &_active {
        display: block;
      }
    }
  }

  &-notif {
    position: relative;
    margin-left: 24px;
    cursor: pointer;
    &__img {
      width: 20px;
      filter: invert(1);

      
    }
    &::before {
      content: '';
      position: absolute;
      top: -3px;
      right: -2px;
      z-index: 9;
      height: 10px;
      width: 10px;
      background-color: #E87D1E;
      border-radius: 10px;
    }

    &__card {
      display: flex;
      position: absolute;
      top: 72px;
      right: 0;

    }
  }
}

@media (max-width: 990px) {
  .navbar {
    padding: 26px 26px 26px 26px;
    height: 72px;
  }

  .hamburger {
    display: block;
  }
}

@media (max-width: 750px) {
  .search-input {
    display: none;
  }
}

@media (max-width: 455px) {
  .navbar {

    &-nav {

      &__wrapper {
        display: none;
      }
    }
  }
}



</style>