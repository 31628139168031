<template>
  <div class="device-small">
    <img 
      class="device-small__img" 
      :src="imgPath ? `${baseUrl}${imgPath}` : noImg" 
      alt="img path"
    >
    <div class="device-small__title">
      <span>{{ title.length > 8 ? title.slice(0, 8) + '...': title }}</span>
    </div>
  </div>
</template>

<!-- src="http://halo.local/storage/image/8bcbdc64-76db-459a-bed5-e68f839910b6.png"  -->

<script>
import noImg from "@/assets/img/no-img.jpg";

  export default {
    props: {
      imgPath: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
    },
    setup() {
      return {
        noImg
      }
    },
    computed: {
      baseUrl() {
        return process.env.VUE_APP_BASE_IMG_URL || '';
      }
    },
  }
</script>

<style lang="scss" scoped>
  .device-small {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 90px;
    border-radius: 8px;
    border: 2px solid #2D3648;
    overflow: hidden;

    &__img {
      width: 78px;
      height: 60px;
      object-fit: cover;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      text-align: center;
      color: black;
      border-top: 2px solid #2D3648;
    }
  }
</style>