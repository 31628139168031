import Device from "./Device";
import Auth from "./Auth";
import Country from "./Country";
import User from "./User";
import Translate from "./Translate";
import Source from "./Source";

const device = new Device()
const auth = new Auth()
const country = new Country()
const user = new User()
const translate = new Translate()
const source = new Source()

export {
  device,
  auth,
  country,
  user,
  translate,
  source
}