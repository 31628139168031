<template>
  <select 
    class="form-select my-select"
    :value="modelValue"
    @change="updateInput"
    :disabled="disabled"
  >
    <option value="" selected disabled>
      {{ title }}
    </option>

    <option
      v-for="option in options"
      :key="option.id"
      :value="option.id"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: "MySelect",
  props: {
    modelValue: String,
    options: Array,
    title: String,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  setup () {
  
    return {}
  },
  methods: {
    updateInput(event) {
      this.$emit('update:model-value', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>

.my-select {
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  color: #363939;
  border: 2px solid #D2D3D3;
}
</style>