import { Axios } from "./config.js";

export default class AuthService {

  async logInGetToken(data) {
    return await Axios.post("/auth/login", data)
  }

  static refreshToken(refreshToken) {
    return Axios.post("/auth/refresh-token", { refresh_token: refreshToken })
  }

}