<template>
  <div class="popup-image">
    <button 
      v-show="isImgShown"
      class="popup-image__close" 
      @click="$emit('onPopupClose')"
    >
      <img 
        :src="closeIcon"
        alt="close icon"
      >
    </button>
    <img 
      v-show="isImgShown"
      :src="currentImgData.path" 
      alt="new image" 
      class="popup-image__img"
    >
    <div class="popup-image__wrapper">
      <div class="popup-image__input">
        <label>
          <span>{{ $t('caption') }}</span> 
          <MyInput 
            v-model="imgData.caption" 
            :class="[validate.caption ? null : 'border-danger']"
            @input="validateItem('caption')"
          />
        </label>
      </div>

      <div class="popup-image__input">
        <label>
          <span>{{ $t('source') }}</span> 
          <MySelect 
            v-model="imgData.source" 
            :options="sourceValues"
            :title="$t('select')"
            :class="[validate.source ? null : 'border-danger']"
            @change="validateItem('source')"
          />
        </label>
      </div>

      <div class="popup-image__input">
        <label>
          <span>{{ $t('license') }}</span> 
          <MySelect 
            v-model="imgData.license" 
            :options="licenseValues" 
            :title="$t('select')"
            :class="[validate.license ? null : 'border-danger']"
            @change="validateItem('license')"
          />
        </label>
      </div>

      <div class="popup-image__footer">
        <MyButton 
          :title="currentImgData.editing ? $t('edit') : $t('add') " 
          @click="onImgSave"
        />
        <MyButton 
          :title="$t('cancel')"
          type="o"
          @click="$emit('onPopupClose')" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MyInput, MySelect, MyButton } from '@/components-ui'

import device2Img from "@/assets/img/device2.jpeg";
import closeIcon from "@/assets/img/icons/close.svg";

export default {
  components: {
    MyInput,
    MySelect,
    MyButton
  },
  props: {
    sourceValues: {
      type: Array,
      default: () => []
    },
    licenseValues: {
      type: Array,
      default: () => []
    },
    currentImgData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['onPopupClose', 'onImgAdded', 'onImgEdited'],
  setup () {
    return {
      device2Img,
      closeIcon
    }
  },
  data() {
    return {
      imgData: {
        caption: '',
        source: '',
        license: ''
      },
      validate: {
        caption: true,
        source: true,
        license: true,
      },
      isImgShown: true
    }
  },
  methods: {
    onImgSave() {
      const imgData = {...this.currentImgData, ...this.imgData}

      if(this.validateAll()) {
        this.currentImgData.editing 
          ? this.$emit('onImgEdited', imgData) 
          : this.$emit('onImgAdded', {
            ...imgData,
            id: Date.now(),
            new: true,
          })
      }
    },
    validateItem(name) {
      this.validate[name] = !!this.imgData[name];
    },
    validateAll() {
      this.validate.caption = !!this.imgData.caption;
      this.validate.source = !!this.imgData.source;
      this.validate.license = !!this.imgData.license;

      return Object.values(this.validate).every(value => value === true);
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    if(window.innerHeight < 780) {
      this.isImgShown = false
    }
    this.imgData = {
      caption: this.currentImgData.caption,
      source: this.currentImgData.source,
      license: this.currentImgData.license,
    }
  }
}
</script>

<style lang="scss" scoped>


.popup-image {
    position: relative;
    width: 370px;
    /* height: 700px; */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    overflow: hidden;
  &__img {
    width: 100%;
    height: 300px;
    margin-bottom: 4px;
    object-fit: cover;
  }
  &__input {
    padding: 16px 24px;

    &:nth-child(2) {
      border-top: 1px solid #EFEFEF;
      border-bottom: 1px solid #EFEFEF;
    }

    label {
      width: 100%;
      margin-bottom: 0;

      span {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 700;
        color: black;
      }
    }
  }
  &__footer {
    display: flex;
    gap: 30px;
    padding: 14px 24px 30px 24px ;

    button {
      height: 36px;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border:none;
    background-color: transparent;
    z-index: 999;
    opacity: .6;
  }
}

@media (max-width: 400px) {
  .popup-image {
    width: 300px;
  }
}
</style>