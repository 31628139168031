import { createStore } from 'vuex'
import { auth } from '@/store/Auth'
import { device } from '@/store/Device'
import { system } from '@/store/System'
import { user } from '@/store/User'
import country from '@/store/country'
import snackbar from '@/store/snackbar'
import navbarFilter from '@/store/navbarFilter'
import translate from '@/store/translate'
import calculator from '@/store/calculator'
import loader from '@/store/loader'
import source from '@/store/source'

const store = createStore({
    modules: {
        auth,
        device,
        user,
        system,
        navbarFilter: {
            namespaced: true,
            ...navbarFilter
        },
        country: {
            namespaced: true,
            ...country
        },
        snackbar: {
            namespaced: true,
            ...snackbar
        },
        translate: {
            namespaced: true,
            ...translate
        },
        calculator: {
            namespaced: true,
            ...calculator
        },
        loader: {
            namespaced: true,
            ...loader
        },
        source: {
            namespaced: true,
            ...source
        }
    }
})

export default store