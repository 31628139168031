<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="device">
    <PopupWrapper ref="popupSlider">
      <SliderPopup 
        :slides="slides" 
        :currentSlide="currentImgSlide" 
        @onPopupClose="() => onPopupClose('popupSlider')" 
      />
    </PopupWrapper>

    <PopupWrapper ref="popupAttachments">
      <AttachmentsViewPopup 
        :slides="attachmentSlides" 
        :currentSlide="currentAttachmentSlide" 
        @onPopupClose="() => onPopupClose('popupAttachments')" 
        @onSlideChanged="onAttachmentSlideChanged"
      />
    </PopupWrapper>

    <PopupWrapper ref="flagsPopup">
      <FlagsPopup 
        :flagsData="flagsDataValues"
        :numberOfPhotos="numberOfPhotos"
        :applyBtnDisabled="flagsApplyBtnDisabled"
        @onValueChanged="onFlagsChanged"
        @onApply="onFlagsApply"
      />
    </PopupWrapper>

    <PopupWrapper ref="reportPopup">
      <ReportPopup 
        @onPopupClose="() => onPopupClose('reportPopup')" 
      />
    </PopupWrapper>

    <div class="my-container">
      <div class="device__header">
        <span>
          {{ $t('devices') }} -> 
          {{ $device.groupName }} -> 
          {{ $device.subGroupName }} -> 
          {{ $device.roleName }} -> 
          <!-- {{ firstHazardName }} -> -->
          <strong> {{ $device.name }} </strong>
        </span>
        <div class="device__actions">
          <img 
            :src="flagIcon" 
            class="device__actions-flag"
            @click="onFlagsPopupOpen"
          >
          <router-link 
            :to="{name: 'DeviceHistory', params: { id: $route.params.id}}" 
          >
            <img 
              v-show="isAdmin"
              :src="infoIcon" 
              class="device__actions-info"
            >
          </router-link>
          <router-link 
            :to="{name: 'EditDevice', params: { id: $route.params.id }}" 
          >
            <img 
              v-show="isAdmin"
              :src="editIcon" 
              class="device__actions-edit"
            >
          </router-link>
          <img 
            v-if="isUser"
            :src="alertIcon" 
            class="device__actions-alert"
            @click="onPopupOpen('reportPopup')"
          >
        </div>
      </div>
      <router-link 
        :to="{name: 'Devices'}" 
      >
        <button class="device__back-btn">
          {{ $t('back') }} 
        </button>
      </router-link>
      <form class="device__card">
        <Spinner :loading="$deviceLoading" />
        <div v-show="!$deviceLoading">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="row">
                <div class="position-relative">
                  <button 
                    v-show="slides[0].imageUrl !=='no-img'"
                    class="device__actions-expand"
                    @click.prevent="onPopupOpen('popupSlider')"
                  >
                    <img 
                      :src="expandIcon"
                      alt="expand icon"
                    >
                  </button>
                  <MySlider 
                    :slides="slides" 
                    @onSlideChanged="setImgCurrentSlide" 
                  />
                </div>
                <div class="device__slides">
                  <div>
                    <b>{{ $t('caption') }}: </b> 
                    {{ currentImgSlide.caption }}
                  </div>
                  <div>
                    <b>{{ $t('source') }}: </b> 
                    {{ currentImgSlide.source }}
                  </div>
                  <div>
                    <b>{{ $t('license') }}: </b> 
                    {{ currentImgSlide.license }}
                  </div>
                </div>
              </div>
              <div 
                class=" device__pdf" 
                :class="slides.length === 1 ? 'device__one-slide' : none"
              >
                <div 
                  v-for="(pdf, i) in $device.attachments"
                  :key="pdf.id"
                  class=""
                >
                  <PdfButton @click.prevent="onAttachmentOpen(pdf, i)" />
                  <span>{{ pdf.title }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-5 device__details">
              <div class="device__details-row device__details-header">
                <h3>{{ $device.name || ' - ' }}</h3> 
                <!-- <span><b>{{ $t('model') }}: </b> {{ $device.name || ' - ' }}</span>  -->
              </div>
              <div class="device__details-row">
                <span><b>{{ $t('auw') }} ({{ $t('kg') }}): </b>{{ $device.auw || '-' }}</span> 
                <span><b>{{ $t('neq') }} ({{ $t('kg') }}): </b>{{ $device.neq || '-' }}</span> 
                <span><b>{{ $t('completeRoundWeight') }} ({{ $t('kg') }}): </b>{{ $device.completeRoundWeight || '-' }}</span>  
                <span><b>{{ $t('propellantWeight') }} ({{ $t('kg') }}): </b>{{ $device.propellantWeight || '-' }}</span> 
                <span><b>{{ $t('explosiveType') }}: </b>{{ explosiveNames || '-' }}</span> 
              </div>
              <div class="device__details-row">
                <span>
                  <b>{{ $t('safetyDistanceProtected') }} ({{ $t('m') }}): </b>
                  {{ 
                    $device.auw < 50
                      ? (130 * Math.pow($device.neq, 1/3)).toFixed(2) 
                      : (370 * Math.pow($device.auw, 1/5)).toFixed(2) 
                  }}
                </span> 
                <span><b>{{ $t('safetyDistanceUnprotected') }} ({{ $t('m') }}): </b>
                  {{ (370 * Math.pow($device.auw, 1/5)).toFixed(2) }}
                </span> 
                <span><b>{{ $t('notam') }} ({{ $t('m') }}): </b>
                  {{ (314 * Math.pow($device.auw, 1/3)).toFixed(2) }}
                </span> 
              </div>
              <div class="position-relative">
                <div class="device__details-row">
                  <span><b>{{ $t('width') }} ({{ $t('mm') }}): </b>{{ $device.width || '-' }}</span> 
                  <span><b>{{ $t('diameter') }} ({{ $t('mm') }}): </b>{{ $device.diameter || '-' }}</span> 
                  <span><b>{{ $t('length') }} ({{ $t('mm') }}): </b>{{ $device.length || '-' }}</span> 
                </div>
                <!-- :class="[calculatorButtonActive ? 'device__add-calc_active' : null ]" -->
                <img 
                  v-if="isUser"
                  class="device__add-calc" 
                  :src="addCalcIcon" 
                  alt="add calc icon"
                  @click="addDeviceToCalculator"
                >
              </div>
              <div 
                v-show="$device.fuzingOptionsCollection.length" 
                class="device__details-row device__details-fuzing"
              >
                <span><b>{{ $t('fuzingOptions') }}:</b></span>
                <div class="devices-carousel">
                  <div 
                    v-for="device in $device.fuzingOptionsCollection"
                    :key="device.id"
                    class="devices-carousel__item"
                  >
                    <router-link 
                      :to="{name: 'Device', params: { id: device.id }}" 
                    >
                      <DeviceSmallCard 
                        :imgPath="device.images[0]?.path" 
                        :title="device.name" 
                      />
                    </router-link>
                  </div>
                </div>
              </div>
              <div 
                v-show="$device.relatedDevicesCollection.length" 
                class="device__details-row device__details-fuzing"
              >
                <span><b>{{ $t('relatedDevices') }}:</b></span>
                <div class="devices-carousel">
                  <div 
                    v-for="device in $device.relatedDevicesCollection"
                    :key="device.id"
                    class="devices-carousel__item"
                  >
                    <router-link 
                      :to="{name: 'Device', params: { id: device.id }}" 
                    >
                      <DeviceSmallCard 
                        :imgPath="device.images[0]?.path" 
                        :title="device.name" 
                      />
                    </router-link>
                  </div>
                </div>
              </div>
              <div 
                v-if="$device.goimsGalleryLink"  
                class="device__details-row"
              >
                <span>
                  <b>{{ $t('goimsDevices') }}: </b>
                  <a 
                    :href="$device.goimsGalleryLink"
                  >
                    Link
                  </a> 
                </span>
              </div>
              <div 
                v-if="$device.relatedAccidentsLink" 
                class="device__details-row"
              >
                <span>
                  <b>{{ $t('relatedAccidents') }}: </b>
                  <a :href="$device.relatedAccidentsLink"> Link </a> 
                </span>
              </div>
              <div class="device__details-row">
                <span><b>{{ $t('CountryOrigin') }}: </b>{{ $device.countryOriginName || ' - ' }}</span> 
                <span><b>{{ $t('countriesFoundIn') }}: </b>{{ foundInNames || ' - ' }}</span> 
              </div>
              <div class="device__details-row">
                <span>
                  <b>{{ $t('source') }}: </b>
                  {{ sourceNames || ' - ' }}
                </span> 
                <span v-if="model3dHref">
                  <b>{{ $t('model3d') }}: </b>
                  <a 
                    :href="model3dHref" 
                    target="_blank"
                  > Link </a> 
                </span>  
              </div>
            </div>
          </div>
          <div class="device__description ql-editor">
            <div class="col">
              <h3>{{ $t('description') }}:</h3> 
              <div v-html="transformeredDescription" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ls from "local-storage";

import infoIcon from "@/assets/img/icons/info.svg";
import editIcon from "@/assets/img/icons/edit.svg";
import expandIcon from "@/assets/img/icons/expand1.png";
import addCalcIcon from "@/assets/img/icons/add-calc.svg";
import alertIcon from "@/assets/img/icons/alert-outline.svg";
import flagIcon from "@/assets/img/icons/flag.svg";

import { 
  MySlider, 
  PdfButton, 
  SliderPopup, 
  PopupWrapper, 
  AttachmentsViewPopup, 
  FlagsPopup, 
  ReportPopup, 
  Spinner,
  DeviceSmallCard
} from "@/components-ui"

export default {
  name: "DeviceView",
  components: {
    MySlider, 
    PdfButton, 
    SliderPopup, 
    PopupWrapper,
    AttachmentsViewPopup,
    FlagsPopup,
    ReportPopup,
    Spinner,
    DeviceSmallCard
  },
  setup () {
    return {
      infoIcon,
      editIcon,
      expandIcon,
      addCalcIcon,
      alertIcon,
      flagIcon,
    }
  },
  data() {
    return {
      // attachmentSlides: [],
      currentAttachmentSlide: {},
      currentImgSlide: {},
      // calculatorButtonActive: false,
      flagsDataValues: {},
      flagsApplyBtnDisabled: false,
      // firstHazardName: '',
      transformeredDescription: ''
    }
  },
  computed: {
    ...mapState("device", {
      $device: "device",
      $adds: "adds"
    }),
    ...mapState("loader", {
      $deviceLoading: "deviceLoading"
    }),

    deviceId() {
      return this.$route.params.id
    },

    slides() {
      if(this.$device.images && this.$device.images.length) {
        return this.$device.images.map(img => {
          return {
            caption: img.caption,
            source: img.source.name,
            license: img.license.name,
            imageUrl: img.path,
          }
        })
      } else {
        return [{
          caption: '-',
          source: '-',
          license: '-',
          imageUrl: 'no-img'
        }]
      }
    },

    sourceNames() {
      if(this.$adds.sources && this.$device.sources) {
        return this.$adds.sources
          .filter(item => this.$device.sources.includes(item.id))
          .map(item => item.name)
          .join(', ');
      }
      return " - "
    },

    explosiveNames() {
      if(this.$adds.explosives && this.$device.explosiveTypes) {
        return this.$adds.explosives
          .filter(item => this.$device.explosiveTypes.includes(item.id))
          .map(item => item.name)
          .join(', ');
      }
      return " - "
    },

    foundInNames() {
      if(this.$device.foundInNames) {
        return this.$device.foundInNames.join(', ')
      }
      return " - "
    },

    fuzingOptionsNames() {
      if(this.$device.fuzingOptionsNames) {
        return this.$device.fuzingOptionsNames.join(', ')
      }
      return " - "
    },

    relatedDevicesNames() {
      if(this.$device.relatedDevicesNames) {
        return this.$device.relatedDevicesNames.join(', ')
      }
      return " - "
    },
    model3dHref() {
      if (this.$device.model3D) {
        return this.$device.model3D.startsWith("http") ? `${this.$device.model3D}` : `http://${this.$device.model3D}`
      } 
      return this.$device.model3D
    },
    numberOfPhotos() {
      if(this.$device.images) {
        return this.$device.images.length
      }
      return 0
    },
    isAdmin() {
      return ls.get("HALO_USER_ROLE") === 'super_admin' || ls.get("HALO_USER_ROLE") === 'admin';
    },
    isUser() {
      return ls.get("HALO_USER_ROLE") === 'user';
    },
    // firstHazardName() {
    //   if(this.$device && this.$device.hazards && this.$adds && this.$adds.hazards) {
    //     const firstHazardId = this.$device.hazards[0];
    //     return this.$adds.hazards.find(item => item.id === firstHazardId).name
    //   }
    //   return ''
    // },

    attachmentSlides() {
      return this.$device.attachments.map(pdf => {
        return {
          title: pdf.title,
          description: pdf.description,
          path: pdf.path
        }
      })
    },
  },
  watch: {
    $route(to, from) {
      const newDeviceId = to.params.id;
      if (this.$device.deviceId !== newDeviceId) {
        this.getDevice({ deviceId: newDeviceId })
      }
    },
    $device() {
      this.setImgCurrentSlide()
      this.setFlagsData()
    }
  },
  methods: {
    ...mapActions("device", ["getDevice", "getDeviceAdds", "updateDeviceFlags"]),
    ...mapActions("calculator", ["addDeviceCalc"]),

    onPopupOpen(refName) {
      this.$refs[refName].onPopupOpen()
    },
    onPopupClose(refName) {
      this.$refs[refName].onPopupClose()
    },
    setImgCurrentSlide(newImgSlide) {
      this.currentImgSlide = newImgSlide 
        ? newImgSlide 
        : {...this.slides[0], index: 0};
    },
    // onImgSliderOpen() {
    //   this.onPopupOpen('popupSlider')
    // },
    onAttachmentOpen(pdf, slideIndex) {
      this.currentAttachmentSlide = {
        title: pdf.title, 
        description: pdf.description, 
        index: slideIndex,
        path: pdf.path
      }
      this.onPopupOpen('popupAttachments')
    },
    // setAttachmentSlides() {
      // this.attachmentSlides = this.$device.attachments.map(pdf => {
      //   return {
      //     title: pdf.title,
      //     description: pdf.description,
      //     path: pdf.path
      //   }
      // })
    //   return this.$device.attachments.map(pdf => {
    //     return {
    //       title: pdf.title,
    //       description: pdf.description,
    //       path: pdf.path
    //     }
    //   })
    // },
    onAttachmentSlideChanged(pdf) {
      this.currentAttachmentSlide = pdf
    },
    addDeviceToCalculator() {
        const deviceData = {
          id: this.$device.deviceId,
          imgPath: this.$device.images[0]?.path,
          name: this.$device.name,
          auw: this.$device.auw,
          quantity: 1
        }
        
        this.addDeviceCalc({
          device: deviceData
        })
    },
    onFlagsPopupOpen() {
      if(Object.keys(this.flagsDataValues).length) {
        this.onPopupOpen('flagsPopup')
      }
    },
    setFlagsData() {
      this.flagsDataValues = {
        missingData: this.$device.missingData,
        needsAdditionalPhotos: this.$device.needsAdditionalPhotos,
        needsCrossSection: this.$device.needsCrossSection,
        errorReported: this.$device.errorReported,
        needsQC: this.$device.needsQC
      }
    },
    onFlagsChanged(name, newVal) {
      this.flagsDataValues[name] = newVal
    },
    async onFlagsApply() {
      this.flagsApplyBtnDisabled = true;
      await this.updateDeviceFlags({
        deviceId: this.$route.params.id, 
        flags: this.flagsDataValues
      })
      this.flagsApplyBtnDisabled = false;
      this.onPopupClose('flagsPopup')
    },
    transformDescription() {
      const regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
      
      this.transformeredDescription = this.$device.description.replace(regex, '<a href="http://$1"');;
    }
    // computeFirstHazardName() {
    //   const firstHazardId = this.$device.hazards[0];
    //   this.firstHazardName = this.$adds.hazards.find(item => item.id === firstHazardId).name
    // }
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
    await this.getDevice({ deviceId: this.deviceId })
    this.transformDescription()
    this.setImgCurrentSlide()
    await this.getDeviceAdds()
    // this.computeFirstHazardName()
    // this.setAttachmentSlides()
    this.setFlagsData()
  }
}
</script>

<style lang="scss">

.device {
  &__header {
    display: flex;
    justify-content: space-between;

    padding: 30px 0 20px 0;

    border-bottom: 1px solid #D2D3D3;

    span {
      font-weight: 700;
      color: #0A3C6180;
    }

    strong {
      color: #0B3C61;
    }
  }

  &__actions {
    display: flex;
    justify-content: end;
    align-items: center;
    min-width: 185px;
    height: 30px;
    &-flag {
      padding-right: 17px;
      /* border-right: 1px solid #D2D3D3; */
      cursor: pointer;
      height: 20px;

    }
    &-info {
      padding-left: 17px;
      border-left: 1px solid #D2D3D3;
      margin-right: 17px;
      cursor: pointer;
    }

    &-edit {
      display: block;
      padding-left: 17px;
      margin-right: 17px;
      border-left: 1px solid #D2D3D3;
      font-size: 12px;
      height: 20px;
      /* border: none; */
      cursor: pointer;
    }

    &-expand {
      position: absolute;
      top: 10px;
      right: 20px;
      background-color: transparent;
      border: none;
      z-index: 2;
      padding: 10px;

      img {
        height: 30px;
        width: 30px;
        /* filter: invert(1); */
        opacity: .9;
      }
    }

    &-alert {
      /* margin-left: 17px; */
      padding-left: 17px;
      border-left: 1px solid #D2D3D3;
      cursor: pointer;
    }
  }

  &__back-btn {
    padding: 10px 8px 8px 30px;
    margin-bottom: 8px;
    font-size: 12px;
    background: #ffffff00 url("@/assets/img/icons/arrow-back.svg");
    background-position: 8px 12px;
    background-repeat: no-repeat;
    border: none;
  }

  &__card {
    padding: 20px 30px;
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    .vueperslides__parallax-wrapper {
      border-radius: 20px !important;
    }
  }

  &__details {
    margin-left: 20px;
    &-row {
      display: flex;
      flex-direction: column;
      gap: 7px;
      padding: 7px 0;
      font-size: 14px;
      border-top: 1px solid #D2D3D3;
    }
    &-header {
      border: none;
      gap: 10px;
      padding-top: 0;
    }
    &-fuzing {
      gap: 0;
    }
  }

  &__pdf {
    position: relative;
    top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 20px;
    padding: 20px 0;
    
    border-top: 1px solid #D2D3D3;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        margin-bottom: 7px;
      }
    }
    /* div:first-child {
      padding-right: 30px;
      margin-right: 30px;
      border-right: 1px solid #D2D3D3;
    } */
  }

  &__description {
    padding-top: 16px;
    border-top: 1px solid #D2D3D3;

    h3 {
      margin: 0;
    };

    div {
      padding: 10px 0 5px 0;
    }

    /* span {
      font-style: italic;
      font-weight: lighter;
    } */
  }

  &__slides {
    position: relative;
    top: -40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;

    div {
      width: 33%;
      font-size: 12px;
      display: inline;
      padding: 0 5px;
    }
    div:nth-child(2) {
      /* padding: 0 16px; */
      border-right: 1px solid #D2D3D3;
      border-left: 1px solid #D2D3D3;
      /* margin: 0 16px; */
    } 
  }
  
  &__one-slide {
    position: relative;
    top: -40px;
  }

  &__add-calc {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    cursor: pointer;
    /* filter: contrast(0.7); */

    &_active {
      filter: contrast(1);
    }
  }

}

.devices-carousel {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  
  /* border-top: 1px solid #D2D3D3; */
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

}

@media (max-width: 992px) {
  .device__details {
    margin-left: 0;
  }
  .device__description {
    padding: 0;
  }
  .device__pdf {
    top: 0px;
  }
  .device__one-slide {
    top: -40px !important;
  }
}
</style>