<template>
  <div :class="`device-calc`"> 
    <button 
      class="device-calc__close" 
    >
      <img 
        :src="closeIcon"
        alt="close icon"
        @click="onRemove"
      >
    </button>
    <div class="device-calc__wrapper">
      <div 
        :class="`device-calc__img`" 
        :style="{ 'background-image': imgPath === 'no-img' ? `url(${noImg})` : `url(${baseUrl}${imgPath})` }"
      />
      <div :class="`device-calc__text`">
        <span :class="`device-calc__title`">{{ quantity }} x {{ name }}</span>
        <span :class="`device-calc__descr`">({{ auw }}kg each)</span>
      </div>
    </div>
    <div class="device-calc__actions">
      <img 
        :src="plusCircleIcon" 
        alt="plus icon" 
        class="device-calc__plus" 
        @click="quantityAction(1)"
      >
      <img 
        :src="minusCircleIcon" 
        alt="plus icon" 
        class="device-calc__minus" 
        @click="quantityAction(-1)"
      >
    </div>
  </div>
</template>

<script>
import plusCircleIcon from "@/assets/img/icons/plus-circle.svg";
import minusCircleIcon from "@/assets/img/icons/minus-circle.svg";
import closeIcon from "@/assets/img/icons/close-gray.svg";

import noImg from "@/assets/img/no-img.jpg";

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: 'no-name'
    },
    imgPath: {
      type: String,
      default: 'no-img'
    },
    auw: {
      type: Number,
      default: 0
    },
    quantity: {
      type: Number,
      default: 0
    }
  },
  setup () {
    return {
      plusCircleIcon,
      minusCircleIcon,
      noImg,
      closeIcon
    }
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_IMG_URL || '';
    }
  },
  methods: {
    quantityAction(value) {
      this.$emit('onQuantityAction', {id: this.id, value})
    },
    onRemove() {
      this.$emit('onDeviceRemove', this.id)
    }
  },
}
</script>

<style lang="scss" scoped>

.device-calc {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 120px;

  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &__close {
    position: absolute;
    top: -15px;
    right: -15px;
    border:none;
    background-color: transparent;
    z-index: 999;

    img {
      width: 30px;
      height: 30px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__img {
    width: 220px;
    background: url("https://m.gordonua.com/img/article/14934/42_tn.jpg?v1585646734") center center/cover no-repeat;
    border-radius: 8px;
    margin-right: 30px
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
  }

  &__descr {
    font-size: 16px;
    color: #717D96;
  }

  &__actions {
    display: flex;
    gap: 26px;
    margin-right: 40px;
  }

  &__plus {
    width: 25px;
    cursor: pointer;
  }
  &__minus {
    width: 25px;
    cursor: pointer;
  }
}

@media (max-width:720px) {
  .device-calc {
    flex-direction: column;
    padding: 10px;

    &__wrapper {
      justify-content: center;
    }

    &__descr {
      align-self: center;
    }

    &__img {
      display: none;
    }

    &__actions {
      justify-content: center;
      margin: 0;
    }
  }

}
</style>