export default {
  state: {
    error: null,
    errorAPI: null,
    success: null,
    warning: null,
  },

  actions: {
    setErrorApi({ commit }, error) {
      // const errorsObject = error?.response?.data?.errors

      // commit("SET_ERROR_API", error.response?.data?.details?.violations
      //   ? error.response.data.details.violations[0].message
      //   : error.response?.data?.description || errorsObject ? Object.values(errorsObject)[0][0] : "Something went wrong"
      // )

      
      const response = error?.response;
      const errorsObject = response?.data?.errors;
      const details = response?.data?.details;
      

      let errorMessage = "Something went wrong";

      if (details?.violations) {
        errorMessage = details.violations[0].message;
      } else if (response?.data?.description) {
        if (response?.data?.description === 'Token is expired' || response?.data?.description === 'Token is invalid' ) return
        errorMessage = response.data.description;
      } else if (errorsObject) {
        errorMessage = Object.values(errorsObject)[0][0];
      }

      commit("SET_ERROR_API", errorMessage);
    },
    setErrorMessage({commit}, errorMessage) {
      commit("SET_ERROR_API", errorMessage)
    }
  },

  mutations: {
    SET_ERROR_API(state, message) {
      state.errorAPI = message;
    },
    SET_SUCCESS_MESSAGE(state, message) {
      state.success = message
    }
  }
}