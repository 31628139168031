const mutations = { 
    auth_success(state, data) {
        state.status = 'success'
        state.token = data.token
        state.user = data.user_email
    },
    logout(state) {
        state.status = ''
        state.token = ''
    },
}

export default mutations