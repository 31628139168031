import { translate } from "@/api";
import langNames from "@/locales/langNames";
import { get, set } from "local-storage"

export default {
  state: {
    translates: [],
    allowedModels: []
  },

  actions: {
    // async importTranslate({ dispatch, commit }, { file }) {
    //   try {

    //     const translateCSV = new FormData();

    //     translateCSV.append('file', file);

    //     const { data } = await translate.importTranslate({ file: translateCSV })

    //     commit('snackbar/SET_SUCCESS_MESSAGE', 'Translate successfuly added', {root: true}) 
    //   } catch (error) {
    //     // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
    //     dispatch('snackbar/setErrorApi', error, { root: true })
        
    //   }
    // },

    // async getTranslateCollection({dispatch, commit}) {
    //   try {
    //     const { data } = await translate.getCollection()

    //     const transformedLanguages = data.map(translateShort => {
    //       return {
    //         short: translateShort,
    //         name: langNames[translateShort] || 'Not-found',
    //         active: true,
    //       }
    //     })

    //     commit('SET_TRANSLATES', transformedLanguages)

    //   } catch (error) {
    //     // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
    //     dispatch('snackbar/setErrorApi', error, { root: true })
    //   }
    // },

    async getLocaleCollection({dispatch, commit}) {
      try {
        const { data } = await translate.getLocaleCollection()

        const transformedLanguages = data.result.map(locale => {
          return {
            short: locale.name,
            name: langNames[locale.name] || 'Unknown',
            active: locale.active,
            id: locale.id
          }
        })
        commit('SET_TRANSLATES', transformedLanguages)

      } catch (error) {
        // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },
    

    async setLanguage({dispatch, commit}, lang) {
      try {

        await translate.setTranslate(lang)

        localStorage.setItem("HALO_LANG", lang);
        location.reload()
      
      } catch (error) {
        // commit('snackbar/SET_ERROR_API', "Error with translation setting", {root: true}) 
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },

    async setActiveLocale({commit, dispatch}, {localeId, newValue} = {}) {
      try {

        await translate.setActiveTranslate({localeId, newValue})

        dispatch('translate/getLocaleCollection', null, { root: true })

      } catch (error) {
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },

    async getAllowedModels({dispatch, commit}) {
      try {
        const { data } = await translate.getAllowedModels()

        const transformeredData = data.result.map(model => {
          return {
            name: model.name,
            id: model.model
          }
        })
        // {
        //   name: 'Static data',
        //   id: 'static'
        // }]

        commit('SET_ALLOWED_MODELS', transformeredData)

      } catch (error) {
        // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },

    async importTranslate({ dispatch, commit }, { model, file }) {
      try {

        const translateCSV = new FormData();

        translateCSV.append('file', file);
        translateCSV.append('model', model);

        await translate.importTranslate({ file: translateCSV})

        commit('snackbar/SET_SUCCESS_MESSAGE', 'Translate successfuly added', {root: true}) 

        switch (model) {
          case 'devices':
          case 'attachments': 
            dispatch('device/getDeviceCollection', {}, {root: true})
            break;
          case 'attachment_licenses':
          case 'device_hazards':
          case 'device_roles':
          case 'device_sources':
          case 'device_explosives':
            dispatch('device/getDeviceAdds', {}, {root: true}) 
            break;
          case 'device_groups':
          case 'device_sub_groups':
            dispatch('device/getActualGroupCollection', {}, {root: true}) 
            break;
          case 'static_data':
            dispatch('translate/getLocaleCollection', {}, {root: true}) 
        }
      } catch (error) {

        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },
  },

  mutations: {
    SET_TRANSLATES(state, translates) {
      state.translates = translates
    },
    SET_ALLOWED_MODELS(state, allowedModels) {
      state.allowedModels = allowedModels
    }
  }
}