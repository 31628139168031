import axios from 'axios';
import store from "@/store";
import router from "@/router";

export function errorHandler(error) {
    store.commit('loading', false)

    if (error.response) {
        if (error.response.status === 422) {
            let message = error.response.data.message
            const errors = error.response.data.errors
            for (const [key, value] of Object.entries(errors)) {
                message += ' ' + `${key}: ${value}`
            }
            let status = error.response.status
            store.commit('set_message', {message, status})

        } else if (error.response.status === 401) {
            store.commit('logout')
            localStorage.removeItem('HALO_TOKEN')
            delete axios.defaults.headers.common['Authorization']
            setMessage(error)
            router.push({name: 'Login'})

        } else if (error.response.status !== 200) {
            setMessage(error)
        }
    }
    return false
}

function setMessage(error) {
    let message = '';

    if (error.response.data.message) {
        message = error.response.data.message;
    } else if (error.response.data.error) {
        message = error.response.data.error
    }
    if (error.response.data.description) {
        message += error.response.data.description;
    }
    if (!message) {
        message = 'unknown error, check server response'
    }

    let status = error.response.status
    store.commit('set_message', {message, status}) 
}