import { Axios } from "./config.js";

const basePath = "device"
export default class DeviceService {

  async getCollection({ params = {} } = {}) {
    return await Axios.get(`${basePath}`, {
      params
    })
  }

  async getDevice(deviceId) {
    return await Axios.get(`${basePath}/${deviceId}`)
  }

  async getAdds({ foundInId = null } = {}) {
    return await Axios.get(`${basePath}/adds`, {
      params: {
        country_found_in_id: foundInId
      }
    })
  }

  async create(body) {
    return await Axios.post(`${basePath}`, body);
  }

  async update({deviceId, body} = {}) {
    return await Axios.put(`${basePath}/${deviceId}`, body)
  }

  async uploadAttachment({deviceId, attachment} = {}) {
    return await Axios.post(`${basePath}/${deviceId}/attachment`, attachment)
  }

  async updateAttachment({deviceId, attachmentId, body} = {}) {
    return await Axios.put(`${basePath}/${deviceId}/attachment/${attachmentId}`, body)
  }

  async deleteAttachment({deviceId, attachmentId} = {}) {
    return await Axios.delete(`${basePath}/${deviceId}/attachment/${attachmentId}`)
  }

  async getHistory(deviceId) {
    return await Axios.get(`/history/device/${deviceId}`)
  }

  async delete(deviceId) {
    return await Axios.delete(`${basePath}/${deviceId}`)
  }

  async calculateSafetyDistance(devices) {
    return await Axios.post(`${basePath}/calculate-safety-distance`, devices)
  }

  async report(body) {
    return await Axios.post(`mail/report`, body);
  }

  async getActualGroups({foundInId = null} = {}) {
    return await Axios.get(`${basePath}/group/actual`, {
      params: {
        country_found_in_id: foundInId
      }
    })
  }

  async getGOIMS({term = ''} = {}) {
    return await Axios.get(`${basePath}/goims/${term}`)
  }
}
