export const mutations = {

  SetUserCollection(state, { data }) {
    state.users = data
  },
  SetUser(state, { data }) {
    state.user = data
  },
  SetUsersLoaded(state, loaded) {
    state.usersLoaded = loaded
  },

}