<template> 
  <QuillEditor 
    v-model:content="editorValue"
    content-type="html"
    theme="snow" 
    :toolbar="toolbar"
    class="my-editor"
    :class="[validate ? null : 'my-editor-red']"
    @update:content="$emit('onEditorChanged', editorValue)"
  />
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "MyEditor",
  components: {
    QuillEditor
  },
  props: {
    validate: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {}
  },
  data() {
    return {
      toolbar: [
        { 'size': ['small', false, 'large', 'huge'] },
        'bold', 
        'italic', 
        'underline', 
        'blockquote', 
        { 'list': 'bullet' }, 
        { 'list': 'ordered'},
        { 'color': [] },
        'link', 
        'image',
        'video',
        'clean'
      ],
      editorValue: ''
    }
  },
  watch: {
    modelValue(newValue) {
      this.editorValue = newValue;
    }
  },
}
</script>

<style lang="scss">
  .my-editor {
    height: 250px;
    overflow: hidden;
    border: 1px solid #d1d5db;
  }

  .my-editor-red {
    border: 2px solid #dc3545;
  }
</style>