<template>
  <vueper-slides
    bullets-outside
    fixed-height="300px"
    @slide="onSlideChanged"
  >
    <template #arrow-left>
      <img :src="caruselButtonLeft">
    </template>

    <template #arrow-right>
      <img :src="caruselButtonRight">
    </template>

    <vueper-slide
      v-for="(slide, i) in slides"
      :key="i"
      :image="slide.imageUrl === 'no-img' ? noImg : `${baseUrl}${slide.imageUrl}`"
      :content="slide"
      :style="{'background-size': slide.imageUrl === 'no-img' ? `cover`: `contain`}"
    >
      <template #content>
        <div class="vueperslide__content-wrapper" />
      </template>
    </vueper-slide>
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import noImg from "@/assets/img/no-img.jpg";

import caruselButtonLeft from "@/assets/img/icons/button-left.svg";
import caruselButtonRight from "@/assets/img/icons/button-right.svg";

export default {
  components: {
    VueperSlides, VueperSlide
  },
  props: {
    slides: {
      type: Array,
      default: () => []
    }
  },
  emits: ['onSlideChanged'],
  setup () {
    return {
      caruselButtonLeft,
      caruselButtonRight,
      noImg
    }
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_IMG_URL || '';
    }
  },
  methods: {
    onSlideChanged(event) {
      this.$emit('onSlideChanged', {...event.currentSlide.content, index: event.currentSlide.index})
    }
  },
}
</script>

<style lang="scss">
.vueperslides__bullet .default {
  background-color: transparent;
  border: 1px solid #77C4D5;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}

.vueperslides__bullet--active .default {
  background-color: #008DA8;
  border: 1px solid #008DA8;

}

.vueperslides__bullet span {
  display: block;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
}

.vueperslides__bullets--outside {
  top: 40px;
}
</style>