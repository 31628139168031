<template>
  <section class="users">
    <div class="my-container">
      <div class="users-header">
        <div class="users-header__actions">
          <router-link 
            :to="{name: 'AddUser'}" 
          >
            <MyButton :title="$t('addNewUser')" /> 
          </router-link>
          <MyButton 
            :title="$t('csvUpload')" 
            @click="onImportBtn"
          /> 
          <MySelect
            :options="rolesValues" 
            :title="$t('selectRole')" 
            class="users-header__select"
            @change="filterByRole"
          />
        </div>
      </div>

      <div class="users-wrapper__loading-card">
        <Spinner :loading="loading" />
      </div>

      <div 
        v-show="filteredUsers.length <= 0 && !loading" 
        class="users__nothing"
      >
        <h3>
          {{ $t('noUsers') }}
        </h3>
      </div>

      <div v-show="!loading" class="users-wrapper">
        <!-- <UserCard :name="'petya'" :email="'petya@adsa'"/> -->
        <UserCard 
          v-for="user in filteredUsers" 
          :key="user.id"
          :name="`${user.first_name} ${user.last_name}`"
          :email="user.email"
          :deleted="user.deleted"
          @click="goToUser(user.id)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MyButton, MySelect, UserCard, Spinner } from "@/components-ui"


export default {
  name: "UserView",
  components: {
    MyButton,
    MySelect,
    UserCard,
    Spinner
  },
  data() {
    return {
      loading: false,
      rolesValues: [
        {
          id: 0, 
          name: this.$t('reset')
        }, 
        {
          id: 'admin', 
          name: this.$t('admin')
        }, 
        {
          id: 'user', 
          name: this.$t('fieldStaff')
        }, 
        {
          id: 'super_admin', 
          name: this.$t('superAdmin')
        }
      ]
    }
  },
  computed: {
    ...mapState("user", {
      $users: "users",
      $usersLoaded: "usersLoaded"
    }),
    ...mapState("navbarFilter", {
      $navbarFilter: "filterValue"
    }),

    filteredUsers() {
      return this.$users.filter(user => {
        return user.first_name.toLowerCase().includes(this.$navbarFilter.toLowerCase())
      })
    },

  },
  methods: {
    ...mapActions("user", ["getUserCollection", "importUsers"]),

    goToUser(id) {
      this.$router.push({
        name: 'EditUser',
        params: {id: id}  
      })
    },
    onImportBtn() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'text/csv';
      input.addEventListener('change', this.onCsvSelected);
      input.click();
    },
    async onCsvSelected(event) {
      const file = event.target.files[0];

      if (file && file.type.startsWith('text/csv')) {
        await this.importUsers({ file })
        this.getUserCollection()
      }
    },
    async filterByRole(event) {
      this.loading = true;
      await this.getUserCollection({ 
        params: {
          role: event.target.value === '0' ? null : event.target.value
        }
      })
      this.loading = false;
    },
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
    if (!this.$usersLoaded) {
      this.loading = true;
      await this.getUserCollection()
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>


.users {
  &-header {
    display: flex;
    justify-content: space-between;

    padding: 31px 0 24px 0;

    border-bottom: 1px solid #B1B2B2;

    &__actions {
      display: flex;
      gap: 20px;
    }

    &__select {
      width: 150px;
      height: 36px;
    }

    &__right {
      display: flex;
      align-items: center;
    }

    &__all {
      display: flex;
      gap: 3px;
      padding-right: 30px;
      height: 30px;
      white-space: nowrap;
      font-size: 16px;
      line-height: 30px;

      border-right: 1px solid #B1B2B2;
    }

    &__blocks {
      display: block;
      padding: 0 30px 0 43px;
      cursor: pointer;
    }

    &__list {
      width: 24px;
      cursor: pointer;
    }

  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    gap: 24px 15px;
    margin-bottom: 30px;
    /* margin: 30px 0; */

    &__loading-card {
      margin-top: 24px;
      width: 100%;
      background-color: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
    }
  }

  &__nothing {
    padding: 20px 30px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    h3 {
      text-align: center;
      margin-bottom: 0;
    }
  }

}

.disable-icon {
  filter: grayscale(100%);
  opacity: 0.3;
}

@media (max-width: 480px) {
  .users-header {
    justify-content: center;
  }
  .users-header__actions {

    a {
      button {
        width: 100%;
      }
    }
    flex-direction: column;
  }
}
</style>