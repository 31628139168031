<template>
  <vue-awesome-paginate
    v-model="currentPage"
    :total-items="total"
    :items-per-page="limit"
    :max-pages-shown="3"
    :show-breakpoint-buttons="true"
    :on-click="(page) => $emit('onChange', page)"
  />
</template>

<script>
  import { ref, watch } from "vue";

  export default {
    name: 'PaginationButtons',
    props: {
      total: {
        type: Number,
        default: 0
      },
      limit: {
        type: Number,
        default: 0
      },
      page: {
        type: Number,
        default: 1
      }
    },
    emits: ['onChange'],
    setup(props) {

      watch(() => props.page, (newVal) => {
        if(newVal !== currentPage.value) {
          currentPage.value = newVal
        }
      })

      const currentPage = ref(1);
      return {
        currentPage
      };
    },
  }
</script>

<style lang="scss">
  .pagination-container {
    display: flex;
    column-gap: 10px;
  }
  .paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(217, 217, 217);
    color: black;
  }
  .paginate-buttons:hover {
    background-color: #d8d8d8;
  }
  .active-page {
    background-color: #276B9D;
    border: 1px solid #276B9D;
    color: white;
  }
  .active-page:hover {
    background-color: #276B9D;
  }

  @media (max-width: 550px) {
    .paginate-buttons {
      height: 30px;
      width: 30px;
    }
  }
  </style>