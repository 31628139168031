import {createApp} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from "axios";
import vClickOutside from "click-outside-vue3"

import { SnackbarService } from "vue3-snackbar";
import "vue3-snackbar/styles";

import '@/assets/css/common-style.css'
import '@/assets/main.css'

import DefaultLayout from "@/layouts/DefaultLayout";
import PublicLayout from "@/layouts/PublicLayout";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import i18n from '@/plugins/i18n'

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

window.$ = window.jQuery = require("jquery");


(async () => {
    const app = createApp(App)
    app.config.warnHandler = function (msg, vm, trace) {
        return null
    }
    app.use(store)
    app.use(router)
    app.use(vClickOutside)
    app.use(SnackbarService);

    app.use(await i18n())
    
    app.component('DefaultLayout', DefaultLayout)
    app.component('PublicLayout', PublicLayout)
    
    app.use(VueAwesomePaginate)
    app.mount('#app')
})()


