export const mutations = {

  SetDeviceCollection(state, {data, total = state.total, per_page = state.limit, current_page = state.page}) {
    state.devices = data
    state.page = current_page
    state.limit = +per_page
    state.total = total
  },

  SetDevice(state, { data }) {
    state.device = data
  },

  AddDevice(state, { data }) {
    state.devices.push(data);
  },

  SetDeviceAdds(state, { result }) {
    state.adds = result
  },

  SetDeviceHistory(state, { data }) {
    state.history = data
  },

  // SetDeviceTotal(state, total) {
  //   state.total = total
  // },

  SetActualGroups(state, actualGroups) {
    state.actualGroups = actualGroups
  },

  SetDevicesLoaded(state, value) {
    state.devicesLoaded = value
  },

  SetGOIMS(state, {result}) {
    state.goims = result
  }

}