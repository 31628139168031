<template> 
  <div class="add-hazard">
    <h4>{{ title }}</h4>
    <div class="add-hazard__checkboxes">
      <MyCheckbox 
        v-for="item in items" 
        :key="item.id"
        :title="item.name"
        :value="item.checked || false"
        @checked="onChecked(item.id, $event)"
      />
    </div>
  </div>
</template>

<script>
import { MyCheckbox } from "@/components-ui";

  export default {
    name: 'CheckboxesPopup',
    components: {
      MyCheckbox,
    },
    props: {
      title: {
        type: String,
        default: 'Add hazard'
      },
      items: {
        type: Array,
        default: () => []
      },
    },
    emits: ['onValueChanged'], 
    methods: {
      onChecked(id, newValue) {
        this.$emit('onValueChanged', id, newValue)
      },
    },
  }
</script>

<style lang="scss" scoped>
.add-hazard {
  display: flex;
  flex-direction: column;
  max-width: 335px;
  height: 500px;
  padding: 24px 30px 0 30px;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

  h4 {
    padding-bottom: 16px;
    color: black;
  }
  
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: scroll;
    padding-bottom: 24px;
    label {
      font-size: 16px;
    }
  }
}
</style>