<template>
  <button class="add-btn">
    {{ $t('add') }} 
  </button>
</template>

<script>
export default {
  name: "AddButton",
  props: {
  },
  setup () {
    return {}
  }
}
</script>

<style lang="scss" scoped>

.add-btn {
    height: 36px;
    padding: 8px 8px 8px 32px;
    font-size: 14px;
    background: #ffffff url("@/assets/img/icons/plus2.svg");
    background-position: 8px 10px;
    background-repeat: no-repeat;
    border: 1px solid #363939;
    border-radius: 4px;

    &:disabled {
      background: #e3e3e3 url("@/assets/img/icons/plus2.svg");
      background-position: 8px 10px;
      background-repeat: no-repeat;
      color: black;
    }
}
</style>