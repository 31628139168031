import { Axios } from "./config.js";

export default class CountryService {

  async getCollection({countriesOfOrigin = null, countriesFoundIn = null} = {}) {
    return await Axios.get("country", {
      params: {
        is_country_of_origin: countriesOfOrigin,
        is_country_found_in: countriesFoundIn
      }
    });
  }

}