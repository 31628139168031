<template>
  <div class="search-input nav-item border-bottom">
    <div class="inputs d-flex search-input__wrapper">
      <img
        class="search-input__search"
        :src="searchIcon"
        alt="search icon"
      >
      <input 
        type="text" 
        class="search-input__input" 
        :placeholder="$t('search')" 
        :value="modelValue"
        @input="updateValue"
      >
    </div>
    <img
      class="search-input__filter"
      :src="filterIcon"
      alt="filter icon"
      @click="$emit('filters-icon-click')"
    >
  </div>
</template>

<script>
import searchIcon from "@/assets/img/icons/search.svg";
import filterIcon from "@/assets/img/icons/filter.svg";
import ls from "local-storage"

export default {
  name: "SearchInput",
  props: {
    modelValue: String
  },
  emits: ['filters-icon-click', 'update:model-value'],
  setup() {
    return {
      searchIcon,
      filterIcon
    };
  },
  // computed: {
  //   isSuperAdmin() {
  //     return ls.get("HALO_USER_ROLE") === 'super_admin'
  //   },
  // },
  methods: {
    updateValue(event) {
      this.$emit('update:model-value', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>

  .search-input {
    display: flex;
    justify-content: space-between;
    width: 30%;
    height: 26px;

    &__wrapper {
      width: 100%;
    }

    &__search {
      width: 18px;
      filter: invert(1);
      cursor: pointer;
    }

    &__input {
      background-color: transparent;
      width: 100%;
      border: none;
      margin-left: 14px;
      color: #ffffff;
      &::placeholder { 
        color: #D2D3D3;
        font-size: 14px;
        font-weight: 300;
        opacity: 1;
      }
    }

    &__filter {
      width: 16px;
      filter: invert(1);
      cursor: pointer;
    }
  }

  @media (max-width: 750px) {
    .search-input {
      /* display: none; */
    }
  }


</style>