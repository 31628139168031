<template>
  <div class="goims">
    <div class="goims__header">
      <h4>{{ title }}</h4>
      <div class="goims__wrapper">
        <div class="goims__search">
          <img
            class="goims__search-img"
            :src="searchIcon"
            alt="search icon"
          >
          <input 
            v-model="term"
            type="text" 
            class="goims__input" 
            :placeholder="$t('search')" 
          >
        </div>
  
        <MyButton
          :title="$t('search')"
          :disabled="searchLoading"
          @click="searchGioms"
        />
      </div>
    </div>
    <div class="goims__checkboxes">
      <MyCheckbox 
        v-for="item in items" 
        :key="item.id"
        :title="item.name"
        :value="item.checked || false"
        @checked="onChecked(item.id, item.name, $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { MyCheckbox, MyButton } from "@/components-ui";

import searchIcon from "@/assets/img/icons/search-light.svg";


  export default {
    name: 'CheckboxesSearchPopup',
    components: {
      MyCheckbox,
      MyButton
    },
    props: {
      title: {
        type: String,
        default: 'Add fusion'
      },
      items: {
        type: Array,
        default: () => []
      },
    },
    emits: ['onValueChanged'], 
    setup() {
      return {
        searchIcon
      }
    },
    data() {
      return {
        term: "",
        searchLoading: false,
      }
    },
    methods: {
      ...mapActions("device", ["getGoimsCollection"]),

      onChecked(id, name, newValue) {
        this.$emit('onValueChanged', {id, name, newValue})
      },
      async searchGioms() {
        if(this.term) {
          this.searchLoading = true;
          await this.getGoimsCollection({term: this.term})
          this.searchLoading = false;
        }
      }
      
    },
  }
</script>

<style lang="scss" scoped>
.goims {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 495px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  

  &__header {
    padding: 24px 30px;
    border-bottom: 1px solid #D2D3D3;
  }

  h4 {
    /* padding: 24px 30px 0px 30px; */
    margin-bottom: 26px;
    color: black;
  }

  &__wrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  &__search {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #D2D3D3;
    align-items: center;

    &-img {
      width: 24px;
      height: 24px;
    }
  }

  &__input {
    width: 100%;
    font-size: 14px;
    border: none;

    &::placeholder {
      color: #D2D3D3;
    }
  }
  
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px 24px 30px;
    overflow: auto;
    
    label {
      font-size: 16px;
    }
  }
}
</style>