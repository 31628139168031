import { Axios } from "@/api/config.js";
import store from '@/store'

import { createI18n } from 'vue-i18n'

function currentLanguage() {
  return localStorage.getItem("HALO_LANG") || process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
}

async function loadLocaleMessages(currentLanguage) {
  try {
    Axios.put(`translation/${currentLanguage}`)
    const response = await Axios.get(`/translation/${currentLanguage}.json`);
    const translations = response.data;
    
    return { [currentLanguage]: {...translations} };
  } catch (error) {
    return { [currentLanguage]: {} };
  }
}

async function setupI18n() {
  const messages = await loadLocaleMessages(currentLanguage());

  return createI18n({
    locale: currentLanguage(),
    fallbackLocale: currentLanguage(),
    messages,
  });
}

export default setupI18n