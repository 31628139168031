<template>
  <section class="translations">
    <PopupWrapper ref="manageTranslationsPopup">
      <ManageTranslationsPopup
        :title="$t('translations')"
        :items="$translates"
      />
    </PopupWrapper>
    <PopupWrapper ref="areYouSurePopup">
      <AreYouSurePopup 
        :fileName="currentFileName" 
        @onAccept="onPopupAccept"
        @onPopupClose="() => onPopupAction('areYouSurePopup', 'close')" 
      />
    </PopupWrapper>

    <div class="my-container">
      <div class="translations__header">
        <span>{{ $t('translations') }}</span> 
      </div>
      <button 
        class="translations__back-btn" 
        @click="$router.push({
          name: 'Devices'
        })"
      >
        {{ $t('back') }} 
      </button>
      <div class="translations__card needs-validation">
        <div class="translations__wrapper">
          <div class="translations__general">
            <h3 class="translations__general-title"> 
              {{ $t('translationsOptions') }} 
            </h3>
            <div class="translations__btns">
              <div
                class="translations__btn-manage"
              >
                <MyButton 
                  :title="$t('manageTranslations')"  
                  :type="'o'"
                  @click="onPopupAction('manageTranslationsPopup', 'open')"
                />
              </div>
              <MySelect
                v-model="translationModel"
                class="translations__btn-select"
                :title="'Select upload model'"
                :options="$allowedModels" 
              />
              <MyButton 
                class="translations__btn-upload"
                :title="$t('csvUpload')" 
                :disabled="csvUploadLoading || !translationModel"
                @click="onImportBtn"
              />
            </div>
          </div>
        </div>
        <div class="translations__footer">
          <!-- <router-link 
            :to="{name: 'Devices'}" 
          >
            <MyButton 
              :title="$t('cancel')"  
              :type="'o'"
            />
          </router-link>
          <MyButton 
            :title="$t('save')" 
          /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash";

import { 
  MyInput, 
  MySelect, 
  MyButton, 
  PopupWrapper,
  ManageTranslationsPopup,
  AreYouSurePopup,
} from "@/components-ui"

export default {
  name: "TranslationsView",
  components: {
    MyButton,
    PopupWrapper,
    ManageTranslationsPopup,
    MySelect,
    AreYouSurePopup
  },
  setup () {
    return {}
  },
  data() {
    return {
      csvUploadLoading: false,
      translationModel: '',
      currentFileName: '',
      currentFile: null,
    }
  },
  computed: {
    ...mapState("translate", {
      $translates: "translates",
      $allowedModels: "allowedModels"
    }),
  },
  methods: {
    ...mapActions("translate", ["getLocaleCollection", "getAllowedModels", "importTranslate"]),

    onImportBtn() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'text/csv';
      input.addEventListener('change', this.onCsvSelected);
      input.click();
    },
    onCsvSelected(event) {
      this.currentFile = event.target.files[0];
      this.currentFileName = event.target.files[0].name;

      if (this.currentFile && this.currentFile.type.startsWith('text/csv')) {
        this.onPopupAction('areYouSurePopup', 'open')
      }
    },

    async onPopupAccept() {
      this.csvUploadLoading = true;
      this.onPopupAction('areYouSurePopup', 'close')
      await this.importTranslate({ 
        file: this.currentFile, 
        model: this.translationModel 
      })
      this.currentFile = null
      this.currentFileName = ''
      this.csvUploadLoading = false;
    },
    
    onPopupAction(refName, Action) {
      if (Action === "open") {
        this.$refs[refName].onPopupOpen()
      } else {
        this.$refs[refName].onPopupClose()
      }
    },  
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
    this.getAllowedModels()
  }
}
</script>

<style lang="scss" scoped>
.translations {
  &__header {
    display: flex;
    justify-content: space-between;

    padding: 30px 0 20px 0;

    border-bottom: 1px solid #D2D3D3;

    span {
      font-weight: 700;
      color: #0A3C6180;
    }

    strong {
      color: #0B3C61;
    }
  }

  &__back-btn {
    padding: 10px 8px 8px 30px;
    margin-bottom: 8px;
    font-size: 12px;
    background: #ffffff00 url("@/assets/img/icons/arrow-back.svg");
    background-position: 8px 12px;
    background-repeat: no-repeat;
    border: none;
  }

  &__card {
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  &__wrapper {
    /* display: grid; */
    /* grid-template-columns: 1fr 2fr; */
  }

  &__general {
    display: flex;
    flex-direction: column;
    width: 100%;

  }

  &__btns {
    display: flex;
    /* gap: 30px; */
    margin-top: 20px;
  }

  &__btn-manage {
    padding-right: 30px;
    border-right: 1px solid #D2D3D3;
  }

  &__btn-upload {
    margin-left: 30px;
  }

  &__btn-select {
    max-width: 180px;
    height: 36px;
    margin-left: 30px;
  }

  /* &__chips {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  } */

  &__images {
    h3 {
      margin-bottom: 21px;
    }
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFEF;

    div {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }
  }

  /* &__attachments {
    h3 {
      margin:16px 0 21px 0;
    }
    padding-bottom: 16px;
    border-bottom: 1px solid #EFEFEF;

    div {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    }
  }
  
  &__description {
    h3 {
      margin:16px 0 10px 0;
    }
  }

  &__editor {
    height: 250px;
  } */

  &__footer {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 60px;
  }
/* 
  &__details-wrapper {
    gap: 50px;    
  } */

  /* &__required {
    text-align: center;
    align-self: center;
    color: rgb(201, 11, 11);
  } */
}

@media (max-width: 760px) {
  .translations {
    &__btns {
      flex-direction: column;
      gap: 10px;
    }

    &__btn-manage {
      padding-right: 0;
      border-right: none;

      button {
        width: 100%;
      }
    }

    &__btn-upload {
      margin-left: 0;
    }

    &__btn-select {
      max-width: 100%;
      margin-left: 0;
    }
  }
}
</style>