<template>
  <div 
    class="add-flag" 
    :style="{ top: y + 'px', left: (x - 220) + 'px' }"
  >
    <div 
      v-show="flagsData.needsQC"
      class="add-flag__item" 
    >
      <span>{{ $t('needQC') }} </span> 
    </div>
    <div 
      v-show="flagsData.missingData"
      class="add-flag__item" 
    >
      <span>{{ $t('missingData') }}</span> 
    </div>
    <div 
      v-show="flagsData.needsAdditionalPhotos"
      class="add-flag__item" 
    >
      <span>{{ $t('needAdditionalPhotos') }}</span> 
    </div>
    <div 
      v-show="flagsData.needsCrossSection"
      class="add-flag__item" 
    >
      <span>{{ $t('needsCrossSection') }}</span> 
    </div>
    <div 
      v-show="flagsData.errorReported"
      class="add-flag__item" 
    >
      <span>{{ $t('errorReportedByUser') }}</span> 
    </div>
  </div>
</template>

<script>


  export default {
    props: {
      x: {
        type: Number,
        default: 0 
      },
      y: {
        type: Number,
        default: 0 
      },
      flagsData: {
        type: Object,
        default: () => {}
      }
    },
    setup() {
      return {
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
.add-flag {
  position: fixed;
  z-index: 10;
  width: 220px;
  padding-bottom: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 8px;


  &__item {
    display: flex;
    align-items: center;
    padding: 12px 16px 0px 16px;
    cursor: pointer;


    span {
      font-size: 16px;
      font-weight: 400;
    }

    &:hover {
      background-color: #dedbdb
    }
  }
}
</style>