import { device } from "@/api";
import ls from "local-storage";
import router from '@/router';

function transformDeviceData(deviceData) {
  return {
    deviceId: deviceData?.id,
    name: deviceData?.name,
    group: deviceData?.group.id,
    groupName: deviceData?.group.name,
    subGroup: deviceData?.sub_group.id,
    subGroupName: deviceData?.sub_group.name,
    role: deviceData?.role.id,
    roleName: deviceData?.role.name,
    hazards: deviceData?.hazards.map(item => {return item.id}),
    sources: deviceData?.sources.map(item => item.id),
    images: deviceData?.images,
    attachments: deviceData?.attachments,
    description: deviceData?.description,
    auw: deviceData?.auw,
    neq: deviceData?.neq,
    completeRoundWeight: deviceData?.complete_round_weight,
    propellantWeight: deviceData?.propellant_weight,
    explosiveTypes: deviceData.explosives.map(item => {return item.id}),
    length: deviceData["length"],
    diameter: deviceData?.diameter,
    width: deviceData?.width,
    foundIn: deviceData?.countries_found_in.map(item => {return item.id}),
    foundInNames: deviceData?.countries_found_in.map(item => {return item.name}),
    countryOrigin: deviceData?.country_origin.id,
    countryOriginName: deviceData?.country_origin.name,
    fuzingOptionsCollection: deviceData?.fuzing_options,
    fuzingOptions: deviceData?.fuzing_options.map(item => {return item.id}),
    fuzingOptionsNames: deviceData?.fuzing_options.map(item => {return item.name}),
    relatedDevicesCollection: deviceData?.related_devices,
    relatedDevices: deviceData?.related_devices.map(item => {return item.id}),
    relatedDevicesNames: deviceData?.related_devices.map(item => {return item.name}),
    goimsDevicesIds: deviceData?.goims_device_ids,
    goimsDevices: deviceData?.goims_devices,
    goimsGalleryLink: deviceData?.goims_gallery_link,
    relatedAccidentsLink: deviceData?.related_accidents_incidents_link,
    relatedEoEvent: deviceData?.related_eo_event,
    model3D: deviceData?.icrc_3D_url,
    safetyDistanceProtected: deviceData?.safetyDistanceProtected,
    safetyDistanceUnprotected: deviceData?.safetyDistanceUnprotected,
    notam: deviceData?.notam,
    missingData: deviceData?.missing_data,
    needsAdditionalPhotos: deviceData?.needs_additional_photos,
    needsCrossSection: deviceData?.needs_cross_section,
    errorReported: deviceData?.error_reported,
    needsQC: deviceData?.needs_qc
  }
}

export const actions = {

  async getDeviceAdds({ commit, dispatch, state }, {force = false} = {}) {
    try {
      if(state.adds.length === 0 || force) {
        ls.remove("HALO_DEVICE_ADDS")
  
        const { data }  = await device.getAdds()
  
        commit('device/SetDeviceAdds', { ...data } , { root: true })
      }

    } catch ( error ) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true}) 
      dispatch('snackbar/setErrorApi', error, { root: true })

    } 
  },

  async getDeviceCollection({ commit, dispatch, state }, { filters = {}, name = null, page = 1} = {}) {
    try {
      commit('loader/SET_DEVICES_LOADING', true, {root: true})
      commit('device/SetDevicesLoaded', true, {root: true})

      const { data } = await device.getCollection({
        params: {
          ...filters,
          name,
          page,
          per_page: state.limit
        }})

      commit('device/SetDeviceCollection', data.result, { root: true })
      // commit('device/SetDeviceTotal', data.result.total , { root: true })
      
    } catch (error) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true}) 
      dispatch('snackbar/setErrorApi', error, { root: true })
    } finally {
      commit('loader/SET_DEVICES_LOADING', false, {root: true})
    }
  },

  async getDevice({ commit, dispatch, state }, { deviceId }) {
    try {
      if(state.device.deviceId != Number(deviceId)) {
        commit('loader/SET_DEVICE_LOADING', true, {root: true})

        const { data } = await device.getDevice(deviceId);
        // const deviceData = data.result;

        // const transformedData = {
        //   deviceId: deviceData?.id,
        //   name: deviceData?.name,
        //   group: deviceData?.group.id,
        //   groupName: deviceData?.group.name,
        //   subGroup: deviceData?.sub_group.id,
        //   subGroupName: deviceData?.sub_group.name,
        //   role: deviceData?.role.id,
        //   hazards: deviceData?.hazards.map(item => {return item.id}),
        //   sources: deviceData?.source_ids.map(item => item.id),
        //   images: deviceData?.images,
        //   attachments: deviceData?.attachments,
        //   description: deviceData?.description,
        //   auw: deviceData?.auw,
        //   neq: deviceData?.neq,
        //   completeRoundWeight: deviceData?.complete_round_weight,
        //   propellantWeight: deviceData?.propellant_weight,
        //   explosiveTypes: deviceData.explosives.map(item => {return item.id}),
        //   length: deviceData["length"],
        //   diameter: deviceData?.diameter,
        //   width: deviceData?.width,
        //   foundIn: deviceData?.countries_found_in.map(item => {return item.id}),
        //   foundInNames: deviceData?.countries_found_in.map(item => {return item.name}),
        //   countryOrigin: deviceData?.country_origin.id,
        //   countryOriginName: deviceData?.country_origin.name,
        //   fuzingOptions: deviceData?.fuzing_options.map(item => {return item.id}),
        //   fuzingOptionsNames: deviceData?.fuzing_options.map(item => {return item.name}),
        //   relatedDevices: deviceData?.related_devices.map(item => {return item.id}),
        //   relatedDevicesNames: deviceData?.related_devices.map(item => {return item.name}),
        //   goimsDevices: deviceData?.goims_device_ids.map(item => {return parseInt(item)}),
        //   relatedEoEvent: deviceData?.related_eo_event,
        //   model3D: deviceData?.icrc_3D_url,
        //   safetyDistanceProtected: deviceData?.safetyDistanceProtected,
        //   safetyDistanceUnprotected: deviceData?.safetyDistanceUnprotected,
        //   notam: deviceData?.notam,
        //   missingData: deviceData?.missing_data,
        //   needsAdditionalPhotos: deviceData?.needs_additional_photos,
        //   needsCrossSection: deviceData?.needs_cross_section,
        //   errorReported: deviceData?.error_reported,
        //   needsQC: deviceData?.needs_qc
        // }

        const transformedDeviceData = transformDeviceData(data.result)

        commit('device/SetDevice', { data: transformedDeviceData }, { root: true })
      }

    } catch ( error ) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true}) 
      dispatch('snackbar/setErrorApi', error, { root: true })
    } finally {
      commit('loader/SET_DEVICE_LOADING', false, {root: true})
    }
  },


  async createDevice({ commit, dispatch }, { deviceData = {}, imgCollection, pdfCollection }) {
    try {
      const body = {
        name: deviceData?.name,
        group_id: deviceData?.group,
        sub_group_id: deviceData?.subGroup,
        role_id: deviceData?.role,
        hazard_ids: deviceData?.hazards,
        fuzing_option_ids: deviceData?.fuzingOptionsCollection.map(item => item.id),
        explosive_ids: deviceData?.explosiveTypes,
        related_device_ids: deviceData?.relatedDevicesCollection.map(item => item.id),
        source_ids: deviceData?.sources,
        goims_device_ids: deviceData?.goimsDevices.map(item => item.id),
        related_eo_event: deviceData?.relatedEoEvent,
        icrc_3D_url: deviceData?.model3D,
        country_origin_id: deviceData?.countryOrigin,
        countries_found_in_ids: deviceData?.foundIn,
        "model": "ABC123",
        auw: deviceData?.auw,
        neq: deviceData?.neq,
        complete_round_weight: deviceData?.completeRoundWeight,
        propellant_weight: deviceData?.propellantWeight,
        length: deviceData?.length,
        width: deviceData?.width,
        diameter: deviceData?.diameter,
        "warning_notes": "Some warning notes",
        description: deviceData?.description
      }

      const { data } = await device.create(body)

      await dispatch("device/uploadAllAttachments", {
        deviceId: data.result.id,
        imgCollection,
        pdfCollection
      }, { root: true });

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Device successfully created', {root: true}) 

      const transformedDeviceData = transformDeviceData(data.result)
      commit('device/SetDevice', { data: transformedDeviceData }, { root: true })
      
      commit('device/AddDevice', { data: data.result }, { root: true })
      
      await router.push({ name: "Devices" });
    } catch (error) {
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async updateDevice({ commit, dispatch }, {deviceId, deviceData = {}, imgCollection = [], pdfCollection = []}) {
    try {
      const body = {
        name: deviceData?.name,
        group_id: deviceData?.group,
        sub_group_id: deviceData?.subGroup,
        role_id: deviceData?.role,
        hazard_ids: deviceData?.hazards,
        fuzing_option_ids: deviceData?.fuzingOptionsCollection.map(item => item.id),
        explosive_ids: deviceData?.explosiveTypes,
        related_device_ids: deviceData?.relatedDevicesCollection.map(item => item.id),
        source_ids: deviceData?.sources,
        goims_device_ids: deviceData?.goimsDevices.map(item => item.id),
        related_eo_event: deviceData?.relatedEoEvent,
        icrc_3D_url: deviceData?.model3D,
        country_origin_id: deviceData?.countryOrigin,
        countries_found_in_ids: deviceData?.foundIn,
        "model": "ABC123",
        auw: deviceData?.auw,
        neq: deviceData?.neq,
        complete_round_weight: deviceData?.completeRoundWeight,
        propellant_weight: deviceData?.propellantWeight,
        length: deviceData?.length,
        width: deviceData?.width,
        diameter: deviceData?.diameter,
        "warning_notes": "Some warning notes",
        description: deviceData?.description
      }

      const { data } = await device.update({deviceId, body})

      await dispatch("device/uploadAllAttachments", {
        deviceId,
        imgCollection,
        pdfCollection
      }, { root: true });

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Device successfully edited', {root: true})

      const transformedDeviceData = transformDeviceData(data.result)
      commit('device/SetDevice', { data: transformedDeviceData }, { root: true })
      
      dispatch('device/getDeviceAdds', {force: true}, { root: true })
      dispatch('device/getDeviceCollection', {}, {root: true})

      await router.push({ name: "Device", id: deviceId });

    } catch (error) {
      dispatch('snackbar/setErrorApi', error, { root: true })
      
    }
  },


  async uploadAllAttachments({commit, dispatch}, {deviceId, imgCollection, pdfCollection}) {
    try {

      if(imgCollection.length) {
        imgCollection.forEach( async img => {

          if(img.new) {
            const attachment = new FormData();

            attachment.append('caption', img.caption);
            attachment.append('type', 'image');
            attachment.append('file', img.file);
            attachment.append('license_id', img.license);
            attachment.append('source_id', img.source);

            await dispatch("device/uploadAttachment", {deviceId, attachment}, { root: true });
          }
        })
      }

      if(pdfCollection.length) {
        pdfCollection.forEach(async pdf => {

          if(pdf.new) {
            const attachment = new FormData();

            attachment.append('title', pdf.name);
            attachment.append('description', pdf.description);
            attachment.append('type', 'attachment');
            attachment.append('file', pdf.file);

            await dispatch("device/uploadAttachment", {deviceId, attachment}, { root: true });
          }
        })
      }

    }
    catch (error) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true}) 
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async uploadAttachment({ commit, state, dispatch }, {deviceId, attachment} = {}) {
    try {
      const { data } = await device.uploadAttachment({deviceId, attachment})

      const transformedDeviceData = transformDeviceData(data.result)
      commit('device/SetDevice', { data: transformedDeviceData }, { root: true })

      const devices = state.devices
      const indexToUpdate = devices.findIndex((item) => item.id === data.result.id);
      if (indexToUpdate !== -1) {
        devices[indexToUpdate] = data.result;
      }
      commit('device/SetDeviceCollection', { data: devices }, { root: true })

    } catch (error) {
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async updateAttachment({commit, dispatch}, {deviceId, attachment} = {}) {
    try {

        const body = attachment.type === 'image' 
        ? {
          caption: attachment.caption,
          source_id: attachment.source,
          license_id: attachment.license,
        } : {
          type: attachment.type,
          title: attachment.name,
          description: attachment.description
        }

      const { data } = await device.updateAttachment({
        deviceId, 
        attachmentId: attachment.id, 
        body,
      })

      const transformedDeviceData = transformDeviceData(data.result)
      commit('device/SetDevice', { data: transformedDeviceData }, { root: true })

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Attachment successfully edited', {root: true})

    } catch (error) {
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async deleteAttachment({ commit, dispatch }, {deviceId, attachmentId} = {}) {
    try {
      const { data } = await device.deleteAttachment({deviceId, attachmentId})

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Attachment successfully deleted', {root: true}) 

      const transformedDeviceData = transformDeviceData(data.result)
      commit('device/SetDevice', { data: transformedDeviceData }, { root: true })

    } catch (error) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true}) 
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async getDeviceHistory({commit}, {deviceId} = {}) {
    try {
      const { data } = await device.getHistory(deviceId)

      commit('device/SetDeviceHistory', { data: data.result.data }, { root: true })
      
    } catch (error) {
      dispatch('snackbar/setErrorApi', error, { root: true })
    } 
    
  },

  async deleteDevice({commit, dispatch}, {deviceId} = {}) {
    try {

      const { data } = await device.delete(deviceId);

      dispatch('device/getDeviceCollection', {}, {root: true})

      router.push({
        name: 'Devices' 
      })

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Device successfully deleted', {root: true})

    } catch ( error ) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async updateDeviceFlags({commit, dispatch}, {deviceId, flags} = {}) {
    try {

      const body = {
        missing_data: flags.missingData ? 1 : 0,
        needs_additional_photos: flags.needsAdditionalPhotos ? 1 : 0,
        needs_cross_section: flags.needsCrossSection ? 1 : 0,
        error_reported: flags.errorReported ? 1 : 0,
        needs_qc: flags.needsQC ? 1 : 0,
      }
      const { data } = await device.update({deviceId, body})

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Flags successfully edited', {root: true})

      const transformedDeviceData = transformDeviceData(data.result)
      commit('device/SetDevice', { data: transformedDeviceData }, { root: true })


    } catch ( error ) {
      // commit('snackbar/SET_ERROR_API', "Something went wrong", {root: true})
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async sendReport({ commit, dispatch }, { report }) {
    try {

      const { data } = await device.report(report)

      commit('snackbar/SET_SUCCESS_MESSAGE', 'Report successfully sent', {root: true}) 

      return true
    } catch (error) {
      dispatch('snackbar/setErrorApi', error, { root: true })
      return false
    }
  },

  async getActualGroupCollection({ commit, dispatch }, { foundInId = null } = {}) {
    try {

        const { data }  = await device.getActualGroups({ foundInId })

        const transformedData = data.result.map(item => {
          return {
            id: item.id,
            name: item.name,
            subGroups: item.sub_groups
          }
        })

        commit('device/SetActualGroups', transformedData , { root: true })
      
    } catch ( error ) {
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },

  async getGoimsCollection({ commit, dispatch }, { term = null } = {}) {
    try {

        const { data }  = await device.getGOIMS({ term })

        commit('device/SetGOIMS', data , { root: true })
      
    } catch ( error ) {
      dispatch('snackbar/setErrorApi', error, { root: true })
    }
  },
}