<template>
  <div>
    <div id="preloader">
      <div id="loader"></div>
    </div>
    <p class="text-center">
      {{ $t('loading') }}... 
    </p>
  </div>
</template>

<script>
import {useRoute} from "vue-router/dist/vue-router";
import router from "@/router";
import AuthService from '@/services/AuthService'


export default {
  name: "AuthCallbackView",
  components: {},
  mounted() {
    const route = useRoute()
    if (route.query.hasOwnProperty('code') && route.query.hasOwnProperty('state')) {
      const authService = new AuthService();
      authService.loginOffice365Callback(route.query.code, route.query.state)
    } else {
      router.push('/login')
    }
  },
}
</script>

<style scoped>
#preloader {

  /*width: 100%;*/
  /*height: 100%;*/
  z-index: 999;
  margin-bottom: 10px;
}

#loader {
  display: block;
  position: relative;
  /*top: 50%;*/
  width: 140px;
  height: 140px;

  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: rgba(1, 43, 255, 0.88);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1.5s linear infinite;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: rgba(3, 139, 198, 0.64);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 2.5s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: rgba(24, 234, 114, 0.78);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>