import actions from '@/store/Auth/actions'; 
import mutations from '@/store/Auth/mutations';
import state from '@/store/Auth/state';
import getters from '@/store/Auth/getters';

export const auth = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}