<template>
  <div class="main paper-bcg-dark">
    <router-view />
  </div>
</template>

<script>

import '@/assets/css/styles.min.css';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Public",
}
</script>
<style scoped>
.main{
  min-height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
