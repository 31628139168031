<template>
  <div class="filters-card">
    <div class="d-flex">
      <div class="filters-card__by">
        <h3>{{ $t('filterBy') }}</h3> 
        <div class="filters-card__selects">
          <!-- <label>{{ $t('group') }}</label> -->
          <MySelect 
            :modelValue="$filters.group"
            :title="$t('group')"
            :options="$actualGroups" 
            @change="selectChanged('group', $event)"
          />
          <MySelect 
            :disabled="!$filters.group.length"
            :modelValue="$filters.subGroup"
            :title="$t('subGroup')"
            :options="actualSubGroups"
            @change="selectChanged('subGroup', $event)"
          />
          <!-- <label>{{ $t('role') }}</label> -->
          <MySelect 
            :modelValue="$filters.role"
            :title="$t('role')"
            :options="$deviceAdds.roles" 
            @change="selectChanged('role', $event)"
          />
          <!-- <label>{{ $t('hazards') }}</label> -->
          <MySelect 
            :modelValue="$filters.hazards"
            :title="$t('hazards')"
            :options="$deviceAdds.hazards" 
            @change="selectChanged('hazards', $event)"
          />
          <!-- <label>{{ $t('CountryOrigin') }}</label> -->
          <MySelect 
            :modelValue="$filters.countryOfOrigin"
            :title="$t('CountryOrigin')"
            :options="$countriesOfOrigin" 
            @change="selectChanged('countryOfOrigin', $event)"
          />
          <!-- <label>{{ $t('foundIn') }}</label> -->
          <MySelect 
            :modelValue="$filters.foundIn"
            :title="$t('foundIn')"
            :options="$countriesFoundIn" 
            @change="onFoundInChanged"
          />
        </div>
      </div>
      <div class="filters-card__flag">
        <h3>{{ $t('filterByFlag') }}</h3>  
        <div class="filters-card__checkboxes">
          <MyCheckbox 
            :value="$filters.needQC"
            :title="$t('needQC')" 
            @checked="checkboxChanged('needQC', $event)"
          /> 
          <MyCheckbox 
            :value="$filters.missingData"
            :title="$t('missingData')" 
            @checked="checkboxChanged('missingData', $event)"
          /> 
          <MyCheckbox 
            :value="$filters.needsAdditionalPhotos"
            :title="$t('needAdditionalPhotos')" 
            @checked="checkboxChanged('needsAdditionalPhotos', $event)"
          /> 
          <MyCheckbox 
            :value="$filters.needsCrossSection"
            :title="$t('needsCrossSection')" 
            @checked="checkboxChanged('needsCrossSection', $event)"
          /> 
          <MyCheckbox 
            :value="$filters.errorReported"
            :title="$t('errorReportedByUser')" 
            @checked="checkboxChanged('errorReported', $event)"
          /> 
        </div>
      </div>
    </div>
    <div class="d-flex justify-contend-end align-items-center gap-3">
      <MyButton 
        :title="$t('clearFilter')" 
        class="filters-card__clear-btn"
        @click="onFilterClear" 
      />
      <MyButton 
        :title="$t('apply')" 
        @click="onFiltersApply"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MyCheckbox, MyButton, MySelect } from "@/components-ui";

export default {
    components: {
      MyCheckbox,
      MyButton, 
      MySelect
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapState("device", {
        $deviceAdds: "adds",
        $actualGroups: "actualGroups",
      }),
      ...mapState("country", {
        $countriesOfOrigin: "countriesOfOrigin",
        $countriesFoundIn: "countriesFoundIn",
      }),
      ...mapState("navbarFilter", {
        $navbarFilter: 'filterValue',
        $filters: "filters"
      }),
      actualSubGroups() {
        return this.$actualGroups.find(group => group.id === +this.$filters.group[0])?.subGroups
      }
    },
    methods: {
      ...mapActions("device", ["getDeviceAdds", "getDeviceCollection", "getActualGroupCollection"]),
      ...mapActions("navbarFilter", ["updateFilterValue", "updateFiltersByName", "setFilters"]),

      selectChanged(name, event) {
        if(name === 'group') {
          this.updateFiltersByName({name: 'subGroup', newValue: []})
        }
        const newValue = [event.target.value]
        this.updateFiltersByName({name, newValue})
      },

      checkboxChanged(name, newValue) {
        this.updateFiltersByName({name, newValue})
      },
      onFilterClear() {
        this.setFilters({
          group: [],
          subGroup: [],
          role: [],
          hazards: [],
          countryOfOrigin: [],
          foundIn: [],
          needsQC: null,
          missingData: null,
          needsAdditionalPhotos: null,
          needsCrossSection: null,
          errorReported: null,
        })
        this.getDeviceCollection()
        this.updateFilterValue('')
        this.getActualGroupCollection()
        this.$emit('close')
      },
      onFiltersApply() {
        this.getDeviceCollection({
          filters: {
            group_ids: this.$filters.group,
            sub_group_ids: this.$filters.subGroup,
            role_ids: this.$filters.role,
            hazard_ids: this.$filters.hazards,
            country_origin_ids: this.$filters.countryOfOrigin,
            country_found_in_ids: this.$filters.foundIn,
            needs_qc: this.$filters.needQC ? 1 : null,
            missing_data: this.$filters.missingData ? 1 : null,
            needs_additional_photos: this.$filters.needsAdditionalPhotos ? 1 : null,
            error_reported: this.$filters.errorReported ? 1 : null,
            needs_cross_section: this.$filters.needsCrossSection ? 1 : null,
          },
          name: this.$navbarFilter ? this.$navbarFilter : null,
        })
        this.$emit('close')
      },
      onFoundInChanged(event) {
        this.selectChanged('foundIn', event)
        this.getActualGroupCollection({
          foundInId: event.target.value
        })
      }
    },
    // eslint-disable-next-line vue/order-in-components
    mounted() {
      this.getDeviceAdds()
      // this.getCountryOfOriginCollection()
      // this.getCountryFoundInCollection()
    }
  }
</script>

<style lang="scss" scoped>
.filters-card {
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 560px;
  padding: 24px 30px 20px 30px;

  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

  h3 {
    color: #0B3C61;
  }

  &__by {
    margin-right: 40px;
  }

  &__flag {
    padding-left: 40px;
    border-left: 1px solid #D2D3D3;
  }

  &__selects {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    
    select {
      height: 36px;
      margin-bottom: 16px;
    }
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 16px;
  }

  &__save-btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0 16px;
    height: 36px;
    margin-right: 20px;
    color: #0B3C61;
    font-weight: 700;
    border: none;
    border-radius: 4px;

    &:hover {
      color: #1163a2;
    }
  }

  &__clear-btn{
    height: 36px;
    padding: 0px 16px 0px 40px;
    font-size: 14px;
    font-weight: 700;
    background: #ffffff url("@/assets/img/icons/x.svg");
    background-position: 16px 9px;
    background-repeat: no-repeat;
    border-radius: 4px;
    border: 1px solid #0B3C61;
    color: #0B3C61;
    border-radius: 4px;

    span {
      position: relative;
      top: 1px;
    }
  }
  
}
</style>