// import store from '@/store/store'
import {createRouter, createWebHistory} from "vue-router/dist/vue-router";
import AuthService from "@/services/AuthService";

import Login from "@/components/Auth/Login";

import DevicesView from "@/views/Device/DevicesView";
import DeviceEdit from "@/views/Device/DeviceEdit";
import DeviceView from "@/views/Device/DeviceView";
import DeviceHistoryView from "@/views/Device/DeviceHistoryView";

import AuthCallbackView from "@/components/Auth/AuthCallbackView";

import UsersView from "@/views/User/UsersView";
import UserEdit from "@/views/User/UserEdit";

import TranslationsView from "@/views/Translations/TranslationsView";

import CalculatorView from "@/views/Calculator/CalculatorView";

import ls from "local-storage";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'Devices',
            path: '/',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: DevicesView
        },
        {
            name: 'AddDevice',
            path: '/add-device',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: DeviceEdit
        },
        {
            name: 'EditDevice',
            path: '/edit-device/:id',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: DeviceEdit
        },
        {
            name: 'Device',
            path: '/device/:id',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: DeviceView
        },
        {
            name: 'DeviceHistory',
            path: '/device-history/:id',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: DeviceHistoryView
        },

        {
            name: 'Login',
            path: '/login',
            meta: {
                layout: "public"
            },
            component: Login
        },
        {
            name: 'AuthCallback',
            path: '/auth/callback',
            meta: {
                layout: "public"
            },
            component: AuthCallbackView
        },

        {
            name: 'Users',
            path: '/users',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: UsersView
        },
        {
            name: 'AddUser',
            path: '/add-user',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: UserEdit
        },
        {
            name: 'EditUser',
            path: '/edit-user/:id',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: UserEdit
        },

        {
            name: 'Translations',
            path: '/translations',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: TranslationsView
        },

        {
            name: 'Calculator',
            path: '/calculator',
            meta: {
                requiresAuth: true,
                layout: "default",
            },
            component: CalculatorView
        },

    ]
})

router.beforeEach((to, from, next) => {
    const loggedIn = ls.get("HALO_TOKEN")

    if (to.name === 'AuthCallback') {
        return next()
    }

    if (to.name === 'Login' && loggedIn ) {
        return next(from.path)
    }

    if (to.name === 'Login' && !loggedIn) {
        return next()
    }

    if (!loggedIn) {
        return next({
            path: "/login",
        });
    }

    return next()
});

export default router 
