<template>
  <div class="you-sure__wrapper">
    <h2> {{ fileName ? fileName : $t('areYouSure') }}</h2>
    <!-- <h2>entranslations.csv</h2>  -->
    <div>
      <MyButton 
        :title="$t('accept')"  
        @click="$emit('onAccept')"
      />
      <MyButton 
        :title="$t('decline')"   
        type="o"
        @click="$emit('onPopupClose')"
      />
    </div>
  </div>
</template>

<script>
import { MyButton} from "@/components-ui";


export default {
  components: {
    MyButton
  },
  props: {
    fileName: {
      type: String,
      default: ''
    }
  },
  emits: ['onPopupClose', 'onAccept'],
}
</script>

<style lang="scss">
.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f222375;
}

.you-sure {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 470px;
    height: 270px;
    padding: 77px 80px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

    div {
      display: flex;
      justify-content: center;
      gap: 40px;
    }

  }
}

@media (max-width: 535px) {
  .logout-popup__wrapper {
    width: 300px;
    height: 260px;
    padding: 77px 10px;
    h2 {
      font-size: 20px !important; 
    }
  }
}
</style>