const mutations = {
    loading(state, data) {
        if(data){
            state.error_api_response = false
        }
        state.loading = data
    },
    set_message(state, data) {
        if (!data) {
            state.message = null;
            state.error_api_response = false;
            return
        }
        state.message = data.message
        if (data.status !== 200) {
            state.error_api_response = true
            state.message = data.status + " : " + data.message
        } else {
            setTimeout(() => {
                state.message = null;
                state.error_api_response = false
            }, 2000)
        }
    },
}

export default mutations