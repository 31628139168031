import axios from "axios";
import router from "@/router/index.js";

import AuthService from "@/api/Auth.js";

import ls from "local-storage";

export const defaultAxios = axios.create({
  withCredentials: true,
  baseURL:
    process.env.VUE_APP_BASE_URL
});

defaultAxios.interceptors.request.use(
  (config) => {
    const token = ls.get("HALO_TOKEN");
    const { headers, url } = config

    if (token) {
      // Object.assign(headers, { Authorization: `Bearer ${token}` })
      Object.assign(headers, { "X-API-Key": `${token}` })
    }

    Object.assign(headers, { "Content-Accept": "application/json" })

    if (url.includes("attachment") && !url.includes("attachment/") || url.includes("import") ) {
      Object.assign(headers, { "Content-Type": "multipart/form-data" })
    } else {
      Object.assign(headers, { "Content-Type": "application/json" })
    }
  
    return config;
  },
  (error) => {
    throw error;
  }
);

defaultAxios.interceptors.response.use(
  (response) => {
    const { url } = response.config
    const { data } = response

    return response;
  },
  (error) => {
    const { response } = error
    
    if (response.status === 403) {
      ls.clear();
      router.push({ name: "Login" });
    }

    if (response.status === 401 && response.data.description === "Token is invalid") {
      ls.clear();
      router.push({ name: "Login" });
    }

    if (response.status === 401 && response.data.description === "Token is expired") {
      AuthService.refreshToken(ls.get("HALO_REFRESH_TOKEN"))
      .then(res => {
        const { status, data } = res
        if (status === 200) {
          ls.set("HALO_TOKEN", data.result['x_api_key']);
          // return defaultAxios(response.config)
        }
      })
      .then(() => {
        router.go(0)
      })
      .catch(() => {
        ls.clear();
        router.push({ name: "Login" });
      })
    }
    
    throw error
  }
);

export const Axios = defaultAxios;
