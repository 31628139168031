<template>
  <div class="add-source">
    <h4>{{ $t('addSource') }}</h4> 
    <div class="add-source__input">
      <MyInput v-model="newSource" />
      <AddButton @click="onSourceAdd" :disabled="addBtnDisabled"/>
    </div>
    <div class="add-source__checkboxes">
      <MyCheckbox 
        v-for="item in items" 
        :key="item.id"
        :title="item.name"
        :value="item.checked || false"
        @checked="onChecked(item.id, $event)"
        class="add-source__checkbox"
      >
        <!-- <img 
          :src="trashIcon" 
          class="add-source__delete"
          @click="onSourceRemove(item.id)"
        > -->
      </MyCheckbox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MyCheckbox, MyInput, AddButton } from "@/components-ui";

import trashIcon from "@/assets/img/icons/trash.svg";


  export default {
    components: {
      MyInput,
      AddButton,
      MyCheckbox,
    },
    props: {
      items: {
        type: Array,
        default: () => []
      },
    },
    setup() {
      return {
        trashIcon,
      }
    },
    emits: ['onValueChanged'],
    data() {
      return {
        newSource: '',
        addBtnDisabled: false,
        removeBtnDisabled: false
      }
    },
    computed: {

    },
    methods: {
    ...mapActions("source", ["addSource", "removeSource"]),

      onChecked(sourceId, newValue) {
        this.$emit('onValueChanged', sourceId, newValue)
      },
      async onSourceAdd() {
        if(this.newSource) {
          this.addBtnDisabled = true;
          await this.addSource({ 
            newSource: this.newSource 
          })
          this.newSource = ''
          this.addBtnDisabled = false;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.add-source {
  display: flex;
  flex-direction: column;
  max-width: 435px;
  height: 460px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 8px;

  h4 {
    padding: 24px 30px 0px 30px;
    margin-bottom: 0;
    color: black;
  }

  &__input {
    display: flex;
    gap: 16px;
    padding: 16px 30px 20px 30px;
    border-bottom: 1px solid #B1B2B2;


    input {
      height: 36px;
    }
  }
  
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px 24px 30px;
    overflow: scroll;

    label {
      font-size: 16px;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    gap: 10px;
  }
  
  &__delete {
    flex-grow: 0;
    right: 0;
    height: 20px;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .add-source {
    width: 300px;
  }
}
</style>