<template>
  <SnackbarModule />
  <component :is="layout" />
</template>

<script>
import SnackbarModule from "@/components-ui/SnackbarModule.vue";
import '@/assets/css/adminlte/fontawesome/css/all.min.css'
import { useRoute } from 'vue-router';
import { computed } from 'vue';


const default_layout = "Default";

export default {
  name: 'App',
  components: {
    SnackbarModule
  },
  setup() {
    const route = useRoute();

    const layout = computed(() => {
      return (route.meta.layout || default_layout) + 'Layout';
    });

    return {
      layout
    };
  }
}
</script>




<style lang="scss">

</style>

