<template>
  <div class="flags-card">
    <h3>{{ $t('addFlag') }}</h3>  
    <div class="flags-card__checkboxes">
      <MyCheckbox 
        :title="$t('needQC')"
        :value="flagsData.needsQC"
        :disabled="isUser"
        @checked="onChecked('needsQC', $event)"
      /> 
      <MyCheckbox 
        :title="$t('missingData')" 
        :value="flagsData.missingData"
        :disabled="true"
        @checked="onChecked('missingData', $event)"
      /> 
      <MyCheckbox 
        :title="$t('needAdditionalPhotos')" 
        :value="flagsData.needsAdditionalPhotos"
        :disabled="numberOfPhotos === 0 || isUser"
        @checked="onChecked('needsAdditionalPhotos', $event)"
      /> 
      <MyCheckbox 
        :title="$t('needsCrossSection')" 
        :value="flagsData.needsCrossSection"
        :disabled="isUser"
        @checked="onChecked('needsCrossSection', $event)"
      /> 
      <MyCheckbox 
        :title="$t('errorReportedByUser')" 
        :value="flagsData.errorReported"
        @checked="onChecked('errorReported', $event)"
      /> 
    </div>
    <div class="d-flex justify-content-center align-items-center mt-4">
      <MyButton 
        :title="$t('apply')" 
        :disabled="applyBtnDisabled"
        @click="onApply" 
      />
    </div>
  </div>
</template>

<script>
import { MyCheckbox, MyButton } from "@/components-ui";
import ls from "local-storage";

  export default {
    components: {
      MyCheckbox,
      MyButton
    },
    props: {
      title: {
        type: String,
        default: 'Add hazard'
      },
      flagsData: {
        type: Object,
        default: () => {}
      },
      numberOfPhotos: {
        type: Number,
        default: 0
      },
      applyBtnDisabled: {
        type: Boolean,
        default: false
      }
    },
    emits: ['onValueChanged', 'onApply'], 
    setup() {
      return {
      }
    },
    data() {
      return {
      }
    },
    computed: {
      isAdmin() {
        return ls.get("HALO_USER_ROLE") === 'super_admin' || ls.get("HALO_USER_ROLE") === 'admin';
      },
      isUser() {
        return ls.get("HALO_USER_ROLE") === 'user';
      },
    },
    methods: {
      onChecked(name, newValue) {
        this.$emit('onValueChanged', name, newValue)
      },
      onApply() {
        this.$emit('onApply')
      }
    },
  }
</script>

<style lang="scss" scoped>
.flags-card {
  display: flex;
  flex-direction: column;
  /* width: 220px; */
  padding: 24px 30px;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);

  h3 {
    padding-bottom: 16px;
    color: black;
  }
  
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      font-size: 16px;
    }
  }
}
</style>