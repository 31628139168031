<template>
  <div 
    v-show="loading"
    class="spinner" 
  >
    <img 
      :src="spinner" 
      alt="spinner"
    >
  </div>
</template>

<script>
import spinner from "@/assets/img/spinner.svg";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Spinner",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    return {
      spinner
    }
  }
}
</script>

<style lang="scss" scoped>
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>