<template>
  <div class="popup-slider__wrapper">
    <button 
      class="popup-slider__close" 
      @click="$emit('onPopupClose')"
    >
      <img 
        :src="closeIcon"
        alt="close icon"
      >
    </button>
    <vueper-slides
      ref="sliderPopup"
      slide-content-outside="bottom"
      :fixed-height="sliderHeight"
      :bullets="false"
    >
      <template #arrow-left>
        <img :src="caruselButtonLeft">
      </template>

      <template #arrow-right>
        <img :src="caruselButtonRight">
      </template>

      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.imageUrl === 'no-img' ? noImg : `${baseUrl}${slide.imageUrl}`"
      >
        <template #content>
          <div class="vueperslide__content-wrapper ">
            <div>
              <b>{{ $t('caption') }}: </b> 
              {{ slide.caption }}
            </div>
            <div>
              <b>{{ $t('source') }}: </b> 
              {{ slide.source }}
            </div>
            <div>
              <b>{{ $t('license') }}:</b> 
              {{ slide.license }}
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import caruselButtonLeft from "@/assets/img/icons/button-left.svg";
import caruselButtonRight from "@/assets/img/icons/button-right.svg";
import closeIcon from "@/assets/img/icons/close-light.svg";

export default {
  name: 'SliderPopup',
  components: {
    VueperSlides, 
    VueperSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    currentSlide: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['onPopupClose'],
  setup (props, context) {
    return {
      caruselButtonLeft,
      caruselButtonRight,
      closeIcon,
    }
  },
  data() {
    return {
      sliderHeight: '525px',
    }
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_IMG_URL || '';
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.calculateSliderHeight);
  },
  mounted() {
    window.addEventListener("resize", this.calculateSliderHeight);

    this.$refs.sliderPopup.goToSlide(this.currentSlide.index)

    this.calculateSliderHeight()
  },
  methods: {
    calculateSliderHeight() {
      if (window.innerWidth > 300) {
        this.sliderHeight = '200px'
      } 
      if (window.innerWidth > 450) {
        this.sliderHeight = '265px'
      } 
      if (window.innerWidth > 550) {
        this.sliderHeight = '325px'
      } 
      if (window.innerWidth > 800) {
        this.sliderHeight = '525px'
      } 
    }
  },
}
</script>

<style lang="scss">
.popup-slider {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f222375;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border:none;
    background-color: transparent;
    z-index: 2;
  }

  &__wrapper {
    position: relative;
    width: 700px;
    height: 585px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;

    .vueperslides {
      margin: 0;
    }
  }
}
/* .vueperslide__image {
} */

.vueperslide {
  background-size: contain;
  background-repeat: no-repeat;
}

.vueperslide__content-wrapper {
  height: 60px;
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  justify-content: center;
  align-items: center;
  div {
    width: 33%;
    font-size: 14px;
    display: inline;
    text-align: center;
  }
  div:nth-child(2) {
    padding: 0 16px;
    border-right: 1px solid #D2D3D3;
    border-left: 1px solid #D2D3D3;
    margin: 0 16px;
  }
}

@media (max-width: 800px) {
  .popup-slider {

    &__wrapper {
      width: 500px;
      height: 385px;
    }
  }
}

@media (max-width: 550px) {
  .popup-slider {

    &__wrapper {
      width: 400px;
      height: 325px;
    }
  }
  .vueperslide__content-wrapper {
    div {
      font-size: 12px;
    }
  }
}


@media (max-width: 450px) {
  .popup-slider {

    &__wrapper {
      width: 300px;
      height: 260px;
    }
  }
  .vueperslide__content-wrapper {
    div {
      font-size: 11px;
    }
  }
}
</style>