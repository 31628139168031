<template> 
  <div class="report">
    <div class="report__wrapper">
      <h3>{{ $t('writeReport') }}</h3>
      <div class="report__input report__topic">
        <label>
          <span>{{ $t('topic') }}</span> 
          <MyInput 
            v-model="report.topic"
            :class="[validate.topic ? null : 'border-danger']"
            @input="validateItem('topic')"
          />
        </label>
      </div>

      <div class="report__input">
        <label>
          <span>{{ $t('description') }}</span> 
          <textarea 
            v-model="report.text"
            class="form-control report__textarea" 
            :class="[validate.text ? null : 'border-danger']"
            :style="{border: '2px solid #D2D3D3'}"
            @input="validateItem('text')"
          />
        </label>
      </div>

      <div class="report__footer">
        <MyButton 
          :title="$t('cancel')"  
          type="o"
          @click="$emit('onPopupClose')" 
        />
        <MyButton 
          :title="$t('send')" 
          @click="onReportSend"
          :disabled="sendButtonDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MyInput, MyButton } from '@/components-ui'
import { mapActions, mapState } from "vuex";

export default {
  components: {
    MyInput,
    MyButton
  },
  emits: ['onPopupClose', 'onPdfAdded'],
  setup () {
    return {
      // closeIcon,
      // pdfIcon
    }
  },
  data() {
    return {
      report: {
        topic: '',
        text: '',
      },
      validate: {
        topic: true,
        text: true,
      },
      sendButtonDisabled: false,
    }
  },
  methods: {
    ...mapActions("device", ["sendReport"]),

    async onReportSend() {
      if(this.validateAll()) {
        this.sendButtonDisabled = true
        const result = await this.sendReport({ report: this.report }) 

        if (result) this.$emit('onPopupClose')
        this.sendButtonDisabled = false
      }
    },
    validateItem(name) {
      this.validate[name] = !!this.report[name];
    },
    validateAll() {
      this.validate.topic = !!this.report.topic;
      this.validate.text = !!this.report.text;

      return Object.values(this.validate).every(value => value === true);
    }
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    // if(window.innerHeight < 700) {
    //   this.isImgShown = false
    // }
  }
}
</script>

<style lang="scss" scoped>


.report {
    position: relative;
    width: 370px;
    /* height: 700px; */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    overflow: hidden;
    padding: 40px;

    h3 {
      text-align: center;
      margin-bottom: 30px;
    }


  &__input {

    /* &:nth-child(1) {
      padding: 20px 24px 16px 24px;
    }
    &:nth-child(2) {
      padding: 0 24px 30px 24px;
    } */
    margin-bottom: 17px;

    label {
      width: 100%;
      margin-bottom: 0;

      span {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 700;
        color: black;
      }
    }
  }


  &__footer {
    display: flex;
    gap: 30px;
    justify-content: center;

    button {
      height: 36px;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border:none;
    background-color: transparent;
    z-index: 999;
  }

  &__textarea {
    resize: none;
    height: 85px;
  }
}

@media (max-width: 400px) {
  .report {
    width: 300px;
  }
}
</style>