<template> 
  <div>
    <label class="custom-checkbox">
      <input 
        type="checkbox" 
        class="custom-checkbox__input" 
        :checked="checked"
        :disabled="disabled"
        @change="handleChange"
      >
      <span>{{ title }}</span>
    </label>
    <slot />
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    title: {
      type: String,
      default: 'no-label'
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['checked'],
  setup(props, { emit }) {
    const checked = ref(props.value);

    const handleChange = (event) => {
      checked.value = event.target.checked;
      emit('checked', checked.value);
    };

    return {
      checked,
      handleChange
    };
  }
}
</script>

<style lang="scss" scoped>

  .custom-checkbox {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    user-select: none;
    font-weight: 400 !important;
    cursor: pointer;

    &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked+span::before {
        border-color: #008DA8;
        background-color: #008DA8;
        background-image: url('@/assets/img/icons/check-white.svg');
        background-position: center center;
        background-size: 20px 20px;
      }

      &:checked:disabled+span::before {
        background-color: #b5b5b5;
        border-color: #b5b5b5;
      }
      &:disabled+span {
        color: #787878;
      }
    }
    &>span {
      display: inline-flex;
      font-size: 16px;
    }

    &>span::before {
      content: '';
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 2px solid #D2D3D3;
      border-radius: 3px;
      margin-right: 12px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
      transition: .2s ease;
    }
  }
</style>
