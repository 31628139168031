<template>
  <section class="calc">
    <PopupWrapper ref="resultPopup">
      <ResultCalculationPopup
        :result="$calcResult"
        @onPopupClose="() => onPopupAction('resultPopup', 'close')" 
      />
    </PopupWrapper>

    <div class="my-container">
      <div class="calc__header">
        <span>{{ $t('safetyDistance') }}</span> 
      </div>
      <button 
        class="calc__back-btn" 
        @click="$router.push({
          name: 'Devices'
        })"
      >
        {{ $t('back') }} 
      </button>
      <div class="calc__card">
        <div class="calc__wrapper">
          <div 
            v-for="device in $calcDevices"
            :key="device.id"
            class="calc__item"
          >
            <DeviceCalcCard
              :id="device.id"
              :name="device.name"
              :quantity="device.quantity"
              :imgPath="device.imgPath"
              :auw="device.auw"
              class="calc__device" 
              @onQuantityAction="onQuantityAction"
              @onDeviceRemove="onDeviceRemove"
            />
            <div class="calc__item-plus" />
          </div>
        </div>


        <h3 
          v-show="$calcDevices.length" 
          class="calc__total"
        >
          {{ $t('totalWeight') }}: <span>{{ totalWeight }}{{ $t('kg') }}</span>
        </h3>

        <h3 
          v-show="!$calcDevices.length"
          class="calc__total" 
        >
          {{ $t('noDevicesToCalculate') }}
        </h3>

        <div 
          v-show="$calcDevices.length" 
          class="calc__footer"
        >
          <MyButton 
            v-show="$calcDevices.length"
            :title="$t('calculate')" 
            :disabled="calculateBtnDisabled"
            @click="onCalculate"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash";

import { 
  MyButton, 
  DeviceCalcCard,
  PopupWrapper,
  ResultCalculationPopup
} from "@/components-ui"

export default {
  name: "CalculatorVuew",
  components: {
    MyButton,
    DeviceCalcCard,
    PopupWrapper,
    ResultCalculationPopup
  },
  setup () {
    return {}
  },
  data() {
    return {
      calculateBtnDisabled: false
    }
  },
  computed: {
    ...mapState("calculator", {
      $calcDevices: 'devices',
      $calcResult: 'result'
    }),
    totalWeight() {
      if(this.$calcDevices?.length) {
        return this.$calcDevices
          .map(item => item.auw * item.quantity)
          .reduce((accum, current) => accum + current)
          .toFixed(2)
      }
      return 0
    },
    totalQuantity() {
      if(this.$calcDevices?.length) {
      return this.$calcDevices
        .map(item => item.quantity)
        .reduce((accum, current) => accum + current)
      }
      return 0
    }
    
  },
  methods: {
    ...mapActions("calculator", ["changeQuantityById", "removeDevice", "calculateDevices"]),

    onPopupAction(refName, Action) {
      if (Action === "open") {
        this.$refs[refName].onPopupOpen()
      } else {
        this.$refs[refName].onPopupClose()
      }
    }, 
    onQuantityAction({id, value}) {
      this.changeQuantityById({id, value})
    },
    onDeviceRemove(id) {
      this.removeDevice(id)
    },
    async onCalculate() {
      if(this.totalQuantity) {
        this.calculateBtnDisabled = true;

        await this.calculateDevices({devices: this.$calcDevices})
        this.onPopupAction('resultPopup', 'open')

        this.calculateBtnDisabled = false;
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
  }
}
</script>

<style lang="scss" scoped>
.calc {
  &__header {
    display: flex;
    justify-content: space-between;

    padding: 30px 0 20px 0;

    border-bottom: 1px solid #D2D3D3;

    span {
      font-weight: 700;
      color: #0A3C6180;
    }

    strong {
      color: #0B3C61;
    }
  }

  &__back-btn {
    padding: 10px 8px 8px 30px;
    margin-bottom: 8px;
    font-size: 12px;
    background: #ffffff00 url("@/assets/img/icons/arrow-back.svg");
    background-position: 8px 12px;
    background-repeat: no-repeat;
    border: none;
  }

  &__card {
    padding: 20px 30px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  &__wrapper {
    position: relative;
  }

  &__item {
    padding: 16px 0;
    border-bottom: 1px solid #EBEBEB;
    position: relative;

    &:first-child {
      padding-top: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      display: inline-block;
      width: 64px;
      height: 15px;
      left: 50%;
      transform: translateX(-50%);
      background: #ffffff00 url("@/assets/img/icons/plus.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      display: inline-block;
      width: 64px;
      height: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:last-child::after {
      display: none;
    }

    &:last-child::before {
      display: none;
    }
  }

  &__device {
    
  }

  &__total {
    text-align: center;
    margin-top: 20px;

    span {
      font-weight: normal;
    }
  }


  &__footer {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
  }
}

@media (max-width: 500px) {
  .calc {
  }
}
</style>