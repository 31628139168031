import { Axios } from "./config.js";

export default class TranslateService {

  async getCollection() {
    return await Axios.get("translation/list");
  }

  async getLocaleCollection() {
    return await Axios.get("translation/locale");
  }

  // async importTranslate({ file }) {
  //   return await Axios.post("translation/import", file );
  // }

  async setTranslate(lang) {
    return await Axios.put(`translation/${lang}`)
  }

  async setActiveTranslate({localeId, newValue}) {
    return await Axios.put(`translation/locale/${localeId}`, {
      active: newValue
    })
  } 

  async getAllowedModels() {
    return await Axios.get("translation/allowed-models");
  }

  async importTranslate({ file }) {
    return await Axios.post("translation/import", file);
  }

}