<template> 
  <div class="source-item">
    <img 
      class="source-item__close"
      :src="closeIcon"
      alt="close icon"
      @click="$emit('onChipsClose', id)"
    >
    <span>{{ text }}</span>
  </div>
</template>

<script>
import closeIcon from "@/assets/img/icons/close.svg";
export default {
  name: 'ChipsItem',
  props: {
    text: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      default: null
    }
  },
  emits: ['onChipsClose'],
  setup () {
    return {
      closeIcon
    }
  },
}
</script>

<style lang="scss" scoped>
  .source-item {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #363939;
    height: 36px;
    text-align: center;
    padding: 0 8px;
    border-radius: 4px;
    background: #EFEFEF;

    &__close {
      position: absolute;
      width: 16px;
      top: -9px;
      right: -9px;
      opacity: .5;
      cursor: pointer;
    }

    span {
      position: relative;
      top: 1px;
    }
  }
</style>

