import { Axios } from "./config.js";

export default class TranslateService {

  async getCollection() {
    return await Axios.get("device/source");
  }

  async addSource(newSource) {
    return await Axios.post("device/source", {
      name: newSource
    });
  }

  async removeSource(sourceId) {
    return await Axios.delete(`device/source/${sourceId}`);
  }
}