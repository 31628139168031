<template>
  <div class="hamburger">
    <span />
    <span />
    <span />
    <span />
  </div>
</template>

<script>
  export default {
    name: 'HamburgerButton'
  }
</script>

<style lang="scss" scoped> 
  .hamburger {
    display: none;
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    & span {
      position: absolute;
      display: block;
      width: 30px;
      height: 3px;
      background-color: #ffffff;
      border-radius: 1px;

      &:nth-child(1) {
        top: 1px;
      }
      &:nth-child(2), &:nth-child(3) {
        top: 14px;
      }
      &:nth-child(4) {
        bottom: 0px;
      }
    }
  }
</style>