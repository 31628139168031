<template>
  <button 
    class="my-button" 
    :class="type === 'o' ? 'my-button__o' : none"
    :disabled="disabled"
  >
    {{ title }} 
  </button>
</template>

<script>
export default {
  name: "MyButton",
  props: {
    title: String,
    type: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.my-button {
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  text-align: center;

  color: white;
  background: #0B3C61;
  
  border-radius: 4px;
  border: none;

  &__o {
    background: #ffffff;
    color: #363939;
    border: 1px solid #0B3C61;
    border-radius: 6px;
  }

  &:disabled {
    background: #a3a3a3;
  }
}
</style>