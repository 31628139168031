<template> 
  <div class="input-container">
    <input 
      class="form-control my-input"
      :value="modelValue"
      :type="showPassword ? 'text' : 'password'"
      :class="validationClass"
      :autocomplete="autocomplete"
      :placeholder="placeholder" 
      @input="updateInput"
    >
    <img 
      :src="showPassword? eyeIcon : eyeSlashIcon" 
      alt="Show password" 
      class="eye-icon"
      @click="togglePassword"
    >
  </div>
</template>

<script>
import eyeIcon from "@/assets/img/icons/eye.svg";
import eyeSlashIcon from "@/assets/img/icons/eye-slash.svg";

export default {
  name: "MyPassInput",
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    validationClass: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    }
  },
  emits: ['update:model-value'],
  setup() {
    return {
      eyeIcon,
      eyeSlashIcon
    }
  },
  data() {
    return {
      showPassword: false
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:model-value', event.target.value)
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    }
  }
}
</script>
<style lang="scss" scoped>
.my-input {
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  color: #363939;
  border: 2px solid #D2D3D3;
  margin: 0;
}

.input-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 40px;
  padding: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>