
export default {
  state: {
    filters: {
      group: [],
      subGroup: [],
      role: [],
      hazards: [],
      countryOfOrigin: [],
      foundIn: [],
      needsQC: null,
      missingData: null,
      needsAdditionalPhotos: null,
      needsCrossSection: null,
      errorReported: null,
    },
    filterValue: "",
  },
  actions: {
    updateFilterValue({ commit }, value) {
      commit('setFilterValue', value);
    },
    setFilters({ commit }, filters) {
      commit('setFilters', filters);
    },
    updateFiltersByName({commit}, {name, newValue}) {
      commit('setFilterByName', {name, newValue})
    }
  },
  mutations: {
    setFilterValue(state, value) {
      state.filterValue = value;
    },
    setFilters(state, value) {
      state.filters = value
    },
    setFilterByName(state, {name, newValue}) {
      state.filters[name] = newValue
    }
  },
}