<template>
  <button class="pdf-button">
    <img 
      class="pdf-button__icon" 
      :src="pdfIcon"  
      alt="pdf icon"
    >
  </button>
</template>

<script>
import pdfIcon from "@/assets/img/icons/pdf-gray.svg";

export default {
  name: "PdfButton",
  props: {
  },
  setup () {
    return {
      pdfIcon,
    }
  }
}
</script>

<style lang="scss" scoped>

.pdf-button {
  width: 48px;
  height: 48px;
  padding: 12px;

  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  border: none;
  border-radius: 8px;

  &__icon {
    filter: grayscale(80%);
  }
}
</style>