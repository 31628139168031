export const state = {
  devices: [],
  devicesLoaded: false,
  device: {
    relatedDevicesCollection: [],
    fuzingOptionsCollection: []
  },
  adds: [],
  actualGroups: [],
  goims: [],
  history: {},
  page: 1,
  limit: 50,
  total: 0,
}