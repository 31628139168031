<template>
  <div class="relative">
    <div 
      v-show="!(groupId === null)"
      v-click-outside="closeSidebarActive"
      class="sidebar-sub-groups" 
      :style="{left: `${subGroupCardX}px`, top: `${subGroupCardY}px`}"
    >
      <div 
        v-for="subGroup in subGroups"
        :key="subGroup.id"
        class="sidebar-sub-groups__item"
        @click="onSubGroupSelected(subGroup.id)"
      >
        <span>{{ subGroup.name }}</span> 
      </div>
    </div>
    <aside 
      class="main-sidebar d-flex flex-column justify-content-between"
      :style="sidebarOpenedStyle"
    >
      <PopupWrapper ref="popupLogout">
        <LogoutPopup @onPopupClose="onPopupAction('popupLogout', 'close')" />
      </PopupWrapper>

      <div>
        <div
          class="main-sidebar__link d-flex justify-content-center align-items-center"
          @click="onLogoClick"
        >
          <img 
            class="main-sidebar__logo" 
            :src="logoImg" 
            alt="Logo img"
          >
        </div>

        <nav class="sidebar">
          <ul 
            v-if="!isSuperAdmin"
            class="sidebar-links" 
            data-widget="treeview" 
            role="menu" 
            data-accordion="false"
          >
            <li 
              v-for="group in $actualGroups" 
              :key="group.id"
              class="d-flex justify-content-between sidebar-links__item"
              :class="[groupId === group.id ? 'sidebar-links__item_active' : null]"
              @click.stop="onGroupSelected($event, group.id)"
            > 
              <span class="sidebar-links__text">{{ group.name }}</span>
              <img 
                class="sidebar-links__icon" 
                :src="arrowIcon" 
                alt="arrow icon"
              >
            </li>
          </ul>
          <ul 
            v-if="isSuperAdmin"
            class="sidebar-links" 
            data-widget="treeview" 
            role="menu" 
            data-accordion="false"
          >
            <li 
              v-for="value in sidebarAdminValues" 
              :key="value.title"
              @click="onSidebarMobileClose"
            > 
              <router-link 
                :to="value.route" 
                class="d-flex justify-content-between sidebar-links__item"
              >
                <span class="sidebar-links__text">{{ value.title }}</span>
                <img 
                  class="sidebar-links__icon" 
                  :src="arrowIcon" 
                  alt="arrow icon"
                >
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div 
        class="logout" 
        @click="onPopupAction('popupLogout', 'open')"
      >
        <img 
          class="logout__icon" 
          :src="logoutIcon" 
          alt="arrow icon"
        >
        <span class="logout__text">{{ $t('logout') }}</span> 
      </div>
    </aside>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ls from "local-storage";

import { PopupWrapper, LogoutPopup } from '@/components-ui'

import '@/assets/css/adminlte/adminlte.css'

import logoImg from "@/assets/img/logo.svg";
import arrowIcon from "@/assets/img/icons/arrow.svg";
import logoutIcon from "@/assets/img/icons/logout.svg";

export default {
  name: "SideBar",
  components: {
    LogoutPopup, 
    PopupWrapper
  },
  props: {
    isOpened: Boolean
  },
  emits: ['onSidebarClose'],
  setup() {
    return {
      logoImg,
      arrowIcon,
      logoutIcon
    };
  },
  data() {
    return {
      groupId: null,
      subGroupCardX: null,
      subGroupCardY: null,
    }
  },
  computed: {
    ...mapState("device", {
      $actualGroups: "actualGroups",
    }),

    sidebarOpenedStyle() {
      return { marginLeft: this.isOpened ? '0px' : '-250px' }
    },

    isSuperAdmin() {
      return ls.get("HALO_USER_ROLE") === 'super_admin'
    },

    sidebarAdminValues() { 
      return [ 
        {
          title: this.$t("users"),
          route: { name: 'Users' } 
        },
        {
          title: this.$t("translations"),
          route: { name: 'Translations' }
        },
        {
          title: this.$t("devices"),
          route: { name: 'Devices' }
        },
      ]
    },

    subGroups() {
      if (this.$actualGroups) {
        return this.$actualGroups.find(item => item.id === this.groupId) 
          ? this.$actualGroups.find(item => item.id === this.groupId).subGroups 
          : []
      } 
      return []
    }
  },
  methods: {
    ...mapActions("device", ["getDeviceCollection", "getActualGroupCollection"]),
    ...mapActions("navbarFilter", ["updateFilterValue", "updateFiltersByName", "setFilters"]),

    closeSidebarActive() {
        this.groupId = null
    },
    onSidebarMobileClose() {
      if (window.innerWidth < 990 && this.isOpened) {
        this.$emit('onSidebarClose')
      }
    },
    onLogoClick() {
      if (window.innerWidth < 990 && this.isOpened) {
        this.onSidebarMobileClose()
      } else {
        this.$router.push({
          name: 'Devices' 
        })
      }
    },
    onPopupAction(refName, Action) {
      if (Action === "open") {
        this.$refs[refName].onPopupOpen()
      } else {
        this.$refs[refName].onPopupClose()
      }
    },  
    
    onGroupSelected(event, groupId) {
      const element = event.currentTarget; 
      const rect = element.getBoundingClientRect();

      const top = rect.top + window.scrollY;
      const right = rect.right + window.scrollX;

      this.subGroupCardX = right;
      this.subGroupCardY = top;

      this.groupId === groupId 
        ? this.groupId = null 
        : this.groupId = groupId;
    },
    async onSubGroupSelected(subGroupId) {

      this.getDeviceCollection({
        filters: {
          group_ids: [this.groupId],
          sub_group_ids: [subGroupId]
        }
      })
      this.updateFiltersByName({name: 'group', newValue: [this.groupId]})
      this.updateFiltersByName({name: 'subGroup', newValue: [subGroupId]})
      this.groupId = null
      this.onSidebarMobileClose()
      this.$router.push({name: 'Devices'})
    }
  },
  // eslint-disable-next-line vue/order-in-components
  async mounted() {
    this.getActualGroupCollection()
  }
}
</script>

<style lang="scss" scoped>

.main-sidebar {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  /* overflow: visible !important; */
  height: 100vh;
  width: auto;
  min-width: 170px;

  background-color: #0B3C61;
  margin-top: 0;
  
  transition: margin-left .3s ease-in-out, width .3s ease-in-out ;

  max-width: 205px;
  
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__link {
    display: block;
    height: 72px;
    border-bottom: 1px solid white;
    cursor: pointer;
  }

  &__logo {
    width: 59px;
  }
}

.sidebar {
  margin-top: 30px;
  overflow: visible !important;

  &-links {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 2px;


    &__item {
      position: relative;
      background: #ffffff17;
      gap: 15px;

      padding: 18px;
      border-left: 2px solid #ffffff17;
      cursor: pointer;

      &:hover {
        background: #306f9d;
      }

      &_active {
        background: #276B9D;
        border-left: 2px solid #E87D1E;
      }
    }

    &__text {
      opacity: 1;
      color: white;
    }

    &__icon {
      filter: invert(1)
    }
  }

  &-sub-groups {
    max-height: 325px;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 100px;
    left: 200px;
    z-index: 99999;
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      min-height: 59px;
      width: 150px;
      padding: 16px;
      font-size: 12px;
      color: white;
      background-color: #276B9D;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      border-bottom: 0.5px solid grey;
      border-top: 0.5px solid grey;
      transition: margin-left .3s ease-in-out, width .3s ease-in-out ;
      cursor: pointer;


      &:last-child {
        border-bottom: none;
      }

      &:hover {
        border: none;
        background-color: #65B1EA;
      }
    }
  }
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
  margin-top: 15px;

  height: 63px;

  border-top: 1px solid white;
  cursor: pointer;

  transition: .3s ease;


  &:hover {
    background: #306f9d;
  }
  
  &__icon {
    width: 20px;
    filter: invert(1)
  }

  &__text {
    position: relative;
    top: 1px;
    color: white;
    font-size: 18px;
    font-weight: 300;
  }
}

@media (max-height: 930px ) {
  .sidebar {
    /* margin-top: 62px; */
    &-links {


      &__item {
        padding: 14px 18px;
      }
    }

    &-sub-groups {

      &__item {
        min-height: auto;
        padding: 14px 18px;
      }
    }
  }
}

@media (max-height: 840px ) {
  .sidebar {
    margin-top: 10px;
    &-links {


      &__item {
        padding: 10px 18px;
      }
    }


    &-sub-groups {

      &__item {
        min-height: auto;
        padding: 10px 18px;
      }
    }
  }
}

@media (max-height: 670px ) {
  .sidebar {
    margin-top: 10px;
    &-links {
      &__item {
        padding: 8px 18px;
      }
    }

    &-sub-groups {

      &__item {
        min-height: auto;
        padding: 8px 18px;
      }
    }

  
  }

}


</style>