import { source } from "@/api";
import { get, set } from "local-storage"

export default {
  state: {
    sources: [],
  },

  actions: {
    async getSourceCollection({ dispatch, commit }) {
      try {
        const { data } = await source.getCollection()

        commit("SET_SOURCES", data.result)

      } catch (error) {
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },

    async addSource({ dispatch, commit }, { newSource }) {
      try {
        
        const { data } = await source.addSource(newSource)
        
        commit("ADD_SOURCE", data.result)
        
      } catch (error) {
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },

    async removeSource({ dispatch, commit, state }, sourceId) {
      try {
        
        const { data } = await source.removeSource(sourceId)
        
        commit("REMOVE_SOURCE", sourceId)
        
      } catch (error) {
        dispatch('snackbar/setErrorApi', error, { root: true })
      }
    },
  },

  mutations: {
    SET_SOURCES(state, sources) {
      state.sources = sources
    },
    ADD_SOURCE(state, newSource) {
      state.sources.push(newSource)
    },
    REMOVE_SOURCE(state, sourceId) {
      const index = state.sources.findIndex(source => source.id === sourceId)
      state.sources.splice(index, 1)
    }
  }
}