<template>
  <div :class="`device-card-${view}`"> 
    <img 
      v-show="hasFlag" 
      :src="flagIcon" 
      alt="flag icon" 
      :class="`device-card-${view}__flag-img`"
      @mouseenter="(event) => onFlagMouseEnter(event)"
      @mouseleave="(event) => onFlagMouseLeave(event)"
    >
    <div 
      :class="`device-card-${view}__img`" 
      :style="cardImageStyle"
    />
    <div :class="`device-card-${view}__text`">
      <span :class="`device-card-${view}__title`">{{ name.length > 15 ? name.slice(0, 15) + '...' : name }}</span>
      <span :class="`device-card-${view}__descr`">{{ subName }}</span>
    </div>
  </div>
</template>

<script>
import flagIcon from "@/assets/img/icons/card-flag.svg";
import noImg from "@/assets/img/no-img.jpg";

export default {
  props: {
    view: {
      type: String,
      default: 'block'
    },
    hasFlag: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'no-name'
    },
    subName: {
      type: String,
      default: 'no-sub-name'
    },
    imgUrl: {
      type: String,
      default: 'no-img'
    },
    flagsData: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    return {
      flagIcon,
      noImg
    }
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_IMG_URL || '';
    },
    cardImageStyle() {
      return {
        'background-image': this.imgUrl === 'no-img' ? `url(${this.noImg})` : `url(${this.baseUrl}${this.imgUrl})`,
        'background-size': this.imgUrl === 'no-img' ? `cover`: `contain`
      }
    }
  },
  methods: {
    onFlagMouseEnter(event) {
      this.$emit('onFlagMouseEnter', {event, flagsData: this.flagsData})
    },
    onFlagMouseLeave(event) {
      this.$emit('onFlagMouseLeave', {event})
    }
  },
}
</script>

<style lang="scss" scoped>

.device-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 19.29%;
  height: 266px;

  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  cursor: pointer;

  &__img {
    height: 184px;
    /* background: url("https://m.gordonua.com/img/article/14934/42_tn.jpg?v1585646734") top center/cover no-repeat; */
    border-radius: 8px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
  }

  &__descr {
    font-size: 16px;
    color: #717D96;
  }

  &__flag-img {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

@media (max-width: 2000px) {
  .device-card-block {
    width: 19%;
  }
}

@media (max-width: 1560px) {
  .device-card-block {
    width: 23.6%;
  }
}

@media (max-width: 1152px) {
  .device-card-block {
    width: 31.2%;
  }
}

@media (max-width: 680px) {
  .device-card-block {
    width: 47%;
  }
}

@media (max-width: 450px) {
  .device-card-block {
    width: 100%;
  }
}

.device-card-list {
  position: relative;
  display: flex;
  width: 100%;
  height: 76px;

  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;

  &__img {
    width: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    margin-right: 30px
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
  }

  &__descr {
    font-size: 16px;
    color: #717D96;
  }

  &__flag-img {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

@media (max-width:440px) {
  .device-card-list__title {
    font-size: 16px;
  }
  .device-card-list__descr {
    font-size: 14px;
  }
}
</style>