<template> 
  <div 
    class="user-card" 
    :class="[deleted ? 'user-deleted' : null]"
  >
    <div class="user-card__wrapper">
      <img 
        :src="userIcon"
        :class="`user-card__img`" 
      >
      <div :class="`user-card__text`">
        <span :class="`user-card__title`">{{ name === " " ? 'no-name' : name}}</span>
        <span :class="`user-card__descr`">{{ email }}</span>
      </div>
    </div>
    <div>
      <img 
        v-show="!deleted"
        class="user-card__edit-btn" 
        :src="editIcon"
      >
      <img 
        v-show="deleted"
        class="user-card__restore-btn" 
        :src="restoreIcon"
      >
    </div>
  </div>
</template>

<script>
import userIcon from "@/assets/img/icons/user-icon.png";
import editIcon from "@/assets/img/icons/edit.svg";
import restoreIcon from "@/assets/img/icons/restore.png";

export default {
  name: "UsersCard",
  props: {
    name: {
      type: String,
      default: 'no-name'
    },
    email: {
      type: String,
      default: 'no-email'
    },
    deleted: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return {
      userIcon,
      editIcon,
      restoreIcon
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>

.user-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 76px;

  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;

  &__wrapper {
    display: flex;
  }

  &__img {
    width: 60px;
    height: 60px;
    
    /* background: url("https://m.gordonua.com/img/article/14934/42_tn.jpg?v1585646734") center center/cover no-repeat; */
    border-radius: 8px;
    margin-right: 30px;
    margin-left: 8px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
  }

  &__descr {
    font-size: 16px;
    color: #717D96;
  }

  &__edit-btn {
    height: 20px;
    width: 20px;
    margin-right: 28px;
  }
  &__restore-btn {
    height: 25px;
    width: 25px;
    margin-right: 28px;
  }

  /* &__flag-img {
    position: absolute;
    top: 0px;
    right: 0px;
  } */
}

.user-deleted {
  background-color: #e3e3e3
}

@media (max-width: 400px) {
 
  .user-card__img {
    margin-right: 10px;
  }
  .user-card__edit-btn{
    margin-right: 15px;
  }
  .user-card__descr {
    font-size: 12px;
  }
}
</style>