<template> 
  <div class="popup-attach-view__wrapper">
    <button 
      class="popup-attach-view__download" 
      @click="onDownload"
    >
      <img 
        :src="downloadIcon"
        alt="download icon"
      >
    </button>
    <button 
      class="popup-attach-view__close" 
      @click="$emit('onPopupClose')"
    >
      <img 
        :src="closeIcon"
        alt="close icon"
      >
    </button>
    <vueper-slides
      ref="attachmentSlider"
      :bullets="false"
      bullets-outside
      fixed-height="525px"
      slide-content-outside="bottom"
      @slide="onSlideChanged"
    >
      <template #arrow-left>
        <img :src="caruselButtonLeft">
      </template>

      <template #arrow-right>
        <img :src="caruselButtonRight">
      </template>

      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="pdfBackgroundImg"
        :content="slide"
      >
        <template #content>
          <div class="vueperslide__content-wrapper" />
        </template>
      </vueper-slide>
    </vueper-slides>
    
    <div class="popup-attach-view__footer">
      <strong>
        <span>
          {{ currentSlide.title }}
        </span>
      </strong>
      <span>
        {{ currentSlide.description }}
      </span>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import pdfBackgroundImg from "@/assets/img/pdfBackground.jpg";

import caruselButtonLeft from "@/assets/img/icons/button-left.svg";
import caruselButtonRight from "@/assets/img/icons/button-right.svg";
import closeIcon from "@/assets/img/icons/close-light.svg";
import downloadIcon from "@/assets/img/icons/download.png";

export default {
  name: 'AttachmentViewPopup',
  components: {
    VueperSlides, 
    VueperSlide,
  },
  props: {
    slides: {
      type: Array,
      default: () => []
    },
    currentSlide: {
      type: Object,
      default: () => {}
    },
  },
  emits: ['onPopupClose', 'onSlideChanged'],
  setup () {
    return {
      caruselButtonLeft,
      caruselButtonRight,
      closeIcon,
      pdfBackgroundImg,
      downloadIcon
    }
  },
  data() {
    return {
    }
  },
  methods: {
    onSlideChanged(event) {
      this.$emit('onSlideChanged', event.currentSlide.content)
    },
    onDownload() {
      const a = document.createElement('a');
      a.href = `${process.env.VUE_APP_BASE_IMG_URL}${this.currentSlide.path}`
      a.download = this.currentSlide.title;
      a.target="_blank";
      a.click();
    }
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    this.$refs.attachmentSlider.goToSlide(this.currentSlide.index)
  }
}
</script>

<style lang="scss">
.popup-attach-view {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f222375;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border:none;
    background-color: transparent;
    z-index: 2;
  }

  &__download {
    position: absolute;
    top: 13px;
    right: 57px;
    border:none;
    background-color: transparent;
    z-index: 2;

    & img {
      height: 29px;
      filter: invert(1)
    }
  }

  &__wrapper {
    position: relative;
    width: 700px;
    /* height: 585px; */
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;

    .vueperslides {
      margin: 0;
    }
  }

  &__footer {
    /* height: 110px; */
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px 24px;
    span {
      font-size: 16px;
      display: inline;
    }
    span:nth-child(2) {
      color: black;
      font-style: italic;
      font-weight: normal;
    }
  }
}
</style>