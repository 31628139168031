
export default {
  state: {
    devicesLoading: false,
    deviceLoading: false,
    historyLoading: false,
    
  },

  actions: {
    
  },

  mutations: {
    SET_DEVICES_LOADING(state, loading) {
      state.devicesLoading = loading
    },
    SET_DEVICE_LOADING(state, loading) {
      state.deviceLoading = loading
    },
    SET_HISTORY_LOADING(state, loading) {
      state.historyLoading = loading
    }
  }
}